import {
  Box,
  Button,
  FormLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';

export const Frequency = ({ prop }) => {
  return (
    <Box>
      <Box
        sx={{
          minHeight: '420px',
          width: '100%',
          border: '0.8px solid #797F8F80',
          borderRadius: '4px',
          p: 3,
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: { xs: '100%', md: '35%' },
            // width: "350px",
            my: '5px',
            mb: 5,
          }}>
          <FormLabel
            sx={{
              fontSize: '12px',
              fontWeight: '600',
              color: '#000000',
            }}>
            Bundle Name
          </FormLabel>
          <TextField
            placeholder='Enter Bundle Name'
            type='text'
            value={prop.name}
            onChange={(e) => prop.setName(e.target.value)}
            required
            sx={{ color: '#AFAFAF', fontSize: '10px', fontWeight: '600' }}
            size='small'
            InputProps={{
              sx: {
                height: '40px',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#47474799',
                },
              },
            }}
          />
        </Box>
        <Box sx={{ width: '100%', my: '12px' }}>
          <Box
            sx={{
              display: 'flex',
              // flexDirection: 'column',
              // alignItems: 'center',
              width: { xs: '100%', md: '35%' },
            }}>
            <Box>
              <FormLabel
                sx={{
                  fontSize: '12px',
                  fontWeight: '600',
                  color: '#000000',
                }}>
                Select Bundle Frequency
              </FormLabel>
              <Select
                sx={{ height: '40px', minWidth: '250px' }}
                displayEmpty
                value={prop.frequency}
                onChange={(e) => prop.setFrequency(e.target.value)}
                inputProps={{ 'aria-label': 'Without label' }}>
                <MenuItem value=''>Select Bundle Frequency</MenuItem>
                <MenuItem value='Weekly'>Weekly</MenuItem>
                <MenuItem value='Monthly'>Monthly</MenuItem>
                <MenuItem value='Yearly'>Yearly</MenuItem>
              </Select>
            </Box>
            <Button disableElevation disableFocusRipple disableRipple disableTouchRipple sx={{ background: '#8A18D0', color: '#fff', px: 4, mx: 1, mt: '25px', height: 'min-content', minWidth: 'max-content', textTransform: 'capitalize', '&:hover': { background: '#B37FFC' }, }} onClick={() => prop.EstimatedCredits()}>
              Calculate
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            width: '100%',
            borderRadius: '8px',
            border: '1px solid #EAECF0',
          }}>
          <Box
            sx={{
              background: '#F3E7FA',
              p: 2,
              borderBottom: '1px solid #EAECF0',
              borderRadius: '8px',
              borderBottomLeftRadius: '0px',
              borderBottomRightRadius: '0px',
            }}>
            <Typography
              sx={{
                fontWeight: '600',
                fontSize: '14px',
                color: '#000000',
              }}>
              Estimated Credits
            </Typography>
          </Box>
          <Box
            sx={{
              p: 2,
              display: 'flex',
              justifyContent: 'space-between',
            }}>
            <Typography
              sx={{
                fontWeight: '500',
                fontSize: '12px',
                color: '#2B2B2B',
              }}>
              Total Number of Line Items Fetched
            </Typography>
            <Typography
              sx={{
                fontWeight: '500',
                fontSize: '12px',
                color: '#2B2B2B',
              }}>
              {prop.Esti.rows ? prop.Esti.rows : 0}
            </Typography>
          </Box>
          <Box
            sx={{
              borderBottom: '1.13px solid #E7E9EB',
              p: 2,
              display: 'flex',
              justifyContent: 'space-between',
            }}>
            <Typography
              sx={{
                fontWeight: '500',
                fontSize: '12px',
                color: '#2B2B2B',
              }}>
              Total Number of Data Points Fetched
            </Typography>
            <Typography
              sx={{
                fontWeight: '500',
                fontSize: '12px',
                color: '#2B2B2B',
              }}>
              {prop.Esti.rows * prop.Esti.columns
                ? prop.Esti.rows * prop.Esti.columns
                : 0}
            </Typography>
          </Box>
          <Box
            sx={{
              p: 2,
              display: 'flex',
              justifyContent: 'space-between',
            }}>
            <Typography
              sx={{
                fontWeight: '600',
                fontSize: '12px',
                color: '#2B2B2B',
              }}>
              Credits needed to run the bundle
            </Typography>
            <Typography
              sx={{
                fontWeight: '500',
                fontSize: '12px',
                color: '#2B2B2B',
              }}>
              {prop.Esti.estimated_credit ? prop.Esti.estimated_credit : 0}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
