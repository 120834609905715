import { Box, Typography } from "@mui/material";
import { MyZoneIcn } from "../../../../../utils/icon/CorporateNormalUserDatas/Icon";


export const MyZone = () => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <MyZoneIcn />
            <Typography sx={{ fontSize: '20px', fontWeight: '600', color: '#000' }}>My Zone</Typography>
            <Typography sx={{ fontSize: '16px', fontWeight: '500', color: '#000' }}>This is the space for you to create your own app.</Typography>
        </Box>
    );
}