import { Box, Button, Rating, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { useContext } from "react";
import CountryContext from "../../context/CountryContext";
import { SellerData } from "../../../utils/data/SellerData";
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { Link } from "react-router-dom";


export const getParsedRating = (rating) => {
    const parsedRating = parseFloat(rating);
    return isNaN(parsedRating) ? 0 : parsedRating;
};

export const TableComp2 = () => {
    const { country, setCountry, brand, setBrand, subBrand, setSubBrand, seller, setSeller, product, setProduct, DashboardTableData } = useContext(CountryContext)
    return (
        <Table aria-label="brand table">
            <TableHead sx={{ background: "#1DA1F21A" }}>
                <TableRow>
                    <TableCell>Sl. No</TableCell>
                    <TableCell>Seller Name</TableCell>
                    <TableCell>No of Products</TableCell>
                    <TableCell>Total Ratings</TableCell>
                    <TableCell>Avg Ratings</TableCell>
                    {/* <TableCell>MRP (₹)</TableCell> */}
                    {/* <TableCell></TableCell> */}
                </TableRow>
            </TableHead>
            <TableBody sx={{ overflow: "auto" }}>
                {SellerData[brand].subBrandDatas[subBrand].tableDatas.map((row, i) => (
                    <TableRow
                        key={i}
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                        <TableCell>{i + 1}.</TableCell>
                        <TableCell><Link to={'/Dashboard/Detailed-Seller'} onClick={() => setSeller(i)}>{row.seller_name}</Link></TableCell>
                        <TableCell>{row.noOfProducts}</TableCell>
                        <TableCell>{row.Total_ratings}</TableCell>
                        <TableCell>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Rating
                                    name="rating"
                                    value={getParsedRating(row.seller_ratings)}
                                    precision={0.1}
                                    readOnly
                                    emptyIcon={<StarBorderIcon fontSize="small" style={{ color: 'gray' }} />}
                                    icon={<StarIcon fontSize="small" style={{ color: '#fdd835' }} />}
                                />
                                &nbsp;{row.seller_ratings}
                            </Box>
                        </TableCell>
                        {/* <TableCell>
                            <Link to={'/Detailed-Seller'}>
                                <Button
                                    sx={{
                                        cursor: "pointer",
                                        fontWeight: "500",
                                        fontSize: "12px",
                                        color: "#1DA1F2",
                                        px: "17px",
                                        py: 0,
                                        height: "25px",
                                        width: "min-content",
                                        display: "flex",
                                        alignItems: "center",
                                        ml: "5px",
                                        background: "#E8F6FE",
                                        borderRadius: "16px",
                                    }}
                                    onClick={() => setSeller(i)}
                                >
                                    View
                                </Button>
                            </Link>
                        </TableCell> */}
                    </TableRow>
                )
                )
                }
            </TableBody>
        </Table>
    );
}


