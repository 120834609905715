import {
    Box,
    Typography,
} from "@mui/material";
import Carousel from "react-material-ui-carousel";
import { useState } from "react";
import { items } from "../../../../utils/data/Auth/Data";




export const Carousel_OG = () => {
    const [activeItemIndex, setActiveItemIndex] = useState(0);
    return (
        <Carousel
            // animation="slide"
            sx={{
                height: "90%",
                mt: 4,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
            }}
            autoPlay={true}
            indicatorContainerProps={{
                style: {
                    textAlign: "center",
                    // height: '12px',
                    // width: '12px',
                    colors: "#000000",
                },
            }}
            activeIndicatorIconButtonProps={{
                style: {
                    width: "30px",
                    height: "12px",
                    borderRadius: "20px",
                    color: "#fff",
                    backgroundColor: "#fff", // Customize the active color as needed
                },
            }}
            next={(nextIndex) => setActiveItemIndex(nextIndex)}
            index={activeItemIndex}
        >
            {items.map((item, index) => (
                <Item key={index} item={item} />
            ))}
        </Carousel>
    );
};

const Item = ({ item }) => {
    return (
        <Box
            sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                alignItems: "center",
            }}
        >
            {item.svg}
            <Box sx={{ textAlign: "center", padding: "30px" }}>
                <Typography sx={{ fontWeight: "600", fontSize: "28px" }}>
                    {item.title}
                </Typography>
                <Typography sx={{ fontWeight: "500", fontSize: "20px" }}>
                    {item.text}
                </Typography>
            </Box>
        </Box>
    );
};
