import {
  Box,
  Button,
  TextField,
  Typography,
  Snackbar,
  Alert,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Bundle } from '../../../../../components/@extended/Bundle';
import { downloadCSV } from '../../../../../components/Public/DownloadCSV';
import http from '../../../../../utils/http-common';
import { Model_1 } from '../../../../../components/@extended/Model';
import { DownloadIcn, EditSvg } from '../../../../../utils/icon/IndividualPages/Icon';
import { modifyDataABLP } from '../../../../../components/@extended/CustomFunctions';
import { BackIcon } from '../../../../../utils/icon/AdminPage/Icon';

export const AdminBundleCreation = () => {
  const [open, setOpen] = useState(false);
  const allbundle = useSelector((state) => state.allbundles.allbundle);
  const navi = useNavigate();
  const credi = useSelector((state) => state.credit.credit);
  const [bundleNameEdit, setBundleNameEdit] = useState(false);
  const dispatch = useDispatch();
  const bundle = useSelector((state) => state.bundles.bundle);
  const [bundleData, setBundleData] = useState(bundle || {});
  const [bundleName, setBundleName] = useState(
    bundleData.data ? bundleData.data.ind_bundle_name : ''
  );
  const [openS, setOpenS] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success');
  const handleClick = () => {
    setOpenS(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenS(false);
  };
  // const [bundleName, setBundleName] = useState(
  //   'Darshan'
  // );
  useEffect(() => {
    if (bundle && bundle.data) {
      console.log('ent bc', bundle);
      // setBundleData({ data: modifyDataABLP(bundle.data)[0] });
      setBundleData(bundle);
      setBundleName(bundle && bundle.data ? bundle.data.ind_bundle_name : '');
    } else {
      console.log('exiting', bundle);
      navi('/Admin');
    }
  }, [bundle]);

  useEffect(() => {
    if (allbundle !== null) {
      const bndl = allbundle.filter(
        (data) => data.ind_bundle_id === bundleData.data.ind_bundle_id
      );
      console.log('bndl', bndl, bndl[0])
      setBundleData({ data: bndl[0] })
    }
  }, [allbundle]);
  const DownloadSampleData = async () => {
    if (!bundleData.data) return;
    try {
      const payload = {
        category_id: bundleData.data.ind_bundle_category_id,
        site_id: bundleData.data.ind_bundle_e_commerce_id,
        brand_id: bundleData.data.ind_bundle_brand_id,
        country_id: bundleData.data.ind_bundle_country_id,
        frequency: bundleData.data.frequency,
      };
      const response = await http.post(
        'master_service/corporate_sample_data',
        payload
      );
      downloadCSV(
        response.data.top5Data,
        bundleData.data.ind_bundle_name + '_Sample_Data'
      );
      // setBundleData(response.data.data);
      // setTbl({
      //   Category: response.data.payload.ind_bundle_category,
      //   Country: response.data.payload.ind_bundle_country,
      //   "E-Commerce Site": response.data.payload.ind_bundle_e_commerce,
      //   Brand: response.data.payload.ind_bundle_brand,
      //   "Configured Date": response.data.payload.ind_bundle_config_date,
      // });
      console.log('res smpl data', response);
      // localStorage.setItem('sample_datas', JSON.stringify(response.data))
      // window.location.href = '/Dashboard/Individual-Dashboard/Sample-Data'
      setMessage('Sample data downloaded successfully!');
      setSeverity('success');
      handleClick();
    } catch (error) {
      console.error('Error fetching sample data:', error);
      setMessage('Failed to fetch sample data.');
      setSeverity('error');
      handleClick();
    }
  };
  const [modelData, setModelData] = useState({
    title: 'Run Bundle',
    // emoji: "🎉",
    txt: [
      'Running the bundle costs credits.',
      [
        { text: 'You need ' },
        {
          text: `${bundleData.data ? bundleData.data.estimated_credits : ''}`,
          isBold: true,
        },
        { text: ' Credits to run this bundle.' },
      ],
      // "You need 20 Credits to run this bundle.",
    ],
    cl: credi.available_credit,
    btn_1: 'Cancel',
    btn_1_cancel: true,
    // btn_1_Link: "/Dashboard/Individual-Dashboard/Recharge",
    btn_2: 'Proceed',
    btn_2_cancel: true,
    // btn_2_Link: "/Dashboard/Individual-Dashboard/User-Wizard",
    // btn_2_fn: RunBundle,
    Credits: true,
    CreditsLnk: '/Dashboard/Individual-Dashboard/Recharge',
    // btn_2_fn_props: {},
  });
  const BndlePops = ({ x, id }) => {
    console.log('BndlePops', x, id);
    if (x === 0) {
      //   setModelData({ ...runbndl, btn_2_fn_props: id });
      //   setOpen(true);
      DownloadSampleData(id);
    }
  };

  const EditBundle = async () => {
    try {
      const bundle_id = localStorage.getItem('bundle_id');
      // const filteredData = respData.filter((data) => data.ind_bundle_id === bundle_id);
      const selectedData2 = bundleData.data;
      console.log('edt', selectedData2);
      // const dr = { startDate: selectedData2.ind_bundle_start_date, endDate: selectedData2.ind_bundle_start_date }
      const dr = {
        startDate: RawDate(selectedData2.ind_bundle_start_date),
        endDate: RawDate(selectedData2.ind_bundle_end_date),
      };
      // console.log('respData', dr, respData, filteredData, filteredData[0])
      const set = {
        SelectData: {
          category: selectedData2.ind_bundle_category,
          category_id: selectedData2.ind_bundle_category_id,
          country: selectedData2.ind_bundle_country,
          country_id: selectedData2.ind_bundle_country_id,
          ecommerceSites: selectedData2.ind_bundle_e_commerce,
          ecommerceSites_id: selectedData2.ind_bundle_e_commerce_id,
          brand: selectedData2.ind_bundle_brand,
          brand_id: selectedData2.ind_bundle_brand_id,
        },
        bundleName: selectedData2.ind_bundle_name,
        ind_bundle_id: selectedData2.ind_bundle_id,
        daterange: dr,
        state: 0,
      };
      console.log('set', set);
      await dispatch(updateBundle(set));
      console.log('bundle data', bundle);
      navi('/Dashboard/Individual-Dashboard/User-Wizard');
      // localStorage.setItem('DataSelectionEdit', JSON.stringify(set))
      // localStorage.removeItem('bundle_id')
      // window.location.href = '/Dashboard/Individual-Dashboard/User-Wizard'
    } catch (error) {
      console.log('Edit bundle error', error);
      // setMessage(
      //   error.response
      //     ? error.response.data.message
      //     : "Unable to Set the Edit Data."
      // );
      // setSeverity("error");
      // handleClick();
      localStorage.removeItem('bundle_id');
    }
  };
  const DeleteBundle = async () => {
    try {
      const bundle_id = localStorage.getItem('bundle_id');
      const response = await http.delete(
        `/master_service/bundle/delete/${localStorage.getItem(
          'user_id'
        )}/${bundle_id}`
      );
      console.log('respon', response);
      setOpenS(true);
      setMessage(response.message || 'Bundle deleted successfully!');
      setSeverity('success');
      handleClick();
      localStorage.removeItem('bundle_id');
      setTimeout(() => {
        navi('/Admin');
        setOpenS(false); // Optionally hide the popup after navigation
      }, 500);
      // navi("/Dashboard/Individual-Dashboard");
    } catch (error) {
      console.log('Delete bundle error', error);
      setMessage(
        error.response ? error.response.message : 'Unable to Delete Data.'
      );
      setSeverity('error');
      handleClick();
      localStorage.removeItem('bundle_id');
    }
  };
  const ChangeModelData = (itm, rb) => {
    console.log('clk');
    if (itm === 0) {
      setModelData({
        title: 'Delete Bundle',
        // emoji: '🎉',
        txt: ['Are you sure you want to delete the bundle??'],
        cl: '',
        btn_1: 'Cancel',
        btn_1_cancel: true,
        // btn_1_Link: '/Dashboard/Individual-Dashboard/Recharge',
        btn_2: 'Delete',
        btn_2_fn: DeleteBundle,
        btn_2_cancel: true,
        // btn_2_Link: '/Dashboard/Individual-Dashboard/User-Wizard'
      });
      setOpen(true);
    } else if (itm === 1) {
      setModelData({
        title: 'Delete Bundle',
        // emoji: '🎉',
        txt: ['Are you sure you want to delete the bundle??'],
        cl: '',
        btn_1: 'Cancel',
        btn_1_cancel: true,
        // btn_1_Link: '/Dashboard/Individual-Dashboard/Recharge',
        btn_2: 'Delete',
        btn_2_fn: DeleteBundle,
        btn_2_cancel: true,
        // btn_2_Link: '/Dashboard/Individual-Dashboard/User-Wizard'
      });
      setOpen(true);
    }
  };
  const { ind_bundle_name, ...rest } = bundleData.data ? bundleData.data : {};
  console.log('data check', bundleData, rest)
  const settingsForRb = [
    { title: 'Edit Bundle', fn: ChangeModelData, link: '' },
    { title: 'Delete Bundle', fn: ChangeModelData, link: '' },
  ];
  const settingsForNot = [
    // { title: 'Copy Bundle', fn: ChangeModelData, cb: true, link: '' },
    { title: 'Delete Bundle', fn: ChangeModelData, link: '' },
  ];
  return (
    <Box sx={{ p: 2 }}>
      <Model_1
        open={open}
        setOpen={setOpen}
        data={modelData}
      />
      <Button
        disableRipple
        sx={{ textTransform: 'capitalize' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Link to={'/Admin/Bundle-List'}>
            <BackIcon />
          </Link>
          <Link to={'/Admin/Bundle-List'}>
            <Typography
              sx={{
                pl: 1,
                fontWeight: '600',
                fontSize: '20px',
                color: '#000',
              }}>
              Bundle List
            </Typography>
          </Link>
        </Box>
      </Button>
      <Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {bundleNameEdit ? (
              <TextField
                sx={{
                  '& .MuiInputBase-input': {
                    padding: 0,
                    border: 'none',
                    fontWeight: '600',
                    width: 'max-content',
                    fontSize: '16px',
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                      boxShadow: 'none',
                    },
                    '&.Mui-focused': {
                      boxShadow: 'none',
                    },
                  },
                  '& .MuiInputBase-input:focus': {
                    boxShadow: 'none',
                  },
                }}
                value={bundleName}
                onChange={(e) => setBundleName(e.target.value)}
                onBlur={() => setBundleNameEdit(false)}
                autoFocus
              />
            ) : (
              <Typography sx={{ mr: 2, fontWeight: '600', fontSize: '16px' }}>
                {bundleName}
              </Typography>
            )}
            <Button
              disableRipple
              onClick={() => setBundleNameEdit(true)}>
              <EditSvg />
            </Button>
          </Box>
          <Box sx={{ display: 'flex' }}>
            <Button
              sx={{
                background: '#8A18D0',
                px: 2,
                color: '#fff',
                textTransform: 'capitalize',
                '&:hover': { background: '#B37FFC' },
              }}
              // onClick={() => RunBundle(e.ind_bundle_id)}
              onClick={() => {
                // BndlePops({
                //   x: 0,
                //   ec: e.card_datas[0].value,
                //   id: e.ind_bundle_id,
                // });
                DownloadSampleData();
              }}>
              <DownloadIcn />
              <Typography sx={{ ml: 2, fontWeight: '600', fontSize: '10px' }}>
                Sample Data
              </Typography>
            </Button>
          </Box>
        </Box>
      </Box>
      <Box>
        <Bundle
          e={rest}
          ChangeModelData={ChangeModelData}
          BndlePops={BndlePops}
          BtnDatas={settingsForNot}
        />
      </Box>
      <Snackbar
        open={openS}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        sx={{ position: 'absolute', mt: '38px' }}>
        <Alert
          onClose={handleClose}
          severity={severity}
          variant='filled'
          sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </Box>
  );
};
