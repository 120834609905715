import { Avatar, Box, IconButton, Typography } from "@mui/material";
import { useContext, useState } from "react";
import CountryContext from "../../../../components/context/CountryContext";
import { brandIconMap } from "../../../../components/icons";
import { Chart } from "../../../../components/card/brand-card-2";
import { subBrandDetails } from "../../../../utils/data/SubBrandDetails";
import { SellerData } from "../../../../utils/data/SellerData";
import { TableComp2 } from "../../../../components/table/type - 2";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Link } from "react-router-dom";



export const Sellers = () => {
    const { country, setCountry, brand, setBrand, subBrand, setSubBrand, DashboardTableData } = useContext(CountryContext)
    // const [data, setData] = useState('')
    // console.log('SellerData', SellerData, SellerData[brand], SellerData[brand].subBrandDatas[subBrand].tableDatas)
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: '70px', p: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', my: '12px' }}>
                <Link to={'/Dashboard/Brands'}>
                    <Box
                        component="img"
                        src={brandIconMap[DashboardTableData[brand].Brand_Name]}
                        alt={DashboardTableData[brand].Brand_Name}
                        sx={{ mr: 2, width: '150px', height: 'auto' }}
                    />
                </Link>
                {/* <img src={brandIconMap[data[brand].Brand_Name]} alt={data[brand].Brand_Name} sx={{ mr: 2, width: '150px', height: 'auto' }} /> */}
                <Typography sx={{ fontWeight: '500', fontSize: '18px', lineHeight: '28px' }}>Overview</Typography>
                <Avatar src={brandIconMap[SellerData[brand].subBrandDetails[subBrand].Brand]} alt={SellerData[brand].subBrandDetails[subBrand].Brand} sx={{ mx: 2, width: 'auto', height: '45px' }} variant="square" />
                <Typography sx={{ fontWeight: '500', fontSize: '12px', color: '#1DA1F2', px: '7px', py: 0, height: '25px', display: 'flex', alignItems: 'center', ml: '5px', background: '#E8F6FE', borderRadius: '16px' }}>4 Platforms</Typography>
            </Box>
            <Link to={'/Dashboard/Brands'}>
                <Box sx={{ display: 'flex', alignItems: 'center', my: '10px' }}>
                    {/* <Avatar src={ArrowBackIosIcon} alt="" sx={{ height: 25, width: 'auto', mr: '5px', borderRadius: '5px' }} /> */}
                    <IconButton aria-label="Example" sx={{ display: 'flex', justifyContent: 'center', mr: '5px' }}>
                        <ArrowBackIosNewIcon sx={{ height: '14px', width: 'auto' }} color="#2B2B2B" />
                    </IconButton>
                    {/* <ArrowBackIosIcon /> */}
                    <Typography sx={{ color: '#2B2B2B', fontWeight: '600', fontSize: '16px' }}>{SellerData[brand].brand + ' Sellers - ' + SellerData[brand].subBrandDatas[subBrand].subBrand}</Typography>
                </Box>
            </Link>
            {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', my: '10px' }}>
                <Chart i={subBrand} />
            </Box> */}
            <Box sx={{ my: '15px' }}>
                <TableComp2 />
            </Box>
        </Box>
    );
}