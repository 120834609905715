import { Box } from "@mui/material"
import { Back } from "../../../../../components/@extended/Button"
import { ProductDetails, ProductHeader, ProductImgs } from "./components"
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import http from '../../../../../utils/http-common'





export const ProductPage = () => {
    const [product_id, setProduct_id] = useState(null);
    const [res, setRes] = useState(null);
    const location = useLocation();
    const getQueryParams = (search) => {
        console.log('ser', search)
        const params = new URLSearchParams(search);

        if (params.get("product_id")) {
            const rawProductId = params.get("product_id");
            const decodedProductId = decodeURIComponent(rawProductId);
            setProduct_id(decodedProductId);
            console.log('Decoded product_id:', decodedProductId, rawProductId);
        }
        if (params.get("card")) {
            // setCard(Number(params.get("card")));
        }
    };

    useEffect(() => {
        getQueryParams(location.search);
    }, [location]);
    const Get = async () => {
        try {
            const response = await http.get(`master_service/product_page_info/${product_id}`, JSON.stringify({
                product_id: product_id,
            }))
            console.log("response tbl:", response?.data, response?.data?.productData);
            setRes(response?.data)
            // setTblDataP(responseP?.data.bundle?.brands[0]?.ecommerce || [])
        }
        catch (error) {
            console.error("Error fetching data:", error)
        }
    }
    useEffect(() => { Get() }, [product_id])
    return (
        <Box sx={{ p: 2, px: 3 }}>
            <Back prop={{ txt: 'Back' }} />
            <ProductHeader prop={res} />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', width: '100%' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '29%', height: '100%', }}>
                    <ProductImgs prop={res} />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '69%', height: '100%', }}>
                    <ProductDetails prop={res} />
                </Box>
            </Box>
        </Box>
    )
}