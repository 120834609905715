import { Box } from "@mui/material";
import { DataG_T_1 } from "../../../../../../../components/table/Data_Grid/type - 1";
import { ConfigStyleBar2 } from "../../../../../../../utils/data/IndividualDashboard/Data";
import { TableComp6 } from "../../../../../../../components/table/type - 6";

export const Preview = ({ prop }) => {
  return (
    <Box
      sx={{
        width: '100%',
        // width: "52vw",
        // maxWidth: "1200px",
        // maxWidth: "1200px",
        borderRadius: '4px',
        // minHeight: "420px",
        // overflowX: "auto",
      }}>
      {/* <OrdinaryTable1 Data={bundleData} Style={ConfigStyleBar2} /> */}
      {/* <DataG_T_1
        Data={prop.bundleData}
        Style={ConfigStyleBar2}
      /> */}
      <TableComp6 Data={prop.bundleData} Style={{ ...ConfigStyleBar2, StrictMaxWidth: true }} />
    </Box>
    // {/* <TableComp6 Data={bundleData} Style={ConfigStyleBar2} /> */}
  );
};
