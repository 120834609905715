import { useState } from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { Side_Nav } from '../../../../components/nav/side_nav';
import { Dashboard } from '../dashboard';
import { Top_Nav } from '../../../../components/nav/top_nav';
import { Outlet } from 'react-router-dom';
import CountryContext from '../../../../components/context/CountryContext';
import { DashboardTableData } from '../../../../utils/data/DashboardTableData';

export const Main = () => {
  const [country, setCountry] = useState(0);
  const [brand, setBrand] = useState(0);
  const [seller, setSeller] = useState(0);
  const [product, setProduct] = useState(0);
  const [separateProduct, setSeparateProduct] = useState({
    display: false,
    subBrand: 0,
  });
  const [subBrand, setSubBrand] = useState(0);
  return (
    <CountryContext.Provider
      value={{
        country,
        setCountry,
        brand,
        setBrand,
        subBrand,
        setSubBrand,
        seller,
        setSeller,
        product,
        setProduct,
        separateProduct,
        setSeparateProduct,
        DashboardTableData,
      }}>
      <Box sx={{ display: 'flex', height: '100vh' }}>
        <CssBaseline />
        <Side_Nav />
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <Top_Nav />
          {/* <Dashboard /> */}
          <Outlet />
        </Box>
      </Box>
    </CountryContext.Provider>
  );
};
