import { Box } from "@mui/material"
import { Logo } from "../../../assets/logo"
import { Link, useLocation } from "react-router-dom"
import { Plans } from "../../../utils/data/LandingPage/Data"
import OurPlans from "../home/private-components/screens/OurPlans"
import ComparePlans from "../home/private-components/screens/ComparePlans"
import { useEffect, useState } from "react"


export const Pricing = () => {
    const location = useLocation();
    const [id, setId] = useState(null)
    const getQueryParams = (search) => {
        console.log('ser', search)
        const params = new URLSearchParams(search);

        if (params.get("user_id")) {
            setId(params.get("user_id"));
        }

    };

    useEffect(() => {
        getQueryParams(location.search);
    }, [location.search]);

    return (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box sx={{ py: 6, width: { xs: '95%', md: '80%' } }}>
                    <Link to={'/'}>
                        <Logo />
                    </Link>
                </Box>
            </Box>
            <OurPlans Plans={Plans} id={id} />
            <ComparePlans />
        </Box>
    )
}