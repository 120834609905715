import { Box, Button } from '@mui/material';
import { Tick } from '../../../../../../../utils/icon/IndividualPages/Icon';

export const Selection = ({ prop }) => {
  return (
    <Box
      sx={{
        minHeight: '420px',
        width: '100%',
        border: '0.8px solid #797F8F80',
        borderRadius: '4px',
        p: 3,
      }}>
      {prop.responseData[prop.data[prop.state].arrayName]?.map((e, i) => (
        <Button
          key={i}
          variant={'outlined'}
          onClick={() => {
            prop.handleButtonClick(e.name, prop.data[prop.state].arrayName);
            console.log('ee', e, prop.responseData)
            prop.BtnAction(i);
          }}
          sx={{
            mx: 2,
            my: 1,
            textTransform: 'capitalize',
            borderColor: prop.selectedData[prop.data[prop.state].arrayName]?.includes(e.name)
              ? '#8A18D0'
              : '#73779173',
            color: prop.selectedData[prop.data[prop.state].arrayName]?.includes(e.name)
              ? '#000000'
              : '#474747',
            backgroundColor: prop.selectedData[prop.data[prop.state].arrayName]?.includes(e.name)
              ? '#F3E7FA'
              : (!e?.available ? '#47474766' : 'transparent'),
            fontWeight: '600',
            fontSize: '11px',
            textWrap: 'nowrap',
            width: '130px',
            position: 'relative',
            '&:hover': { borderColor: '#ac5edb' },
          }}
          disabled={!e?.available}
        >
          {prop.selectedData[prop.data[prop.state].arrayName]?.includes(e.name) && (
            <Box sx={{ position: 'absolute', top: '-4px', right: '-4px' }}>
              <Tick />
            </Box>
          )}
          {e.name}
        </Button>
      ))}
    </Box>
  );
};
