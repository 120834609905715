import { Box, Button, Rating, Typography } from '@mui/material';
import {
  BrandsIcon,
  CategoriesChoosenIcon,
  CountriesAddedIcon,
  EcommerceIcon,
  TotalCreditCostIcon,
} from '../../utils/icon/IndividualPages/Icon';
import StarIcon from '@mui/icons-material/Star';
import { dateClnse } from '../Public/DateClnse';
import { Link } from 'react-router-dom';
import { RoundedBtn } from './Button';
import { ActiveBundle } from './ApiCall';
import { ThreeDot2 } from './ThreeDots';

export const modifyDataIDP = (data) => {
  return data.map((item, index) => {
    return {
      ...item,
      card_datas: [
        {
          icon: TotalCreditCostIcon,
          title: 'Total Credit Cost',
          value: item.estimated_credits,
        },
        {
          icon: CategoriesChoosenIcon,
          title: 'Categories Chosen',
          value: '1',
        },
        {
          icon: CountriesAddedIcon,
          title: 'Countries Added',
          value: '1',
        },
        {
          icon: EcommerceIcon,
          title: 'E-Commerce Sites',
          value: item.ind_bundle_e_commerce.length.toString(),
        },
        {
          icon: BrandsIcon,
          title: 'Brands Selected',
          value: item.ind_bundle_brand.length.toString(),
        },
      ],
      table: {
        Category: item.ind_bundle_category,
        Country: item.ind_bundle_country,
        'E-Commerce Site': item.ind_bundle_e_commerce,
        Brand: item.ind_bundle_brand,
        'Configured Date': dateClnse(new Date()),
      },
    };
  });
};

export const modifyBundleRunLogTable = (d) => {
  if (d.length === 0) {
    return [{
      "S.No": '',
      Bundle_Name: '',
      Date: '',
      Time: '',
      Credit_Cost: ''
    }]
  }
  return d.map((e, i) => {
    const formattedDate = e.bundle_date ? dateClnse(new Date(e.bundle_date)) : '-';
    return {
      "S.No": d.length - i || '-',
      Bundle_Name: e.bundle_name || '-',
      Date: formattedDate,
      Time: e.bundle_time || '-',
      Credit_Cost: e.credit_cost || '-',
    }
  })
}


export const modifyRechargeCregitTable = (d) => {
  if (d.length === 0) {
    return [{
      Paid_by: '',
      Mail_ID: '',
      "Transaction No .": '',
      Payment_Type: '',
      Payment_Date: '',
      Credits_Added: '',
      // Invoice: ''
    }]
  }
  return d.map((e, i) => {
    const formattedPaymentDate = e.payment_date ? dateClnse(new Date(e.payment_date)) : '-';
    return {
      Paid_by: e.user_first_name || '-',
      Mail_ID: e.user_email || '-',
      "Transaction No .": e.transaction_no || '-',
      Payment_Type: e.payment_type || '-',
      Payment_Date: formattedPaymentDate,
      Credits_Added: e.applicable_credit || '-',
      // Invoice: e.Invoice
    }
  })
}
export const modifyDataABLP = (data) => {
  return data.map((item, index) => {
    return {
      ...item,
      card_datas: [
        {
          icon: TotalCreditCostIcon,
          title: 'Total Credit Cost',
          value: item.estimated_credits,
        },
        {
          icon: CategoriesChoosenIcon,
          title: 'Categories Chosen',
          value: '1',
        },
        {
          icon: CountriesAddedIcon,
          title: 'Countries Added',
          value: '1',
        },
        {
          icon: EcommerceIcon,
          title: 'E-Commerce Sites',
          value: item.ind_bundle_e_commerce.length.toString(),
        },
        {
          icon: BrandsIcon,
          title: 'Brands Selected',
          value: item.ind_bundle_brand.length.toString(),
        },
      ],
      table: {
        Category: item.ind_bundle_category,
        Country: item.ind_bundle_country,
        'E-Commerce Site': item.ind_bundle_e_commerce,
        Brand: item.ind_bundle_brand,
        'Configured Date': dateClnse(new Date(item.ind_bundle_config_date)),
        Frequency_of_data: item.frequency,
        'Active / Inactive': <ActiveBundle i={item} />,
      },
    };
  });
};

export const modifyUserLandingTable = ({ d, ee, data }) => {
  if (d.length === 0) {
    return [{
      'E-Commerce Site': '',
      minimum_price: '',
      maximum_price: '',
      sentiment: '',
      Average_Rating: '',
      No_Of_Sellers: '',
      No_Of_Products: '',
    }]
  }
  return d.map((e, i) => {
    return {
      'E-Commerce Site': e.site_name,
      minimum_price: e.min_price,
      maximum_price: e.max_price,
      sentiment: e.sentiment || '-',
      Average_Rating:
        (<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Rating name='rating' value={parseFloat(e.NoOfRatings)} precision={0.5} readOnly emptyIcon={<StarIcon fontSize='small' style={{ color: '#00000033' }} />} icon={<StarIcon fontSize='small' style={{ color: '#F4A403' }} />} />
          &nbsp;({e.NoOfRatings})
        </Box>),
      No_Of_Sellers: <Link to={`/user/Brands?category_name=${encodeURIComponent(ee?.category?.category_name)}&brand_name=${encodeURIComponent(data?.brand_name)}&ind_bundle_id=${encodeURIComponent(ee?.ind_bundle_id)}&brand_id=${encodeURIComponent(data?.brand_id)}&site_id=${encodeURIComponent(e?.site_id)}&card=${encodeURIComponent(i)}&type=${encodeURIComponent(1)}`} style={{ color: '#4F46E5', textDecorationLine: 'underline' }}>{e?.seller_count}</Link>,
      No_Of_Products: <Link to={`/user/Brands?category_name=${encodeURIComponent(ee?.category?.category_name)}&brand_name=${encodeURIComponent(data?.brand_name)}&ind_bundle_id=${encodeURIComponent(ee?.ind_bundle_id)}&brand_id=${encodeURIComponent(data?.brand_id)}&site_id=${encodeURIComponent(e?.site_id)}&card=${encodeURIComponent(i)}&type=${encodeURIComponent(0)}`} style={{ color: '#4F46E5', textDecorationLine: 'underline' }}>{e?.product_count}</Link>,
      // No_Of_Products: e.product_count,
    }
  })
}

export const modifyAdminLandingTable = ({ d, btnD }) => {
  return d.length === 0 || (d.length === 1 && d[0].user_id === '') ?
    ([{
      user_id: '-',
      user_first_name: '-',
      user_email: '-',
      Name: null,
      user_role: '-',
      joined_on: '-',
      invite_status: '-',
      user_status: '-',
    }])
    :
    (d.map((e, i) => {
      const formattedJoinedOn = e.joined_on ? new Date(e.joined_on).toLocaleDateString() : '-';
      return {
        user_id: e.user_id,
        user_first_name: e.user_first_name,
        user_email: e.user_email,
        user_status_og: e.user_status,
        invite_status_og: e.invite_status,
        Name: (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
            <Box sx={{ height: "40px", width: "40px", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "50%", background: "#474747", color: "#fff", fontSize: "20px", fontWeight: "bold", marginRight: "8px" }}>
              {e.user_first_name.charAt(0).toUpperCase()}
            </Box>
            <Box sx={{ textAlign: 'left' }}>
              <Typography sx={{ fontSize: '14px', fontWeight: '500', color: '#2B2B2B' }}>{e.user_first_name}</Typography>
              <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#2B2B2B', pb: 1 }}>{e.user_email}</Typography>
            </Box>
          </Box >
        ),
        user_role: e.user_role,
        joined_on: formattedJoinedOn,
        invite_status: e.invite_status === 'Pending' ? (<RoundedBtn col='#FF974C' txt={e.invite_status} />) : (<RoundedBtn col='#008000' txt={e.invite_status} />),
        user_status: e.user_status === 'Deactivate' ? (<RoundedBtn col='#FF974C' txt={e.user_status} />) : (<RoundedBtn col='#008000' txt={e.user_status} />),
        // '': <ThreeDot2 data={btnD} i={i} />
      }
    }))
}

export const modifyUserAllSellerTable = (d) => {
  if (d.length === 0) {
    return [{
      Seller_Name: '',
      No_Of_Products: '',
      'Reviews': '',
      'Ratings': '',
      '': '',
    }]
  }
  return d.map((e, i) => {
    return {
      Seller_Name: e.seller_name || '-',
      No_Of_Products: e.product_count || '-',
      'Reviews': e.reviews || '-',
      'Ratings': (<Box sx={{ display: 'flex' }}>
        <Rating name='rating' value={parseFloat(e.seller_ratings)} precision={0.5} readOnly emptyIcon={<StarIcon fontSize='small' style={{ color: '#00000033' }} />} icon={<StarIcon fontSize='small' style={{ color: '#F4A403' }} />} />
        &nbsp;({e.seller_ratings})
      </Box>),
      '': (
        <Link to={`/user/seller?seller_id=${encodeURIComponent(e?.seller_id)}`}>
          <Button sx={{ background: '#F3E7FA', height: '20px', color: '#8A18D0', borderRadius: '20px', textTransform: 'capitalize', '&:hover': { background: '#F3E7FA' } }}>View</Button>
        </Link>
      ),
    }
  })
}
export const modifyUserAllProductTable = (d) => {
  if (d.length === 0) {
    return [{
      produce_name: '',
      No_Of_Sellers: '',
      'Max Price (₹)': '',
      'Min Price (₹)': '',
      'MRP (₹)': '',
      '': '',
    }]
  }
  return d.map((e, i) => {
    return {
      product_name: e.product_name,
      No_Of_Sellers: e.no_of_sellers,
      'Max Price (₹)': e.max_price,
      'Min Price (₹)': e.min_price,
      'MRP (₹)': e.mrp,
      '': (
        <Link to={`/user/Product?product_id=${encodeURIComponent(e?.product_id)}`}>
          <Button sx={{ background: '#F3E7FA', height: '20px', color: '#8A18D0', borderRadius: '20px', textTransform: 'capitalize', '&:hover': { background: '#F3E7FA' } }}>View</Button>
        </Link>
      ),
    }
  })
}
export const modifyUserBrandProductTable = ({ d }) => {
  console.log('fn', d)
  if (d.length === 0) {
    return [{
      produce_name: '',
      No_Of_Sellers: '',
      'Max Price (₹)': '',
      'Min Price (₹)': '',
      'MRP (₹)': '',
      '': '',
    }]
  }

  return d.map((e, i) => {
    return {
      produce_name: e.product_name,
      No_Of_Sellers: e.no_of_sellers,
      'Max Price (₹)': e.max_price,
      'Min Price (₹)': e.min_price || '-',
      'MRP (₹)': e.mrp,
      '': (
        <Link to={`/user/Product?product_id=${encodeURIComponent(e?.product_id)}`}>
          <Button sx={{ background: '#F3E7FA', height: '20px', color: '#8A18D0', borderRadius: '20px', textTransform: 'capitalize', '&:hover': { background: '#F3E7FA' } }}>View</Button>
        </Link>
      ),
    }
  })
}

export const modifyUserBrandSellerTable = ({ d }) => {
  if (d.length === 0) {
    return [{
      seller_name: '',
      No_Of_Products: '',
      Reviews: '',
      Ratings: '',
      '': '',
    }]
  }

  return d.map((e, i) => {
    return {
      seller_name: e.seller_name,
      No_Of_Products: e.product_count,
      Reviews: e.seller_reviews || '-',
      Ratings: (
        <Box><Ratings prop={{ value: e.seller_ratings }} /></Box>
      ),
      '': (
        <Link to={`/user/seller?seller_id=${encodeURIComponent(e?.seller_id)}`}>
          <Button sx={{ background: '#F3E7FA', height: '20px', color: '#8A18D0', borderRadius: '20px', textTransform: 'capitalize', '&:hover': { background: '#F3E7FA' } }}>View</Button>
        </Link>
      ),
    }
  })
}

export const modifyUserSellerProductTable = (d) => {
  if (d.length === 0) {
    return [{
      brand: '',
      product_list: '',
      MRP: '',
      discount: '',
      final_price: '',
      Link: '',
    }]
  }

  return d.map((e, i) => {
    return {
      brand: e.brand_name,
      product_list: e.product_name,
      MRP: e.price,
      discount: e.discount,
      final_price: e?.max_p,
      Link: (
        <Link to={`/user/Product?product_id=${encodeURIComponent(e?.product_id)}`}>
          <Button sx={{ background: '#F3E7FA', height: '20px', color: '#8A18D0', borderRadius: '20px', textTransform: 'capitalize', '&:hover': { background: '#F3E7FA' } }}>View</Button>
        </Link>
      ),
    }
  })
}
export const modifyUserSellerEcormTable = (d) => {
  if (d.length === 0) {
    return [{
      'E-Commerce': '',
      Products: '',
      Total_Reviews: '',
      Total_Ratings: '',
    }]
  }

  return d.map((e, i) => {
    return {
      'E-Commerce': e.site_name,
      Products: e.no_of_products,
      Total_Reviews: e.reviews,
      Total_Ratings: (
        <Box><Ratings prop={{ value: e.seller_ratings }} />{('')}</Box>
      ),
    }
  })
}
export const modifyUserProductTable = (d) => {
  if (d.length === 0) {
    return [{
      Seller_Name: '',
      Price: '',
      Discount: '',
      Stock: '',
      Platform: '',
      Average_Rating: '',
    }]
  }

  return d.map((e, i) => {
    return {
      Seller_Name: e.seller_name,
      Price: e.seller_price,
      Discount: e.discount,
      Stock: e.stock,
      Platform: e.site_name,
      Average_Rating: e.seller_ratings,
      // Average_Rating: (
      //   <Box><Ratings prop={{ value: e.seller_ratings }} /></Box>
      // ),
    }
  })
}

export function base64ToBlob(base64, mime) {
  const byteCharacters = atob(base64);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: mime });
}
export function extractBase64Data(base64String) {
  const base64Data = base64String.split(",")[1];
  return base64Data;
}
export const Ratings = ({ prop }) => {
  return (
    <Rating name="rating" value={parseFloat(prop?.value)} precision={0.1} readOnly emptyIcon={<StarIcon fontSize="small" style={{ color: '#00000033' }} />} icon={<StarIcon fontSize="small" style={{ color: '#F4A403' }} />} />
  );
}
