import { Box, IconButton, Typography } from "@mui/material";
import { DownloadCSV } from "../../../../../../../components/Public/DownloadCSV";
import SettingsIcon from '@mui/icons-material/Settings';

export const Header = ({ prop }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}>
      <Box sx={{ mb: 1 }}>
        <Typography
          sx={{ fontWeight: '500', fontSize: '12px', color: '#8A18D0' }}>
          Step {prop.state + 1} of {prop.data.length}
        </Typography>
        <Typography
          sx={{ fontWeight: '600', fontSize: '16px', color: '#000000' }}>
          {prop.data[prop.state].title}
        </Typography>
        <Typography
          sx={{ fontWeight: '400', fontSize: '12px', color: '#797F8F' }}>
          {prop.data[prop.state].text}
        </Typography>
      </Box>
      {prop.state < 5 && (
        <IconButton
          sx={{ display: { xs: 'flex', md: 'none' } }}
          onClick={() => {
            prop.setMinimize(!prop.minimize);
          }}>
          <SettingsIcon />
        </IconButton>
      )}
      {prop.state === 5 && (
        <DownloadCSV
          data={prop.bundleData}
          fileName={`${prop.bName}_sample_data`}
          btnStyle={prop.btnStyle}
          btnName={'Sample Data'}
        />
      )}
    </Box>
  );
};
