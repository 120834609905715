import { Box, Typography, Avatar, Rating } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';

export const SimpleCard = ({ x, wd }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: { xs: 'auto', md: '100px' }, justifyContent: 'center', pl: { xs: 0, md: 3 }, borderRadius: '6px', background: '#F3E8FA', width: { xs: 'calc(50% - 8px)', sm: 'calc(50% - 8px)', md: wd }, }}>
      <Typography sx={{ fontWeight: '500', fontSize: '13px', color: '#000', }}>
        {x.title}
      </Typography>
      <Typography sx={{ fontWeight: '500', fontSize: '24px', color: '#000', }}>
        {x.value}
      </Typography>
    </Box>
  );
};

export const SimpleCard2 = ({ x, wd }) => {
  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', height: { xs: 'auto', md: '80px' }, py: 1, justifyContent: 'space-around', pl: { xs: 0, md: 3 }, borderRadius: '6px', background: '#F3E8FA', width: { xs: 'calc(50% - 8px)', sm: 'calc(50% - 8px)', md: wd }, }}>
      <Typography sx={{ fontWeight: '700', fontSize: '20px', color: '#2B2B2B', }}>
        {x.title}
      </Typography>
      <Typography sx={{ fontWeight: '500', fontSize: '12px', color: '#2B2B2B', }}>
        {x.value}
      </Typography>
    </Box>
  );
};


export const ReviewCard = ({ title, rating, date, content }) => {
  return (
    <Box sx={{ border: '1px solid #F3E8FA', borderRadius: '8px', padding: '10px', width: '100%', marginTop: '20px', backgroundColor: '#fff', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar alt="User Avatar" sx={{ marginRight: 2, backgroundColor: '#F3E8FA' }} />
          <Typography sx={{ fontWeight: '600', fontSize: '10px', color: '#2B2B2B' }}>
            {title}
          </Typography>
        </Box>
        <Typography sx={{ fontWeight: '500', fontSize: '12px', color: '#2B2B2B99' }}>
          {date}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 1 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Typography sx={{ mr: 1 }}>{rating}</Typography>
          <Rating
            name='rating' value={parseFloat(rating)} precision={0.5} readOnly emptyIcon={<StarIcon fontSize='small' style={{ color: '#00000033' }} />} icon={<StarIcon fontSize='small' style={{ color: '#F4A403' }} />}
          />
        </Box>
      </Box>
      <Typography sx={{ mt: 1, fontWeight: '400', fontSize: '10px', color: '#2B2B2B99' }}>
        {content}
      </Typography>
    </Box>
  );
};




