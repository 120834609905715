import { Box, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { cPlans } from "../../../../../utils/data/LandingPage/Data";
import ConfigurationsTable from "../components/ConfigurationsTable";

const ComparePlans = ({ need = true }) => {
  const [selectedPlans, setSelectedPlan] = useState(2);
  const handleClick = (i) => {
    setSelectedPlan(i);
  };
  return (
    <Box
      sx={{
        background: "#fff",
        width: "100%",
        maxWidth: "100%",
        display: "flex",
        justifyContent: "center",
        pt: 8,
        pb: 8,
      }}
    >
      <Box sx={{ width: { xs: need ? "95%" : '100%', md: need ? "80%" : '100%' } }}>
        <Box sx={{ textAlign: "center", mb: 5 }}>
          <Typography
            sx={{
              color: "#000",
              fontSize: { xs: "30px", md: "40px" },
              fontWeight: "600",
              pr: "10px",
              display: "inline-block",
            }}
          >
            Compare
          </Typography>
          <Typography
            sx={{
              color: "#8A18D0",
              fontSize: { xs: "30px", md: "40px" },
              fontWeight: "600",
              display: "inline-block",
            }}
          >
            Plans
          </Typography>
        </Box>
        <Grid container spacing={4}>
          <Grid item xs={12} md={3}>
            <Box
              sx={{
                textAlign: { xs: "center", md: "left" },
                mt: { xs: "10px", md: "40px" },
              }}
            >
              <Typography
                sx={{
                  color: "#000",
                  fontSize: { xs: "24px", md: "27px" },
                  fontWeight: "700",
                }}
              >
                Pick the Plan that
              </Typography>
              <Typography
                sx={{
                  color: "#8A18D0",
                  fontSize: { xs: "24px", md: "27px" },
                  fontWeight: "700",
                }}
              >
                Fits Your Needs
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={9}>
            <Grid container spacing={2}>
              {cPlans.map((e, i) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  key={i}
                  onClick={() => handleClick(i)}
                >
                  <Box
                    sx={{
                      border: "1px solid #E4E4E4",
                      color: selectedPlans === i ? "#FFFFFF" : "#000000",
                      background: selectedPlans === i ? "#000000" : "",
                      p: 3,
                      borderRadius: "8px",
                      height: "100%",
                    }}
                  >
                    <Typography sx={{ fontWeight: "500", fontSize: "24px" }}>
                      {e.title}
                    </Typography>
                    {e.text && (
                      <Typography
                        sx={{
                          fontWeight: "500",
                          fontSize: "14px",
                          color: "#727272",
                          mb: 3,
                        }}
                      >
                        {e.text}
                      </Typography>
                    )}
                    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                      {e.price && (
                        <Typography
                          sx={{
                            fontWeight: "500",
                            fontSize: "32px",
                            pr: "5px",
                          }}
                        >
                          {e.price}
                        </Typography>
                      )}
                      {e.discount && (
                        <Typography
                          sx={{
                            fontWeight: "500",
                            fontSize: "12px",
                            background:
                              selectedPlans === i ? "#4B4B4B" : "#F2F2F2",
                            padding: "2px 6px",
                            borderRadius: "4px",
                          }}
                        >
                          {e.discount}
                        </Typography>
                      )}
                    </Box>
                    {e.subText && (
                      <Typography
                        sx={{
                          fontWeight: "500",
                          fontSize: "12px",
                          color: selectedPlans === i ? "#AFAFAF" : "",
                        }}
                      >
                        {e.subText}
                      </Typography>
                    )}
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <ConfigurationsTable i={selectedPlans} />
      </Box>
    </Box>
  );
};

export default ComparePlans;
