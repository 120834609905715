import { Box, Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { BackIcon } from '../../../../../utils/icon/AdminPage/Icon';
import { BrandCards, BrandHeader, BrandTable } from './components';
import { Back } from '../../../../../components/@extended/Button';

export const Brands = () => {
  return (
    <Box sx={{ p: 2, px: 3 }}>
      <Back prop={{ txt: 'Back', url: '/User' }} />
      <BrandHeader />
      <BrandCards />
      <BrandTable />
    </Box>
  );
};
