import { Box } from "@mui/material";
import Sunburst from "sunburst-chart";
import * as d3 from "d3";
import 'd3-transition';
import { useEffect, useRef, useState } from "react";
import { SunburstDatas } from "../../../../../../utils/data/CorporateNormalUserDatas/Data";
import { ComboTxtBtn } from "../../../../../../components/@extended/Button";

export const Sunbursts = () => {
    const chartContainerRef = useRef(null);
    const [chartWidth, setChartWidth] = useState(400); // Default width in pixels

    useEffect(() => {
        const updateChartWidth = () => {
            if (chartContainerRef.current) {
                const containerWidth = chartContainerRef.current.offsetWidth - 3;
                setChartWidth(containerWidth); // Set the width to the container's width
            }
        };

        // Initial width calculation
        updateChartWidth();

        // Update width on window resize
        window.addEventListener('resize', updateChartWidth);
        // window.addEventListener('error', function (e) {
        //     // prevent React's listener from firing
        //     e.stopImmediatePropagation();
        //     // prevent the browser's console error message 
        //     e.preventDefault();
        // });
        return () => {
            window.removeEventListener('resize', updateChartWidth);
        };
    }, []);

    useEffect(() => {
        if (chartContainerRef.current) {
            // Clear existing chart (if any) to avoid overlapping
            chartContainerRef.current.innerHTML = "";

            // Define color scale
            // const color = d3.scaleOrdinal(d3.schemeAccent);

            // Create and render the Sunburst chart
            try {
                Sunburst()
                    ?.data(SunburstDatas)
                    ?.label("name")
                    ?.size("size")
                    ?.width(chartWidth)
                    ?.height(chartWidth)
                    // .transition()
                    // ?.transitionDuration(500) // Set a duration for the transition
                    ?.color((d, parent) => (d?.color ? d.color : parent ? '#fff' : null))
                    // ?.onTransitionEnd(() => { })
                    .tooltipContent((d, node) => `Size: <i>${node.value}</i>`)(
                        chartContainerRef.current
                    );
            } catch (error) {
                console.error("Error rendering Sunburst chart:", error);
            }
        }
    }, [chartWidth]);

    return (
        <Box sx={{ width: '30%', height: '100%' }}>
            <ComboTxtBtn prop={{ name: 'E-Commerce Brand Breakdown' }} />
            <Box sx={{ border: '1px solid #EAECF0', borderRadius: '8px', p: 2 }}>
                <Box sx={{ width: '100%', height: '100%' }} ref={chartContainerRef}>
                    {/* <div id="sunburst" style={{ width: chartWidth, minWidth: chartWidth, maxWidth: chartWidth, height: chartWidth, maxHeight: chartWidth }}></div> */}
                </Box>
            </Box>
        </Box>
    );
};