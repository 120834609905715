import { Box } from "@mui/material";
import { CorporateNormalUserTable } from "../../../../../../components/@extended/ModifiedTable"
import { modifyUserBrandProductTable, modifyUserBrandSellerTable } from "../../../../../../components/@extended/CustomFunctions"
import { AllProductData, AllSellerData } from "../../../../../../utils/data/CorporateNormalUserDatas/Data"
import { useState } from 'react';
import { Pie } from "./Pie";
import { DownloadIcn } from "../../../../../../utils/icon/IndividualPages/Icon";
import { HorizontalBar } from "./HorizontalBar";
import { MultiBarChart } from "./MultiBarChart";


export const PlatformDistribution = () => {
    const d = [
        { id: 0, value: '40', color: '#2BA1DB', label: 'Ibell (40%)', text: 'Ibell (40%) - 1,254', },
        { id: 1, value: '40', color: '#8A18D0', label: 'Makita (40%)', text: 'Makita (40%) - 1,254', },
        { id: 2, value: '20', color: '#F68D2B', label: 'Dewalt (20%)', text: 'Dewalt (20%) - 365', },
    ];
    const props = { data: d, title: 'Dewalt', num: '365', sideTitle: 'Based on Products', sideDatas: d, name: 'Platform Distribution', txtStyl: {}, btnIkn: '', btnTxt: '', btnLnk: '', fn: () => { } }
    // const props = { data: d, title: '', num: '', sideTitle: '', sideDatas: [{ text: '', color: '' }] }
    return (
        <Box sx={{ width: '33%' }}>
            <Pie prop={props} />
        </Box>
    );
}
export const SellerDistribution = () => {
    const d = [
        { id: 0, value: '45', color: '#2BA1DB', label: 'Makita (45%)', text: 'Makita (45%) - 54', },
        { id: 1, value: '30', color: '#8A18D0', label: 'Ibell (30%)', text: 'Ibell (30%) - 34', },
        { id: 2, value: '25', color: '#F68D2B', label: 'Dewalt (25%)', text: 'Dewalt (25%) - 28', },
    ];
    const props = { data: d, title: 'Makita', num: '54', sideTitle: 'Based on Platform', sideDatas: d, name: 'Seller Distribution', txtStyl: {}, btnIkn: '', btnTxt: '', btnLnk: '', fn: () => { } }
    // const props = { data: d, title: '', num: '', sideTitle: '', sideDatas: [{ text: '', color: '' }] }
    return (
        <Box sx={{ width: '33%' }}>
            <Pie prop={props} />
        </Box>
    );
}
export const ProductRating = () => {
    const d = [
        { id: 0, value: '5', color: '#2BA1DB', label: 'Makita (45%)', brand: 'Makita', text: 'Makita (45%) - 54', totalReview: '466', totalRatings: '6885', },
        { id: 1, value: '3', color: '#8A18D0', label: 'Ibell (30%)', brand: 'Ibell', text: 'Ibell (30%) - 34', totalReview: '156', totalRatings: '6868', },
        { id: 2, value: '1', color: '#F68D2B', label: 'Dewalt (25%)', brand: 'Dewalt', text: 'Dewalt (25%) - 28', totalReview: '5756', totalRatings: '284', },
    ];
    const props = { data: d, title: 'Makita', num: '54', sideTitle: 'Based on Platform', sideDatas: d, name: 'Product Rating', txtStyl: {}, btnIkn: <DownloadIcn />, btnTxt: 'Report', btnLnk: '', fn: () => { } }
    // const props = { data: d, title: '', num: '', sideTitle: '', sideDatas: [{ text: '', color: '' }] }
    return (
        <Box sx={{ width: '33%' }}>
            <HorizontalBar prop={props} />
            {/* <Pie prop={props} /> */}
        </Box>
    );
}

export const ProductListTable = () => {
    const [page, setPage] = useState(1);
    const [rowsPerPage] = useState(8);
    const tbl = modifyUserBrandProductTable({ d: AllProductData || [] });
    const filteredData = tbl

    if (filteredData.length === 0) {
        filteredData.push({
            produce_name: '',
            No_Of_Sellers: '',
            'Max Price (₹)': '',
            'Min Price (₹)': '',
            'MRP (₹)': '',
            '': '',
        });
    }
    const props = {
        // searchTerm,
        // setSearchTerm,
        filteredData,
        rowsPerPage,
        page,
        setPage
    };
    return (
        <Box>
            <CorporateNormalUserTable prop={props} />
        </Box>
    )
}
export const ProductPerBrand = () => {
    const d = [
        { id: 0, site: 'Amazon', Makita: 50, Ibell: 20, Dewalt: 25, color: ['#2BA1DB', '#8A18D0', '#F68D2B'] },
        { id: 1, site: 'Flipkart', Makita: 10, Ibell: 40, Dewalt: 15, color: ['#2BA1DB', '#8A18D0', '#F68D2B'] },
        { id: 2, site: 'Ebay', Makita: 24, Ibell: 25, Dewalt: 25, color: ['#2BA1DB', '#8A18D0', '#F68D2B'] },
        { id: 3, site: 'Indiamart', Makita: 44, Ibell: 22, Dewalt: 45, color: ['#2BA1DB', '#8A18D0', '#F68D2B'] },
    ];
    const props = { data: d, title: 'Makita', num: '54', sideTitle: 'Based on Platform', sideDatas: [{ color: '#2BA1DB', text: 'Makita (154)' }, { color: '#8A18D0', text: 'Ibell (101)' }, { color: '#F68D2B', text: 'Dewalt (54)' },], name: 'Products Per Brand ', txtStyl: {}, btnIkn: <DownloadIcn />, btnLnk: '', btnTxt: 'Report', fn: () => { } }
    // const props = { data: d, title: '', num: '', sideTitle: '', sideDatas: [{ text: '', color: '' }] }
    return (
        <Box sx={{ width: '99%' }}>
            <MultiBarChart prop={props} />
            {/* <Pie prop={props} /> */}
        </Box>
    );
}

export const SellerListTable = () => {
    const [page, setPage] = useState(1);
    const [rowsPerPage] = useState(8);
    const tbl = modifyUserBrandSellerTable({ d: AllSellerData || [] });
    const filteredData = tbl

    if (filteredData.length === 0) {
        filteredData.push({
            seller_name: '',
            No_Of_Products: '',
            Reviews: '',
            Ratings: '',
            '': '',
        });
    }
    const props = {
        // searchTerm,
        // setSearchTerm,
        filteredData,
        rowsPerPage,
        page,
        setPage
    };
    return (
        <Box>
            <CorporateNormalUserTable prop={props} />
        </Box>
    )
}
export const SellerPerBrand = () => {
    const d = [
        { id: 0, site: 'Amazon', Makita: 50, Ibell: 20, Dewalt: 25, color: ['#2BA1DB', '#8A18D0', '#F68D2B'] },
        { id: 1, site: 'Flipkart', Makita: 10, Ibell: 40, Dewalt: 15, color: ['#2BA1DB', '#8A18D0', '#F68D2B'] },
        { id: 2, site: 'Ebay', Makita: 24, Ibell: 25, Dewalt: 25, color: ['#2BA1DB', '#8A18D0', '#F68D2B'] },
        { id: 3, site: 'Indiamart', Makita: 44, Ibell: 22, Dewalt: 45, color: ['#2BA1DB', '#8A18D0', '#F68D2B'] },
    ];
    const props = { data: d, title: 'Makita', num: '54', sideTitle: 'Based on Platform', sideDatas: [{ color: '#2BA1DB', text: 'Makita (154)' }, { color: '#8A18D0', text: 'Ibell (101)' }, { color: '#F68D2B', text: 'Dewalt (54)' },], name: 'Sellers Per Brand ', txtStyl: {}, btnIkn: <DownloadIcn />, btnLnk: '', btnTxt: 'Report', fn: () => { } }
    // const props = { data: d, title: '', num: '', sideTitle: '', sideDatas: [{ text: '', color: '' }] }
    return (
        <Box sx={{ width: '99%' }}>
            <MultiBarChart prop={props} />
            {/* <Pie prop={props} /> */}
        </Box>
    );
}