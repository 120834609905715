import { createSlice } from '@reduxjs/toolkit';

export const ProfilePicSlice = createSlice({
    name: 'ProfilePic',
    initialState: {
        ProfilePic: '',
        name: '',
    },
    reducers: {
        setImage: (state, action) => {
            console.log('Redux updateBundle action:', state, action);
            state.ProfilePic = action.payload;
        },
        setName: (state, action) => {
            console.log('Redux updateBundle action:', state, action);
            state.name = action.payload;
        },
        clearImage: (state) => {
            state.ProfilePic = '';
        },
    },
});

// Export the actions
export const { setImage, clearImage } = ProfilePicSlice.actions;

// Export the reducer
export default ProfilePicSlice.reducer;
