import {
  Box, Typography, Button, Snackbar, Alert,
} from '@mui/material';
import { TableComp6 } from '../../../../../components/table/type - 6/index.jsx';
import { ConfigStyleBar } from '../../../../../utils/data/IndividualDashboard/Data.js';
import { CorporateNormalStyleBar2, Data } from '../../../../../utils/data/CorporateNormalUserDatas/Data.js';
import { modifyUserLandingTable } from '../../../../../components/@extended/CustomFunctions.jsx';
import { useState, useEffect } from 'react';
import http from "../../../../../utils/http-common.js"
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const CorporateNormalDashboard = () => {
  const [tblData, setTblData] = useState(JSON.parse(localStorage.getItem('DashboardData')) || []);
  const nav = useSelector((state) => state.navs.sideNav.nav);
  const [bundle, setBundle] = useState(0)
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success');
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const fetchDatas = async () => {
    try {
      const response = await http.post(
        '/master_service/dashboard/page',
        JSON.stringify({ user_id: localStorage.getItem('user_id') })
      );
      console.log('response', response?.data);
      setTblData(response?.data?.bundles);
      localStorage.setItem('DashboardData', JSON.stringify(response?.data?.bundles))
      // if (response.status === 200) {
      //   setMessage("data fetched successfully");
      //   setSeverity("success");
      //   handleClick();
      // }
    }
    catch (error) {
      console.error('Error fetching data:', error?.response?.data?.message);
      setMessage(
        error?.response
          ? error?.response?.data?.message
          : 'Unable to Fetch the Data.'
      );
      setSeverity('error');
      handleClick();
    }
  }
  useEffect(() => {
    fetchDatas();
  }, []);
  useEffect(() => {
    if (nav !== -1) {
      setBundle(nav - 1)
    } else if (nav === -1) {
      setBundle(0)
    }
  }, [nav]);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        p: '25px',
        pt: 2,
      }}
    >
      <Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            alignItems: 'center',
          }}
        >
          <Typography sx={{ fontSize: '20px', fontWeight: '600', color: '#2B2B2B' }}>{tblData[bundle]?.category?.category_name}</Typography>
          <Link to={'/user/Report'}>
            <Button
              sx={{
                mt: '20px',
                textAlign: 'center',
                background: '#8A18D0',
                color: '#fff',
                width: '80px',
                fontSize: '12px',
                textTransform: 'capitalize',
                '&:hover': { background: '#B37FFC' },
              }}
            >
              Report
            </Button>
          </Link>
        </Box>
        <Typography sx={{ fontSize: '16px', fontWeight: '600', color: '#2B2B2B' }}>Brands</Typography>
        <Box sx={{}}>
          {tblData[bundle]?.brands.map((data, index) => (
            <Box key={index} sx={{ my: 2, borderRadius: '8px', borderTop: '1px solid #DADADA', borderRight: '1px solid #DADADA', borderLeft: '1px solid #DADADA', }}>
              <Box sx={{ p: 1, pl: 4 }}>
                <Typography sx={{ fontSize: '14px', fontWeight: '600', color: '#2B2B2B' }}>{data.brand_name}</Typography>
                <a href={data.brand_url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                  <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#2B2B2BCC', cursor: 'pointer' }}>
                    {data.brand_url}
                  </Typography>
                </a>
              </Box>
              <TableComp6 Data={modifyUserLandingTable({ d: data?.ecommerce || [], ee: tblData[bundle], data: data || [] })} Style={{ ...CorporateNormalStyleBar2, isThreeDot: false }} />
            </Box>
          ))}
        </Box>
      </Box>
      {/* {tblData?.map((bundle, i) => (
        <Box key={i}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              alignItems: 'center',
            }}
          >
            <Typography sx={{ fontSize: '20px', fontWeight: '600', color: '#2B2B2B' }}>{bundle.category.category_name}</Typography>
            <Link to={'/user/Report'}>
              <Button
                sx={{
                  mt: '20px',
                  textAlign: 'center',
                  background: '#8A18D0',
                  color: '#fff',
                  width: '100px',
                  textTransform: 'capitalize',
                  '&:hover': { background: '#B37FFC' },
                }}
              >
                Report
              </Button>
            </Link>
          </Box>
          <Typography sx={{ fontSize: '16px', fontWeight: '600', color: '#2B2B2B' }}>Brands</Typography>
          <Box sx={{}}>
            {bundle.brands.map((data, index) => (
              <Box key={index} sx={{ my: 2, borderRadius: '8px', borderTop: '1px solid #DADADA', borderRight: '1px solid #DADADA', borderLeft: '1px solid #DADADA', }}>
                <Box sx={{ p: 1, pl: 4 }}>
                  <Typography sx={{ fontSize: '14px', fontWeight: '600', color: '#2B2B2B' }}>{data.brand_name}</Typography>
                  <a href={data.brand_url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                    <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#2B2B2BCC', cursor: 'pointer' }}>
                      {data.brand_url}
                    </Typography>
                  </a>
                </Box>
                <TableComp6 Data={modifyUserLandingTable({ d: data?.ecommerce || [], ee: bundle, data: data || [] })} Style={{ ...CorporateNormalStyleBar2, isThreeDot: false }} />
              </Box>
            ))}
          </Box>
        </Box>
      ))} */}
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </Box>
  );
};
