import { Button, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { useContext } from "react";
import { Link } from "react-router-dom";
import CountryContext from "../../context/CountryContext";


export const TableComp = ({ Data }) => {
    const { country, setCountry, brand, setBrand, subBrand, setSubBrand, seller, setSeller, product, setProduct, separateProduct, setSeparateProduct, DashboardTableData } = useContext(CountryContext)
    return (
        <Table aria-label="brand table">
            <TableHead sx={{ background: '#1DA1F21A' }}>
                <TableRow>
                    <TableCell>Sl. No</TableCell>
                    <TableCell>Product Name</TableCell>
                    <TableCell>Max Price (₹)</TableCell>
                    <TableCell>Min Price (₹)</TableCell>
                    <TableCell>Discount (%)</TableCell>
                    <TableCell>MRP (₹)</TableCell>
                    {/* <TableCell></TableCell> */}
                </TableRow>
            </TableHead>
            {separateProduct.display ? (
                <TableBody sx={{ overflow: 'auto' }}>
                    {Data[brand].separateProductDatas[separateProduct.subBrand].productDatas.map((row, i) => (
                        <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 }, height: '60px' }}>
                            <TableCell>{i + 1}.</TableCell>
                            <TableCell>
                                <Link to={'/Product'} onClick={() => setProduct(i)} style={{ display: 'flex', flexWrap: 'wrap' }}>{row.product}</Link>
                            </TableCell>
                            <TableCell>{row.max_p}</TableCell>
                            <TableCell>{row.min_p}</TableCell>
                            <TableCell>{row.discount}</TableCell>
                            <TableCell>{row.mrp}</TableCell>
                            {/* <TableCell sx={{ height: '100%', verticalAlign: 'middle' }}>
                            <Link to={'/Product'}>
                                <Button
                                    sx={{
                                        cursor: "pointer",
                                        fontWeight: "500",
                                        fontSize: "12px",
                                        color: "#1DA1F2",
                                        px: "17px",
                                        py: 0,
                                        height: "25px",
                                        width: "min-content",
                                        display: "flex",
                                        alignItems: "center",
                                        ml: "5px",
                                        background: "#E8F6FE",
                                        borderRadius: "16px",
                                    }}
                                    onClick={() => setProduct(i)}
                                >
                                    View
                                </Button>
                            </Link>
                        </TableCell> */}
                        </TableRow>
                    ))}
                </TableBody>
            ) : (
                <TableBody sx={{ overflow: 'auto' }}>
                    {Data[brand].productTableDatas.map((row, i) => (
                        <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 }, height: '60px' }}>
                            <TableCell>{i + 1}.</TableCell>
                            <TableCell>
                                <Link to={'/Dashboard/Product'} onClick={() => setProduct(i)} style={{ display: 'flex', flexWrap: 'wrap' }}>{row.product}</Link>
                            </TableCell>
                            <TableCell>{row.max_p}</TableCell>
                            <TableCell>{row.min_p}</TableCell>
                            <TableCell>{row.discount}%</TableCell>
                            <TableCell>{row.mrp}</TableCell>
                            {/* <TableCell sx={{ height: '100%', verticalAlign: 'middle' }}>
                            <Link to={'/Product'}>
                                <Button
                                    sx={{
                                        cursor: "pointer",
                                        fontWeight: "500",
                                        fontSize: "12px",
                                        color: "#1DA1F2",
                                        px: "17px",
                                        py: 0,
                                        height: "25px",
                                        width: "min-content",
                                        display: "flex",
                                        alignItems: "center",
                                        ml: "5px",
                                        background: "#E8F6FE",
                                        borderRadius: "16px",
                                    }}
                                    onClick={() => setProduct(i)}
                                >
                                    View
                                </Button>
                            </Link>
                        </TableCell> */}
                        </TableRow>
                    ))}
                </TableBody>
            )}
        </Table>
    );
}
