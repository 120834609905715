import { RoundedBtn } from "./Button"
import http from '../../utils/http-common'
import { modifyDataABLP } from "./CustomFunctions";
import { updateCredit } from "../../slice/credits";
import { useDispatch } from "react-redux";
import { updateBundles } from "../../slice/allBundles";
import { useCallback } from "react";


export const useFetchDatas = () => {
    const dispatch = useDispatch();
    
    const FetchDatas = useCallback(async () => {
      try {
        const response = await http.post(
          '/master_service/corporate_bundle_list',
          JSON.stringify({ user_id: localStorage.getItem('user_id') })
        );
        if (response.data.data.length !== 0) {
          const modifiedData = modifyDataABLP(response.data.data);
          dispatch(updateBundles(modifiedData)); // Update allBundles state
          dispatch(
            updateCredit({
              available_credit: response.data.available_credits,
              used_credit: response.data.used_credits,
            })
          );
        }
      } catch (error) {
        console.log('fetching error', error);
      }
    }, [dispatch]);
  
    return FetchDatas;
  };
  

export const ActiveBundle = ({ i }) => {
    const FetchDatas = useFetchDatas();
    const Api = async () => {
        const pay = {
            ind_bundle_id: i.ind_bundle_id,
            user_id: localStorage.getItem('user_id'),
            bundle_status: i.bundle_status === 'Active' ? 'Deactivate' : 'Active'
        }
        try {
            const response = http.post('master_service/bundle_status', pay)
            console.log(response, 'response')
            FetchDatas()
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <RoundedBtn txt={i.bundle_status} col={i.bundle_status === 'Active' ? '#008000' : '#FF4C51'} fn={Api} />
    )
}