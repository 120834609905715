import { useSelector } from 'react-redux';
import { store } from '../../store';

export const Tk = () => {
  const a = useSelector((state) => state.token.token);
  return a;
};

export const Tk2 = () => {
  return store.getState().token.token;
};
