import { useState } from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TopNav from "../home/private-components/nav/TopNav";
import Footer from "../home/private-components/screens/Footer.jsx";
import { Element } from "react-scroll";
import { Link } from "react-router-dom";
import { scroller } from "react-scroll";
import { PlusIcon,CustomMinusIcon } from "../../../utils/icon/FaqPage/Icon.jsx";
import { faq } from "../../../utils/data/FaqPage/Data.js";

export const Faq = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        color: "#fff",
        minHeight: "100vh",
        fontFamily: "Inter",
        // px: { xs: 2, md: 0 }, // Add padding for mobile
      }}
    >
      <Box
        sx={{
          width: "100%",
          // pb: 6,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: { xs: "85%", md: "85%" },
            position: "sticky",
            top: 0,
            zIndex: 999,
          }}
        >
          <TopNav />
        </Box>
        <Box sx={{ width: { xs: "100%", md: "100%" } }}>
          <Element name="faq">
            <FirstPage />
          </Element>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            background: "#000000",
            mt: 4,
            width: "100%",
          }}
        >
          <Box sx={{ width: { xs: "100%", md: "80%" } }}>
            <Element name="about-us">
              <Footer />
            </Element>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const NestedAccordion = ({ items, expanded, setExpanded }) => {
  return (
    <>
      {items.map((item, index) => (
        <Accordion
          key={index}
          expanded={expanded === index}
          onChange={() => setExpanded(expanded === index ? false : index)}
          sx={{
            my: 1,
            borderRadius: "8px",
            border:
              expanded === index ? "1px solid #000000" : "1px solid #AFAFAF",
            boxShadow: 0,
            width: "100%",
            px: 1,
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              padding: { xs: "8px", sm: "0px" },
            }}
          >
            <Typography
              sx={{
                flex: 1,
                px: 2,
                textAlign: "left",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "normal",
                fontSize: { xs: "14px", sm: "16px" },
                fontWeight: "500",
                color: expanded === index ? "#8A18D0" : "#000",
              }}
            >
              {item.question}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              width: "100%",
              px: 2,
              py: 4,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                textAlign: "left",
                fontSize: { xs: "14px", sm: "16px" },
                fontWeight: "400",
                color: "#727272",
                my: -3,
                pb: 2,
              }}
            >
              {/* {typeof item.answer === "string"
                ? item.answer
                : item.answer.map((part, idx) =>
                    typeof part === "string" ? (
                      part
                    ) : (
                      <Link
                        key={idx}
                        onClick={() => {
                          setTimeout(() => {
                            scroller.scrollTo(part.to, {
                              smooth: true,
                              duration: 1500,
                            });
                          }, 750);
                        }}
                        // to={part.to}
                        to={part.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          color: "#8A18D0",
                          textDecoration: "underline",
                        }}
                      >
                        {part.text}
                      </Link>
                    )
                  )} */}
              {/* {typeof item.answer === "string"
                ? item.answer
                : item.answer.map((part, idx) =>
                  typeof part === "string" ? (
                    part
                  ) : (
                    <Link
                      key={idx}
                      to={part.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        color: "#8A18D0",
                        textDecoration: "underline",
                        pb: 2,
                        my: 4,
                      }}
                    >
                      {part.text}
                    </Link>
                  )
                )} */}
              {typeof item.answer === "string"
                ? item.answer
                : item.answer.map((part, idx) => {
                    if (typeof part === "string") {
                      return (
                        <Typography
                          key={idx}
                          sx={{
                            textAlign: "left",
                            fontSize: { xs: "14px", sm: "16px" },
                            fontWeight: "400",
                            color: "#727272",
                            display: "inline", // Ensure text flows continuously
                          }}
                        >
                          {part}
                        </Typography>
                      );
                    } else if (part.point) {
                      return (
                        <ListItem key={idx} sx={{ py: 0, px: 3 }}>
                          <Typography
                            sx={{
                              textAlign: "left",
                              fontSize: { xs: "14px", sm: "16px" },
                              fontWeight: "400",
                              color: "#727272",
                              display: "list-item",
                            }}
                          >
                            {part.point}
                          </Typography>
                        </ListItem>
                      );
                    } else {
                      return (
                        <Link
                          key={idx}
                          to={part.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            color: "#8A18D0",
                            textDecoration: "underline",
                            pb: 2,
                            my: 4,
                          }}
                        >
                          {part.text}
                        </Link>
                      );
                    }
                  })}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

const FirstPage = () => {
  const [expanded, setExpanded] = useState(false);
  const [nestedExpanded, setNestedExpanded] = useState(false);

  const handleMainAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    setNestedExpanded(false);
  };

  return (
    <Box
      sx={{
        background: "#fff",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        mt: { xs: "0", md: "-75px" }, // Adjust margin for mobile
        pt: { xs: "80px", md: "120px" }, // Adjust padding for mobile
        px: { xs: 2, md: 0 }, // Add padding for mobile
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          width: { xs: "100%", md: "90%" },
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: "32px", md: "58px" }, // Responsive font size
            fontWeight: "600",
            color: "#000000",
            mb: 2, // Add bottom margin for spacing
          }}
        >
          Frequently Asked
          <span
            style={{
              background:
                "linear-gradient(to right, #8A17D0 50%, #CA3FC4 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              fontSize: "inherit",
              fontWeight: "600",
            }}
          >
            {" "}
            Questions
          </span>
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: "16px", md: "20px" }, // Responsive font size
            fontWeight: "500",
            color: "#000000",
            mb: 4, // Add bottom margin for spacing
          }}
        >
          Still have questions?{""}
          <Link
            to={"/contact"}
            style={{
              fontWeight: "600",
              fontSize: { xs: "16px", md: "20px" }, // Responsive font size
              color: "#000000",
              textDecoration: "none",
              borderBottom: "2px solid #000000",
              margin: "0 5px",
            }}
          >
            Contact Us
          </Link>
          and we'll be happy to assist you!
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            p: 2,
          }}
        >
          {faq.map((e, i) => (
            <Accordion
              key={i}
              expanded={expanded === i}
              onChange={handleMainAccordionChange(i)}
              sx={{
                my: 1,
                borderRadius: "8px",
                boxShadow: 0,
                width: "100%",
                px: 0,
                border: "none",
                borderTop: "none",
                borderBottom: "none",
                "&:before": {
                  display: "none",
                },
                "&:after": {
                  display: "none",
                },
                "& .MuiAccordionSummary-root": {
                  borderBottom: "none",
                },
                "& .MuiAccordionDetails-root": {
                  borderTop: "none",
                },
                "& .MuiAccordionSummary-expandIcon": {
                  padding: 0,
                },
              }}
            >
              <AccordionSummary
                expandIcon={
                  expanded === i ? (
                    <CustomMinusIcon
                      width="35"
                      height="35"
                      sx={{
                        backgroundColor: "black",
                        color: "white",
                        borderRadius: "50%",
                      }}
                    />
                  ) : (
                    <PlusIcon />
                  )
                }
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    flex: 1,
                    textAlign: "left",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    fontSize: { xs: "18px", md: "24px" }, // Responsive font size
                    fontWeight: "600",
                    color: "#000000",
                    whiteSpace: "normal",
                  }}
                >
                  {e.title}
                </Typography>
              </AccordionSummary>

              <AccordionDetails
                sx={{
                  width: "100%",
                  px: 2,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <NestedAccordion
                  items={e.subTitle}
                  expanded={nestedExpanded}
                  setExpanded={setNestedExpanded}
                />
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default FirstPage;
