import React, { useEffect, useState } from 'react';
import {
  Box,
  ListItem,
  ListItemButton,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link, useLocation } from 'react-router-dom';
import { Logo } from '../../../assets/logo';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { sideNav } from '../../../utils/data/IndividualDashboard/Data';
import { QueriesSvg } from '../../../utils/icon/IndividualPages/Icon';
import { useDispatch, useSelector } from 'react-redux';
import { setNav, setSubNav } from '../../../slice/sideNav';

export const SideNav_3 = ({
  //   nav,
  //   subNav,
  minimize,
  smallScreen,
  setSmallScreen,
  setMinimize,
  //   setSubNav,
  //   setNav,
  Data,
  routesMap,
  commonPath,
}) => {
  const ut = localStorage.getItem('user_type');
  const location = useLocation();
  const nav = useSelector((state) => state.navs.sideNav.nav);
  const subNav = useSelector((state) => state.navs.sideNav.subNav);
  const dispatch = useDispatch();
  console.log('sta', nav, subNav, location, location.pathname);
  useEffect(() => {
    if (nav === -1 && subNav === -1 && location.pathname !== commonPath) {
      console.log('ent rot');
      const newRoute = routesMap.find(
        (route) => route.map === location.pathname
      );
      console.log('rot', newRoute);
      if (newRoute) {
        if (newRoute.nav) {
          dispatch(setNav(newRoute.no));
        } else {
          dispatch(setSubNav(newRoute.no));
        }
      }
    }
  }, [location, location.hash, location.key, location.pathname]);

  return (
    <Box
      sx={{
        display: minimize || !smallScreen ? 'flex' : 'none',
        flexDirection: 'column',
        position: smallScreen && { xs: 'absolute', md: 'static' },
        zIndex: 10,
        height: '100vh',
        Width: minimize ? '90px' : '350px',
        background: { xs: 'rgba(0, 0, 0, 0.889)', md: '#000' },
        color: '#fff',
      }}>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: { xs: 'flex-start', sm: 'center' },
          py: { xs: 1, sm: 5 },
        }}>
        {!minimize && (
          <Link
            to={ut === 'company' ? '/Admin' : '/Dashboard/Individual-Dashboard'}
            onClick={() => dispatch(setNav(-1))}>
            <Logo />
          </Link>
        )}
        {smallScreen && (
          <IconButton
            sx={{ mx: { xs: 1, sm: 2 } }}
            onClick={() => setMinimize(false)}>
            <MenuOpenIcon sx={{ color: '#fff' }} />
          </IconButton>
        )}
      </Box>
      <Box
        sx={{
          height: '85%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          pt: { xs: 5, md: 0 },
        }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          {Data.p1.map((e, i) =>
            e.drop ? (
              <ListItem
                disableRipple
                disableGutters
                disablePadding
                sx={{ width: '100%', px: 2 }}
                key={i}>
                <Accordion
                  key={i}
                  sx={{
                    background: '#000',
                    color: '#fff',
                    py: 0,
                    width: '100%',
                  }}>
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreIcon
                        sx={{ color: nav === i ? '#000000' : '#ffffff' }}
                      />
                    }
                    sx={{
                      width: '100%',
                      borderRadius: '3px',
                      px: 0,
                      py: 0,
                      height: '56px',
                      background: nav === i ? '#FFFFFF' : '',
                      color: nav === i ? '#000000' : '',
                    }}>
                    <ListItemButton
                      sx={{
                        display: 'flex',
                        width: '100%',
                        borderRadius: '3px',
                        color: nav === i ? '#000000' : '',
                        py: 2,
                        '&:hover': {
                          background: nav === i ? '#FFFFFF' : '#000',
                        },
                      }}
                      onClick={() => {
                        dispatch(setNav(i));
                        dispatch(setSubNav(-1));
                      }}>
                      {e.icon}
                      <Typography sx={{ pl: '15px', textWrap: 'nowrap' }}>
                        {e.name}
                      </Typography>
                    </ListItemButton>
                  </AccordionSummary>
                  <AccordionDetails sx={{ px: 0, py: 0 }}>
                    {e.subNav.map((f, j) => (
                      <Link
                        to={f.to}
                        className='w-[100%]'
                        key={j}>
                        <ListItemButton
                          disableTouchRipple
                          disableFocusRipple
                          disableGutters
                          disablePadding
                          sx={{
                            display: 'flex',
                            background: subNav === j ? '#FFFFFF' : '',
                            width: '100%',
                            borderRadius: '3px',
                            color: subNav === j ? '#000000' : '',
                            py: 2,
                            '&:hover': {
                              background: subNav === j ? '#FFFFFF' : '#000',
                            },
                          }}
                          onClick={() => {
                            setSubNav(j);
                            dispatch(setNav(-1));
                          }}>
                          <Typography sx={{ pl: '15px', textWrap: 'nowrap' }}>
                            {f.name}
                          </Typography>
                        </ListItemButton>
                      </Link>
                    ))}
                  </AccordionDetails>
                </Accordion>
              </ListItem>
            ) : (
              <ListItem
                disableTouchRipple
                disableFocusRipple
                disableGutters
                disablePadding
                sx={{ width: '100%', px: minimize ? '5px' : 2 }}
                key={i}>
                <Link
                  to={e.to}
                  className='w-[100%]'>
                  <ListItemButton
                    disableTouchRipple
                    disableFocusRipple
                    disableGutters
                    disablePadding
                    sx={{
                      display: 'flex',
                      flexDirection: minimize ? 'column' : 'row',
                      alignItems: 'center',
                      background: nav === i ? '#FFFFFF' : '',
                      width: '100%',
                      minWidth: !minimize && '250px',
                      borderRadius: '3px',
                      color: nav === i ? '#000000' : '',
                      py: 2,
                      px: minimize ? 0 : 3,
                      '&:hover': { background: nav === i ? '#FFFFFF' : '#000' },
                    }}
                    onClick={() => {
                      dispatch(setNav(i));
                      dispatch(setSubNav(-1));
                      if (smallScreen) {
                        setMinimize(false);
                      }
                    }}>
                    {e.icon}
                    <Typography
                      sx={{
                        pl: minimize ? 0 : '15px',
                        pt: minimize && 1,
                        textWrap: 'nowrap',
                      }}>
                      {e.name}
                    </Typography>
                  </ListItemButton>
                </Link>
              </ListItem>
            )
          )}
        </Box>
        {Data.p2 && (
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            {/* <ListItem disablePadding sx={{ width: '100%', px: 2 }} >
                    <Link to={'/Dashboard/Individual-Dashboard/Raise-Ticket'} className='w-[100%]'>
                        <ListItemButton sx={{ display: 'flex', background: nav === Data.length ? '#FFFFFF' : '', width: '100%', borderRadius: '3px', color: nav === Data.length ? '#000000' : '', py: 2, '&:hover': { background: nav === Data.length ? '#FFFFFF' : '#000' } }} onClick={() => setNav(Data.length)}>
                            <QueriesSvg />
                            <Typography sx={{ pl: '15px', textWrap: 'nowrap' }}>Any Queries?</Typography>
                        </ListItemButton>
                    </Link>
                </ListItem> */}
            {Data.p2.map((e, i) =>
              e.drop ? (
                <ListItem
                  disableRipple
                  disableGutters
                  disablePadding
                  sx={{ width: '100%', px: 2 }}
                  key={i}>
                  <Accordion
                    key={i}
                    sx={{
                      background: '#000',
                      color: '#fff',
                      py: 0,
                      width: '100%',
                    }}>
                    <AccordionSummary
                      expandIcon={
                        <ExpandMoreIcon
                          sx={{
                            color:
                              nav === Data.p1.length + i
                                ? '#000000'
                                : '#ffffff',
                          }}
                        />
                      }
                      sx={{
                        width: '100%',
                        borderRadius: '3px',
                        px: 0,
                        py: 0,
                        height: '56px',
                        background: nav === Data.p1.length + i ? '#FFFFFF' : '',
                        color: nav === Data.p1.length + i ? '#000000' : '',
                      }}>
                      <ListItemButton
                        disableTouchRipple
                        disableFocusRipple
                        disableGutters
                        disablePadding
                        sx={{
                          display: 'flex',
                          width: '100%',
                          borderRadius: '3px',
                          color: nav === Data.p1.length + i ? '#000000' : '',
                          py: 2,
                          '&:hover': {
                            background:
                              nav === Data.p1.length + i ? '#FFFFFF' : '#000',
                          },
                        }}
                        onClick={() => {
                          dispatch(setNav(i));
                          dispatch(setSubNav(-1));
                        }}>
                        {e.icon}
                        <Typography sx={{ pl: '15px', textWrap: 'nowrap' }}>
                          {e.name}
                        </Typography>
                      </ListItemButton>
                    </AccordionSummary>
                    <AccordionDetails sx={{ px: 0, py: 0 }}>
                      {e.subNav.map((f, j) => (
                        <Link
                          to={f.to}
                          className='w-[100%]'
                          key={j}>
                          <ListItemButton
                            disableTouchRipple
                            disableFocusRipple
                            disableGutters
                            disablePadding
                            sx={{
                              display: 'flex',
                              background:
                                subNav === Data.p1.length + j ? '#FFFFFF' : '',
                              width: '100%',
                              borderRadius: '3px',
                              color:
                                subNav === Data.p1.length + j ? '#000000' : '',
                              py: 2,
                              '&:hover': {
                                background:
                                  subNav === Data.p1.length + j
                                    ? '#FFFFFF'
                                    : '#000',
                              },
                            }}
                            onClick={() => {
                              dispatch(setSubNav(Data.p1.length + j));
                              dispatch(setNav(-1));
                            }}>
                            <Typography sx={{ pl: '15px', textWrap: 'nowrap' }}>
                              {f.name}
                            </Typography>
                          </ListItemButton>
                        </Link>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                </ListItem>
              ) : (
                <ListItem
                  disableTouchRipple
                  disableFocusRipple
                  disableGutters
                  disablePadding
                  sx={{ width: '100%', px: minimize ? '5px' : 2 }}
                  key={i}>
                  <Link
                    to={e.to}
                    className='w-[100%]'>
                    <ListItemButton
                      disableTouchRipple
                      disableFocusRipple
                      disableGutters
                      disablePadding
                      sx={{
                        display: 'flex',
                        flexDirection: minimize ? 'column' : 'row',
                        background: nav === Data.p1.length + i ? '#FFFFFF' : '',
                        width: '100%',
                        borderRadius: '3px',
                        color: nav === Data.p1.length + i ? '#000000' : '',
                        py: 2,
                        px: minimize ? 0 : 3,
                        '&:hover': {
                          background:
                            nav === Data.p1.length + i ? '#FFFFFF' : '#000',
                        },
                      }}
                      onClick={() => {
                        dispatch(setNav(Data.p1.length + i));
                        dispatch(setSubNav(-1));
                        if (smallScreen) {
                          setMinimize(false);
                        }
                      }}>
                      {e.icon}
                      <Typography
                        sx={{
                          pl: minimize ? 0 : '15px',
                          pt: minimize && 1,
                          textWrap: 'nowrap',
                        }}>
                        {e.name}
                      </Typography>
                    </ListItemButton>
                  </Link>
                </ListItem>
              )
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};
