import { Box } from "@mui/material";
import { Chart } from "react-google-charts";
import { ComboTxtBtn } from "../../../../../../components/@extended/Button";
import { DownloadIcn } from "../../../../../../utils/icon/IndividualPages/Icon";
import { useEffect, useRef, useState } from "react";
import { SankeyData, SankeyOptions } from "../../../../../../utils/data/CorporateNormalUserDatas/Data";



export const Sankey = () => {
    const chartContainerRef = useRef(null);
    const [chartWidth, setChartWidth] = useState(400);

    useEffect(() => {
        const updateChartWidth = () => {
            if (chartContainerRef.current) {
                const containerWidth = chartContainerRef.current.offsetWidth - 3;
                setChartWidth(containerWidth); // Set the width to the container's width
            }
        };

        // Initial width calculation
        updateChartWidth();

        // Update width on window resize
        window.addEventListener('resize', updateChartWidth);
        return () => {
            window.removeEventListener('resize', updateChartWidth);
        };
    }, []);
    return (
        <Box sx={{ width: '69%', height: '100%' }}>
            <ComboTxtBtn prop={{ name: 'Seller Distribution Path for Brand', txtStyl: {}, btnIkn: <DownloadIcn />, btnTxt: 'Report', fn: () => { } }} />
            <Box sx={{ border: '1px solid #EAECF0', borderRadius: '8px', p: 2 }}>
                <Chart
                    chartType="Sankey"
                    width={'100%'}
                    // width={chartWidth}
                    height="500px"
                    data={SankeyData}
                    options={SankeyOptions}
                />
            </Box>
        </Box>
    );
}