import { Box, Typography } from "@mui/material";
import { Card_2 } from "../components";
import {
  Anal,
  Compr,
  ES,
  RG,
  RTM,
  UF,
} from "../../../../../utils/icon/IndividualPages/Icon";

export const WhyChoseUs = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        background: "#fff",
        color: "#000",
        px: { xs: 0, md: 5 },
      }}
    >
      <Box sx={{ my: 2 }}>
        <Typography
          sx={{
            textAlign: "center",
            fontWeight: "500",
            fontSize: { xs: "36px", md: "58px" },
          }}
        >
          Why choose us?
        </Typography>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            my: { xs: 4, md: -1 }, 
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: { xs: "", md: "space-around" },
              alignItems: { xs: "center", md: "" },
              width: "100%",
              my: 0, 
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Box
              sx={{ width: { xs: "60%", md: "25%" }, my: { xs: 4, md: "" } }}
            >
              <Card_2
                data={{
                  icon: <Anal />,
                  title: "Advanced Analytics",
                  text: "Leverage cutting-edge analytics and machine learning to uncover hidden patterns and trends.",
                }}
              />
            </Box>
            <Box
              sx={{ width: { xs: "60%", md: "25%" }, my: { xs: 4, md: "" } }}
            >
              <Card_2
                data={{
                  icon: <Compr />,
                  title: "Comprehensive Insights",
                  text: "Transform raw data into actionable insights that drive strategic decision-making.",
                }}
              />
            </Box>
            <Box
              sx={{ width: { xs: "60%", md: "25%" }, my: { xs: 4, md: "" } }}
            >
              <Card_2
                data={{
                  icon: <UF />,
                  title: "User-Friendly Interface",
                  text: "Navigate our platform with ease, regardless of your technical expertise.",
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: { xs: "", md: "space-around" },
              alignItems: { xs: "center", md: "" },
              width: "100%",
              my: 4,
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Box
              sx={{ width: { xs: "60%", md: "25%" }, my: { xs: 4, md: "" } }}
            >
              <Card_2
                data={{
                  icon: <RTM />,
                  title: "Real-Time Monitoring",
                  text: "Stay updated with real-time tracking and monitoring of digital footprints and market movements.",
                }}
              />
            </Box>
            <Box
              sx={{ width: { xs: "60%", md: "25%" }, my: { xs: 4, md: "" } }}
            >
              <Card_2
                data={{
                  icon: <RG />,
                  title: "Reports Generation",
                  text: "Generate detailed, customizable reports tailored to your specific business needs.",
                }}
              />
            </Box>
            <Box
              sx={{ width: { xs: "60%", md: "25%" }, my: { xs: 4, md: "" } }}
            >
              <Card_2
                data={{
                  icon: <ES />,
                  title: "Expert Support",
                  text: "Our dedicated team of professionals is always ready to assist you with personalized support.",
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
