import {
  Alert,
  Box,
  Button,
  FormLabel,
  Pagination,
  Snackbar,
  TablePagination,
  TextField,
  Typography,
} from "@mui/material";
import { TableComp5 } from "../../../../components/table/type - 5";
import { useEffect, useState } from "react";
import EastIcon from "@mui/icons-material/East";
import { downloadCSV } from "../../../../components/Public/DownloadCSV";
import http from "../../../../utils/http-common";
import { useDispatch, useSelector } from "react-redux";
import { RechargeCreditsStyle } from "../../../../utils/data/IndividualDashboard/Data";
import { modifyRechargeCregitTable } from "../../../../components/@extended/CustomFunctions"

export const RechargeCredits = () => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [price, setPrice] = useState("");
  const [credits, setCredits] = useState("");
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");

  const dispatch = useDispatch();
  const credi = useSelector((state) => state.credit.credit);
  const credi2 = useSelector((state) => state);
  console.log("credi2", credi2);
  // const [cred, setCred] = useState(credi.available_credit)

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const Data = [
    {
      recharge_id: 0,
      "Paid by": "Darshan",
      "Mail Id": "abc@cdf.ghi",
      "Transaction No.": 8798465451,
      "Payment Type": "Online",
      "Payment Date": "30 May 2024",
      "Credits Added": 30,
      // Invoice: (
      //     <Button>
      //         <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      //             <path d="M11.9999 16.5L6.99988 11.5L8.39988 10.05L10.9999 12.65V4.5H12.9999V12.65L15.5999 10.05L16.9999 11.5L11.9999 16.5ZM5.99988 20.5C5.44988 20.5 4.97921 20.3043 4.58788 19.913C4.19654 19.5217 4.00054 19.0507 3.99988 18.5V15.5H5.99988V18.5H17.9999V15.5H19.9999V18.5C19.9999 19.05 19.8042 19.521 19.4129 19.913C19.0215 20.305 18.5505 20.5007 17.9999 20.5H5.99988Z" fill="black" />
      //         </svg>
      //     </Button>
      // )
    },
  ];
  const [tbl, setTbl] = useState([]);
  const [tblData, setTblData] = useState(modifyRechargeCregitTable([]));
  // const Tbl = modifyRechargeCregitTable(tblData || []);
  // const [cred, setCred] = useState([0, 0])
  const userId = localStorage.getItem("user_id");
  const Fetch_TableData = async () => {
    try {
      const response = await http.post(
        "/master_service/recharge_history",
        JSON.stringify({ user_id: userId })
      );
      const Data2 = modifyRechargeCregitTable(response.data.data);
      // Get the keys (labels) from the first entry in Data2
      const labels = Data2.length > 0 ? Object.keys(Data2[0]) : [];

      // Create a template object with empty values for each label
      const emptyRow = labels.reduce((acc, label) => {
        acc[label] = "";
        return acc;
      }, {});

      // Push empty rows until Data2 has at least 7 entries
      while (Data2.length < 8) {
        Data2.push({ ...emptyRow });
      }

      setTbl(response.data.data);
      setTblData(Data2);
      await Credit();
      console.log(response.data);

      // setMessage(response.data.message);
      // setSeverity("success");
      // handleClick();
    } catch (error) {
      console.error("Error fetching data:", error);
      setMessage(
        error.response
          ? error.response.data.message
          : "Unable to Fetch the table data."
      );
      setSeverity("error");
      handleClick();
    }
  };
  const MakePayment = async () => {
    try {
      const response = await http.post(
        "/master_service/payment",
        JSON.stringify({
          user_id: userId,
          request_amt: price,
          applicable_credit: credits,
        })
      );

      console.log(response.data);
      await Fetch_TableData();
      setMessage(response.data.message);
      setSeverity("success");
      handleClick();
    } catch (error) {
      console.error("Error fetching data:", error);
      setMessage(
        error.response
          ? error.response.data.message
          : "Unable to Fetch the table data."
      );
      setSeverity("error");
      handleClick();
    }
  };
  const Credit = async () => {
    try {
      const res = await http.post(
        "/master_service/credit",
        JSON.stringify({ user_id: localStorage.getItem("user_id") })
      );
      console.log("resp", res, res.data);
      // setCred(res.data.data.available_credit)
      dispatch(
        updateCredit({
          available_credit: res.data.data.available_credit,
          used_credit: res.data.data.used_credit,
        })
      );
      console.log("credi", credi);
    } catch (error) {
      console.log("credits err", error);
    }
  };

  useEffect(() => {
    Fetch_TableData();
  }, []);

  const totalRows = tblData.length;
  const totalPages = Math.ceil(totalRows / rowsPerPage);
  const startIndex = (page - 1) * rowsPerPage;
  const endIndex = Math.min(startIndex + rowsPerPage, totalRows);

  // Slice data for current page
  const paginatedData = tblData.slice(startIndex, endIndex);
  console.log(
    "startIndex, endIndex, paginatedData, tblData",
    startIndex,
    endIndex,
    paginatedData,
    tblData
  );
  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };
  return (
    <Box sx={{ p: 4, px: { xs: 1, md: 4 }, height: "calc(100vh-64px)" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: "20px",
              pb: 3,
              background:
                "linear-gradient(to right, #8A17D0 50%, #CA3FC4 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Recharge Credits
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "250px",
              my: "5px",
            }}
          >
            <FormLabel
              sx={{ fontWeight: "500", fontSize: "12px", color: "#000000" }}
            >
              Price
              <span style={{ color: "#797F8F" }}>(in dollars)</span>
            </FormLabel>
            <TextField
              placeholder="Enter Price"
              type="number"
              value={price}
              onChange={(e) => {
                const newPrice = e.target.value;
                if (newPrice >= 0) {
                  setPrice(newPrice);
                  setCredits(newPrice * 10);
                }
              }}
              onKeyPress={(e) => {
                if (e.key === "-" || e.key === "+") {
                  e.preventDefault();
                }
              }}
              required
              sx={{
                color: "#AFAFAF",
                fontWeight: "500",
                fontSize: "10px",
              }}
              InputProps={{
                sx: {
                  height: "30px",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#797F8F",
                  },
                },
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: { xs: "100%", md: "250px" },
                my: "5px",
              }}
            >
              <FormLabel
                sx={{ fontWeight: "500", fontSize: "12px", color: "#000000" }}
              >
                Estimated Credits
              </FormLabel>
              <TextField
                type="number"
                value={credits}
                onChange={(e) => {
                  const newCredits = e.target.value;
                  if (newCredits >= 0) {
                    setCredits(newCredits);
                    setPrice(newCredits / 10);
                  }
                }}
                onKeyPress={(e) => {
                  if (e.key === "-" || e.key === "+") {
                    e.preventDefault();
                  }
                }}
                required
                sx={{
                  color: "#AFAFAF",
                }}
                InputProps={{
                  sx: {
                    height: "30px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#797F8F",
                    },
                  },
                }}
              />
            </Box>
            <Button
              sx={{
                height: "15",
                background: "#8A18D0",
                color: "#fff",
                // px: 4,
                ml: 2,
                mt: 3,
                fontSize: "12px",
                fontWeight: "600",
                textTransform: "capitalize",
                "&:hover": { background: "#B37FFC" },
              }}
              onClick={MakePayment}
            >
              Make Payment
            </Button>
          </Box>
        </Box>
        <Box sx={{ display: "flex", mt: { xs: 5, md: 0 } }}>
          <Box sx={{ display: "flex", flexDirection: "column", mx: 1 }}>
            <Typography
              sx={{
                fontWeight: "500",
                fontSize: "12px",
                // pb: 3,
                color: "#000",
              }}
            >
              Credits Used
            </Typography>
            <Box
              sx={{
                height: "66px",
                width: "130px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "#F3E7FA",
              }}
            >
              {credi.used_credit}
              {/* {cred[0]} */}
              {/* 0 */}
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", mx: 1 }}>
            <Typography
              sx={{
                fontWeight: "500",
                fontSize: "12px",
                // pb: 3,
                color: "#000",
              }}
            >
              Credits Left
            </Typography>
            <Box
              sx={{
                height: "66px",
                width: "130px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "#F3E7FA",
              }}
            >
              {credi.available_credit}
              {/* {cred[1]} */}
              {/* 0 */}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: { xs: "column", md: "row" },
          mt: 4,
        }}
      >
        <Typography
          sx={{
            fontWeight: "600",
            fontSize: "16px",
            // pb: 3,
            color: "#000",
            my: { xs: 2, md: 0 },
          }}
        >
          Credits Purchase History
        </Typography>
        <Button
          sx={{
            background: "#8A18D0",
            color: "#fff",
            // px: 4,
            display: "flex",
            textTransform: "capitalize",
            "&:hover": { background: "#B37FFC" },
          }}
          onClick={() => {
            downloadCSV(tbl, "Credits_Purchase_History_All_Data");
            handleClick();
            setSeverity("success");
            setMessage("Downloaded successfully");
          }}
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.99999 10.667L4.66666 7.33366L5.59999 6.36699L7.33332 8.10033V2.66699H8.66666V8.10033L10.4 6.36699L11.3333 7.33366L7.99999 10.667ZM3.99999 13.3337C3.63332 13.3337 3.31955 13.2032 3.05866 12.9423C2.79777 12.6814 2.6671 12.3674 2.66666 12.0003V10.0003H3.99999V12.0003H12V10.0003H13.3333V12.0003C13.3333 12.367 13.2029 12.681 12.942 12.9423C12.6811 13.2037 12.3671 13.3341 12 13.3337H3.99999Z"
              fill="white"
            />
          </svg>
          <Typography sx={{ pl: 2, fontSize: '12px', fontWeight: '600' }}>Download All</Typography>
        </Button>
      </Box>
      <Box
        sx={{
          overflow: "auto",
          borderTopLeftRadius: "15px",
          border: "1px solid #E1E1E1",
          borderTopRightRadius: "15px",
          width: "100%",
          mt: 2,
        }}
      >
        <TableComp5
          Data={paginatedData}
          Style={{ ...RechargeCreditsStyle, Invoice: false }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          mt: 2,
          // border: "1px solid #DADADA",
          marginTop: "0px",
          p: "10px",
          borderBottom: "1px solid #DADADA",
          borderRight: "1px solid #DADADA",
          borderLeft: "1px solid #DADADA",
          borderBottomRightRadius: "15px",
          borderBottomLeftRadius: "15px",
        }}
      >
        <Button
          variant="outlined"
          onClick={handlePreviousPage}
          disabled={page === 1}
          sx={{
            height: "30px",
            color: "#474747",
            display: { xs: "none", sm: "flex" },
            px: 4,
            alignItems: "center",
            borderColor: "#DADADA",
            justifyContent: "space-evenly",
            textTransform: "capitalize",
            "&:hover": { borderColor: "#DADADA" },
            mr: 2,
          }}
        >
          <EastIcon sx={{ transform: "rotate(180deg)" }} />
          Previous
        </Button>

        <Pagination
          count={Math.ceil(tblData.length / rowsPerPage)}
          page={page}
          onChange={handleChangePage}
          //   showFirstButton
          //   showLastButton
          hidePrevButton
          hideNextButton
          shape="rounded"
        />
        <Button
          variant="outlined"
          onClick={() => setPage((x) => x + 1)}
          disabled={page >= totalPages}
          sx={{
            height: "30px",
            color: "#474747",
            display: { xs: "none", sm: "flex" },
            px: 4,
            alignItems: "center",
            borderColor: "#DADADA",
            justifyContent: "space-evenly",
            textTransform: "capitalize",
            "&:hover": { borderColor: "#DADADA" },
            ml: 2,
          }}
        >
          Next
          <EastIcon sx={{ ml: 1 }} />
        </Button>
        <Snackbar
          open={open}
          autoHideDuration={2000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          sx={{ position: "absolute", mt: "38px" }}
        >
          <Alert
            onClose={handleClose}
            severity={severity}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
};
