import {
  Box,
  Button,
  FormLabel,
  TextField,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
// import Textarea from '@mui/joy/Textarea'
import { useState } from "react";
import http from "../../../../utils/http-common";
import { Textarea } from "../../../../components/@extended/Textarea";
import { Model_1 } from "../../../../components/@extended/Model";
import { useLocation } from "react-router-dom";
export const Raise_Ticket = () => {
  const [ticketType, setTicketType] = useState("");
  const [open, setOpen] = useState(false);
  const [description, setDescription] = useState("");
  const [errors, setErrors] = useState({});
  const location = useLocation();

  const Clear = () => {
    setTicketType(""), setDescription("");
  };
  const validateForm = () => {
    let tempErrors = {};
    let isValid = true;

    // Ticket type validation
    if (!ticketType) {
      tempErrors.ticketType = "Ticket Type is required.";
      isValid = false;
    }

    // Description validation
    if (!description) {
      tempErrors.description = "Ticket Details are required.";
      isValid = false;
    }

    setErrors(tempErrors);
    return isValid;
  };
  const handleSubmit = () => {
    if (validateForm()) {
      raiseTicket();
    }
    // } else {
    //   setMessage("Please correct the highlighted errors.");
    //   setSeverity("error");
    //   setOpen(true);
    // }
  };
  const raiseTicket = async () => {
    try {
      const response = await http.post(
        "/master_service/raise/ticket",
        JSON.stringify({
          ticket_type: ticketType,
          ticket_description: description,
          user_id: localStorage.getItem("user_id"),
        })
      );
      console.log("res", response);
      setOpen(true);
    } catch (error) {
      console.error("Error creating contact us entry", error);
    }
  };
  const btn_2_Link =
    location.pathname === "/Admin/Raise-Ticket"
      ? "/Admin"
      : location.pathname === "/Dashboard/Individual-Dashboard/Raise-Ticket"
        ? "/Dashboard/Individual-Dashboard"
        : location.pathname === "/user/Raise-Ticket"
          ? "/user"
          : "";

  const ModelData = {
    title: "Raise Ticket",
    emoji: "",
    txt: [
      "We have received your Request.",
      "Our team will review and mail you with in 48 hours.",
    ],
    cl: "",
    // btn_1: 'Cancel',
    // btn_1_cancel: true,
    // btn_1_Link: '',
    btn_2: "Dashboard",
    btn_2_cancel: "",
    btn_2_Link: btn_2_Link,
    // btn_2_fn: Bundle_Creation
  };

  return (
    <Box
      sx={{
        background: "#fff",
        color: "#797F8F",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        // minHeight: "390px",
        // width: "668px",
        // pt: "116px",
        // margin: "auto",
      }}
    >
      <Model_1 open={open} setOpen={setOpen} data={ModelData} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "90%",
          // width: "668px",
          // mt: "75px",
          // margin: "auto",
          // minHeight: "390px",
        }}
      >
        <Box
          sx={{
            py: 3,
            minHeight: "390px",
            width: { xs: "100%", md: "668px" },
            borderTop: "1px solid #E4E4E4",
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            borderBottom: "1px solid #E4E4E4",
            borderRight: "1px solid #E4E4E4",
            borderLeft: "1px solid #E4E4E4",
            borderTopLeftRadius: "12px",
            borderBottomLeftRadius: "12px",
            px: 4,
            borderTopRightRadius: "12px",
            borderBottomRightRadius: "12px",
            my: "20px",
          }}
        >
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: "20px",
              justifyContent: "center",
              background:
                "linear-gradient(to right, #8A17D0 50%, #CA3FC4 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              pt: "3",
              my: "5px",
            }}
          >
            Raise Ticket
          </Typography>
          <Typography
            sx={{
              fontWeight: "500",
              fontSize: "16px",
              justifyContent: "center",
              color: "#474747",
            }}
          >
            If you have any queries, feel free to raise a ticket
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", gap: "40px" }}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                my: "20px",
              }}
            >
              <FormLabel sx={{ color: "#000000" }}>
                Ticket Type*
              </FormLabel>
              <FormControl sx={{ width: "100%" }}>
                {/* {ticketType === "" ? (
                  <InputLabel
                    id="select-TicketType"
                    sx={{ color: "#47474799", textAlign: "center", my: -1 }}
                  >
                    Select ticket type
                  </InputLabel>
                ) : null} */}
                <Select
                  labelId="select-TicketType"
                  value={ticketType}
                  onChange={(e) => setTicketType(e.target.value)}
                  displayEmpty
                  sx={{
                    width: { xs: "100%", md: "601px" },
                    height: "35px",
                  }}
                  error={!!errors.ticketType}
                >
                  <MenuItem value={""}>
                    Select Ticket Type
                  </MenuItem>
                  <MenuItem value={"Custom configuration"}>
                    Custom configuration
                  </MenuItem>
                  <MenuItem value={"Addition of credits"}>
                    Addition of credits
                  </MenuItem>
                  <MenuItem value={"Others"}>Others</MenuItem>
                </Select>
                {errors.ticketType && (
                  <Typography sx={{ color: "#d32f2f", fontSize: '12px', mt: 1 }}>
                    {errors.ticketType}
                  </Typography>
                )}
              </FormControl>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              my: "5px",
            }}
          >
            <Box
              sx={{
                height: "150px",
                width: { xs: "100%", md: "620px" },
                display: "flex",
                flexDirection: "column",
                my: "5px",
              }}
            >
              <FormLabel sx={{ color: "#000000" }}>Ticket Details*</FormLabel>
              {/* <Textarea placeholder="Enter Ticket Details" value={description} onChange={(e) => setDescription(e.target.value)} variant="outlined"
                                sx={{ width: '601px', height: '100px', borderRadius: '3px', color: '#BCBFC7', my: '10px' }} /> */}
              <Textarea
                Placeholder="Enter Ticket Details"
                Value={description}
                onChange={(e) => setDescription(e.target.value)}
                Style={{
                  width: "100%",
                  height: "100px",
                  borderRadius: "3px",
                  color: "#000",
                  my: "10px",
                }}
                error={!!errors.description}
                helperText={errors.description}
              />
            </Box>
          </Box>
          <Box
            sx={{ display: "flex", justifyContent: "flex-end", gap: "12px" }}
          >
            <Button
              sx={{
                color: "#8A18D0",
                width: "120px",
                height: "32px",
                border: "1px solid #8A18D0",
                textTransform: "capitalize",
              }}
              onClick={Clear}
            >
              Clear
            </Button>
            <Button
              onClick={handleSubmit}
              sx={{
                background: "#8A18D0",
                color: "#ffffff",
                px: 4,
                textWrap: "nowrap",
                textTransform: "capitalize",
                height: "32px",
                "&:hover": { background: "#B37FFC" },
                borderRadius: "3px",
              }}
            >
              Send Ticket
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
