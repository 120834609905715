import { Box } from '@mui/material';
import { MyCredits, SubscriptionDetails, UserTable } from './components';
import { Plans } from '../../../../../utils/data/AdminPage/Data';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export const Landing = () => {
  const plan = Plans[2];
  const seq = localStorage.getItem('seq');
  const sub = useSelector((state) => state.subscribe.subscribe);
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        px: 2,
        py: 2,
      }}>
      <Box sx={{ width: '30%', height: '100%' }}>
        <MyCredits />
        {sub && (
          <>
            <SubscriptionDetails plan={plan} />
          </>
        )}
      </Box>
      <Box sx={{ width: '69%', height: '100%' }}>
        <UserTable />
      </Box>
    </Box>
  );
};
