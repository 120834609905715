import { Box } from "@mui/material"
import { Component } from "react"
import { useEffect, useState } from "react"




export class ErrorBoundary extends Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
    static getDerivedStateFromError(error) {
        return { hasError: true }
    }
    componentDidCatch(error, info) {
        console.log('errr',error, info)
        // logErrorToMyService(error, info)
    }
    render() {
        if(this.state.hasError){
            return 'There is some issue with this Component or Page'
            // return this.props.children
        }
        return this.props.children
    }
}





// export const ErrorBoundary = ({ children }) => {
//     const [hasError, setHasError] = useState(false);

//     const handleError = (error) => {
//         console.error("Error caught in FunctionalErrorBoundary:", error);
//         setHasError(true);
//     };

//     useEffect(() => {
//         // This is a workaround to catch errors in the component tree
//         const errorHandler = (event) => {
//             handleError(event.error);
//         };

//         window.addEventListener('error', errorHandler);
//         return () => {
//             window.removeEventListener('error', errorHandler);
//         };
//     }, []);

//     if (hasError) {
//         return null; // Do not render anything if there's an error
//     }

//     return children; // Render children if no error
// };

