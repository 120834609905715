import { Box, Typography } from "@mui/material";
import { BulletPointLandingPage } from "../../../../../utils/icon/LandingPage/Icon";

const SecondPage = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        mt: 1,
        pb: 6,
        mb: 10,
        px: { xs: 2, md: 0 },
      }}
    >
      <Typography
        variant="h1"
        sx={{
          fontSize: { xs: "32px", md: "40px" },
          fontFamily: "Inter Variable",
          textAlign: "center",
          userSelect: "none",
          py: "60px",
          fontWeight: "700",
        }}
      >
        Why
        <Typography
          component="span"
          sx={{
            background: "linear-gradient(to right, #8A17D0 60%, #CA3FC4 100%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            ml: "10px",
            fontSize: "inherit",
          }}
        >
          Brand Aura?
        </Typography>
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "space-around",
          width: "100%",
          pb: 8,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: { xs: "100%", md: "25%" },
            mb: { xs: 4, md: 0 },
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", pb: 4 }}>
            <BulletPointLandingPage />
            <Typography
              sx={{
                background:
                  "linear-gradient(to right, #8A17D0 60%, #CA3FC4 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                fontWeight: "600",
                fontSize: { xs: "20px", md: "24px" },
              }}
            >
              Comprehensive Data Analytics
            </Typography>
          </Box>

          <Typography
            sx={{
              color: "#fff",
              fontWeight: "500",
              fontSize: { xs: "14px", md: "17px" },
              mb: 2,
              display: "block",
              textAlign: { xs: "justify", md: "justify" },
              hyphens: "none"
            }}
          >
            Brand Aura is a comprehensive data analysis solution designed for
            businesses in the eCommerce Space that offers insight of Digital
            Touch Points, Market Sentiments, Digital Foot Prints, Product
            Positioning
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: { xs: "100%", md: "25%" },
            mb: { xs: 4, md: 0 },
            // px: { xs: 0, md: 1 },
            mx: { xs: 0, md: 3 },
            borderTop: "1px solid #FFFFFF1A",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", pb: 4 }}>
            <BulletPointLandingPage />
            <Typography
              sx={{
                background:
                  "linear-gradient(to right, #8A17D0 60%, #CA3FC4 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                fontWeight: "600",
                fontSize: { xs: "20px", md: "24px" },
              }}
            >
              Strategic Insights
            </Typography>
          </Box>
          <Typography
            sx={{
              color: "#fff",
              fontWeight: "500",
              fontSize: { xs: "14px", md: "17px" },
              textAlign: { xs: "justify", md: "justify" },
              hyphens: "none"
            }}
          >
            With advanced analytics and machine learning capabilities, it
            empowers data-driven decision-making offering strategic insights
            into market trends.
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: { xs: "100%", md: "25%" },
            borderTop: "1px solid #FFFFFF1A",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", pb: 4 }}>
            <BulletPointLandingPage />
            <Typography
              sx={{
                background:
                  "linear-gradient(to right, #8A17D0 60%, #CA3FC4 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                fontWeight: "600",
                fontSize: { xs: "20px", md: "24px" },
              }}
            >
              Navigate E-Commerce Footprint
            </Typography>
          </Box>
          <Typography
            sx={{
              color: "#fff",
              fontWeight: "500",
              fontSize: { xs: "14px", md: "17px" },
              textAlign: { xs: "justify", md: "justify" },
              hyphens: "none"
            }}
          >
            A versatile tool that addresses the challenges posed by dynamic
            eCommerce environment, enhancing brand competitiveness and fostering
            success in the digital marketplace.
          </Typography>
        </Box>
      </Box>
      <Typography
        variant="h1"
        sx={{
          fontSize: { xs: "32px", md: "40px" },
          fontWeight: "700",
          textAlign: "center",
          userSelect: "none",
          py: "10px",
        }}
      >
        Your Brand. Your Data.
      </Typography>
      <Typography
        variant="h1"
        sx={{
          background: "linear-gradient(to right, #8A17D0 60%, #CA3FC4 100%)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          ml: "10px",
          fontWeight: "700",
          fontSize: { xs: "32px", md: "40px" },
          textAlign: "center",
          userSelect: "none",
          mb: 0,
        }}
      >
        Always in Sync.
      </Typography>
    </Box>
  );
};
export default SecondPage;
