import {
  Box,
  Button,
  Fade,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  LogoutSvg,
  SettingsSvg,
  SupportSvg,
  ThemeSvg,
  UserSvg,
  WalletSvg,
} from '../../../utils/icon/IndividualPages/Icon';
import http from '../../../utils/http-common';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { Logo } from '../../../assets/logo';
import { useDispatch, useSelector } from 'react-redux';
import { updateCredit } from '../../../slice/credits';
import { setNav } from '../../../slice/sideNav';
import { setImage } from '../../../slice/ProfilePic';

export const CorporateNormalUsersTopNav = ({ minimize, setMinimize, Data }) => {
  const ut = localStorage.getItem('user_type');
  const dispatch = useDispatch();
  const credi = useSelector((state) => state.credit.credit);
  // const [cred, setCred] = useState(credi.available_credit)
  // const Credit = async () => {
  //   try {
  //     const res = await http.post(
  //       '/master_service/credit',
  //       JSON.stringify({ user_id: localStorage.getItem('user_id') })
  //     );
  //     console.log('resp', res, res.data);
  //     // setCred(res.data.data.available_credit)
  //     dispatch(
  //       updateCredit({
  //         available_credit: res.data.data.available_credit,
  //         used_credit: res.data.data.used_credit,
  //       })
  //     );
  //     console.log('credi', credi);
  //   } catch (error) {
  //     console.log('credits err', error);
  //   }
  // };
  // useEffect(() => {
  //   Credit();
  // }, []);
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        borderBottom: '0.8px solid #797F8F80',
        justifyContent: 'space-between',
        background: '#fff',
        boxShadow: 'none',
        height: '64px',
      }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton
          sx={{ mx: { xs: '3px', md: 2 } }}
          onClick={() => setMinimize(!minimize)}>
          <MenuOpenIcon />
        </IconButton>
        {minimize && (
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <Link
              to={
                '#'
                // ut === "company" ? "/Admin" : "/Dashboard/Individual-Dashboard"
              }
              onClick={() => dispatch(setNav(-1))}>
              <Logo />
            </Link>
          </Box>
        )}
        <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
          <Link
            to={'/Dashboard/Individual-Dashboard'}
            onClick={() => dispatch(setNav(-1))}>
            <Logo />
          </Link>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {Data.dropdown && <Box></Box>}
        {Data.credits && (
          <Box
            sx={{
              p: 1,
              borderRadius: '3px',
              background:
                'linear-gradient(to right, #8A17D0 50%, #CA3FC4 100%)',
              display: { xs: 'none', md: 'flex' },
              alignItems: 'center',
              mx: '5px',
            }}>
            <WalletSvg />
            <Typography
              sx={{
                fontWeight: '600',
                fontSize: '10px',
                color: '#FFFFFF',
                pl: '8px',
              }}>
              Credits Left : {credi.available_credit}
            </Typography>
            {/* <Typography sx={{ fontWeight: '600', fontSize: '10px', color: '#FFFFFF', pl: '8px' }}>Credits Left : {cred}</Typography> */}
          </Box>
        )}
        {Data.nav.map((e, i) => (
          <Link
            key={i}
            to={e.to ? e.to : '#'}
            className='mx-[3px]'
            onClick={() => {
              if (e.nav) {
                dispatch(setNav(e.nav));
              }
            }}>
            <Button disableRipple sx={{minWidth:'max-content'}}>{e.icon}</Button>
          </Link>
        ))}
      </Box>
    </Box>
  );
};

export const Profile = () => {
  const [anchorElUser, setAnchorElUser] = useState(null);
  const profileImage = useSelector((state) => state.profile.ProfilePic);
  // const [profileImage, setProfileImage] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const email = localStorage.getItem('email');
  const userName = localStorage.getItem('user_name');
  const user_id = localStorage.getItem('user_id');

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const settings = [
    {
      title: 'View profile',
      icn: <UserSvg />,
      link:
        localStorage.getItem('user_type') === 'company'
          ? localStorage.getItem('user_role') === 'Admin User'
            ? '/Admin/User_Profile'
            : '/user/User_Profile'
          : '/Dashboard/Individual-Dashboard/User_Profile',
      active: true,
    },
    {
      title: 'Themes',
      icn: <ThemeSvg style={{ color: 'lightgrey' }} />,
      link: '',
      active: false,
    },
    {
      title: 'Support',
      icn: <SupportSvg />,
      // fn: () => navigate('/Dashboard/Individual-Dashboard/Raise-Ticket'),
      link:
        localStorage.getItem('user_type') === 'company'
          ? localStorage.getItem('user_role') === 'Admin User'
            ? '/Admin/Raise-Ticket'
            : '/user/Raise-Ticket'
          : '/Dashboard/Individual-Dashboard/Raise-Ticket',
      active: true,
    },
    {
      title: 'Settings',
      icn: <SettingsSvg />,
      link: '',
      active: false,
    },
    {
      title: 'Log out',
      icn: <LogoutSvg />,
      fn: () => {
        localStorage.clear();
        navigate('/');
      },
      link: '',
      active: true,
    },
  ];
  const fetchImage = async () => {
    try {
      const response = await http.get(`/master_service/get_image/${user_id}`);
      dispatch(setImage(response.data.imageUrl));
    } catch (error) {
      console.log("Fetching img failed", error);
    }
  };

  useEffect(() => {
    fetchImage();
  }, []);


  return (
    <Box sx={{ flexGrow: 0 }}>
      <Tooltip title='Open settings'>
        <IconButton
          onClick={handleOpenUserMenu}
          sx={{ p: 0 }}>
          {profileImage ? (
            <Box
              component="img"
              src={profileImage}
              alt="Profile"
              sx={{
                height: "40px",
                width: "40px",
                borderRadius: "50%",
                objectFit: "cover",
              }}
            />
          ) : (
            <Box
              sx={{
                height: "40px",
                width: "40px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "50%",
                background: "#B37FFC",
                color: "#fff",
                fontSize: "20px",
                fontWeight: "bold",
              }}
            >
              {userName && userName.charAt(0).toUpperCase()}
            </Box>
          )}
        </IconButton>
      </Tooltip>

      <Menu
        sx={{ mt: '45px', minWidth: '200px' }}
        id='menu-appbar'
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
        TransitionComponent={Fade}>
        <Box
          sx={{ display: 'flex', alignItems: 'center', padding: '10px 20px' }}>
          {profileImage ? (
            <Box
              component="img"
              src={profileImage}
              alt="Profile"
              sx={{
                height: "40px",
                width: "40px",
                borderRadius: "50%",
                objectFit: "cover",
              }}
            />
          ) : (
            <Box
              sx={{
                height: "40px",
                width: "40px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "50%",
                background: "#B37FFC",
                color: "#fff",
                fontSize: "20px",
                fontWeight: "bold",
              }}
            >
              {userName && userName.charAt(0).toUpperCase()}
            </Box>
          )}
          <Box sx={{ marginLeft: '10px' }}>
            <Typography
              sx={{ fontWeight: '500', fontSize: '16px', color: '#344054' }}>
              {userName}
            </Typography>
            <Typography
              sx={{ fontWeight: '400', fontSize: '14px', color: '#667085' }}>
              {email}
            </Typography>
          </Box>
        </Box>
        {settings.map((setting, index) => (
          <MenuItem
            key={index}
            sx={{
              display: 'flex',
              alignItems: 'center',
              padding: '10px 20px',
              color: setting.active ? '#344054' : 'lightgrey',
              cursor: setting.active ? 'pointer' : 'default',
              pointerEvents: setting.active ? 'auto' : 'none',
              '& svg': {
                color: setting.active ? '#344054' : 'lightgrey',
              },
            }}
            onClick={() => {
              if (setting.fn) setting.fn();
              if (setting.link) navigate(setting.link);
              handleCloseUserMenu();
            }}>
            {setting.icn}
            <Typography sx={{ pl: 2, fontWeight: '500', fontSize: '14px' }}>
              {setting.title}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
