import { Box, Typography } from "@mui/material"
import TopNav from "../home/private-components/nav/TopNav.jsx";
import Footer from "../home/private-components/screens/Footer.jsx";
import { Element } from "react-scroll";
import { TermsAndConditionData } from "../../../utils/data/LandingPage/Data"
import { TextDescription } from "../../../components/@extended/Textarea";

export const Terms_And_Condition = () => {
    return (

        <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100%", color: "#000", fontFamily: "Inter", }}
        >
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", }}
            >
                <Box sx={{ width: { xs: "85%", md: "85%" }, position: "sticky", top: 0, zIndex: 999, }}
                >
                    <TopNav />
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", }}
                >
                    <Element name="Terms_And_Condition">
                        <TextDescription prop={{ datas: TermsAndConditionData }} />
                    </Element>
                </Box>
            </Box>
            <Box sx={{
                display: "flex", justifyContent: "center", background: "#000000", mt: 4, width: "100%",
            }}>
                <Box sx={{ width: { xs: "100%", md: "80%" } }}>
                    <Element name="about-us">
                        <Footer />
                    </Element>
                </Box>
            </Box>
        </Box>
    );

}
