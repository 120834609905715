import {
  Alert,
  Box,
  Button,
  Paper,
  Rating,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import CountryContext from '../../context/CountryContext';
import { Link } from 'react-router-dom';
import { getParsedRating } from '../type - 2';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { snakeCaseToNormal } from '../../../utils/Utility';
import { LetterIcon, RefIcon } from '../../../utils/icon/AdminPage/Icon';
import { DownloadSvg } from '../../../utils/icon/IndividualPages/Icon';
import http from '../../../utils/http-common';
import { ThreeDot, ThreeDot2 } from '../../@extended/ThreeDots';

export const TableComp5 = ({ Data, Style = {}, btnData = [] }) => {
  const [formData, setFormData] = useState([]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success');
  const requiredFields = [
    'transaction_no',
    'payment_date',
    'payment_type',
  ];

  const Head = Data?.length > 0 ? Object.keys(Data[0]) : [];
  const Row =
    Head?.length > 0
      ? Head.map((x) => ({ title: snakeCaseToNormal(x), field: x }))
      : [];

  const getSx = (style) => (style ? { ...style } : {});

  const handleFieldChange = (index, fieldName, value) => {
    setFormData((prevFormData) => {
      const updatedData = prevFormData.map((entry) =>
        entry.index === index ? { ...entry, [fieldName]: value } : entry
      );

      const entryExists = prevFormData.some((entry) => entry.index === index);
      return entryExists
        ? updatedData
        : [...prevFormData, { index, [fieldName]: value }];
    });
  };

  const handleFileUpload = (index, event) => {
    const file = event.target.files[0];
    setFormData((prevFormData) => {
      const updatedData = prevFormData.map((entry) =>
        entry.index === index ? { ...entry, file } : entry
      );

      const entryExists = prevFormData.some((entry) => entry.index === index);
      return entryExists ? updatedData : [...prevFormData, { index, file }];
    });
  };

  const handleSendCredit = async (index) => {
    const entry = formData.find((entry) => entry.index === index);
    console.log('ent',entry)
    // if (!entry || requiredFields.some((field) => !entry[field])) {
    //   setMessage('Please fill all required fields.');
    //   setSeverity('error');
    //   handleClick();
    //   return;
    // }
    const dataToSend = {
      ...Data[index], // Get the specific data using index
      ...entry, // Override with edited fields from formData
    };
    console.log('Sending data:', dataToSend);

    try {
      const response = await http.post(
        '/master_service/credit_request/update',
        JSON.stringify(dataToSend)
      );
      console.log(response.data);
      setMessage('Data sent successfully.');
      setSeverity('success');
      handleClick();
    } catch (error) {
      console.error('Error sending data:', error);
      setMessage('Error sending data.');
      setSeverity('error');
      handleClick();
    }
  };

  const handleClick = () => setOpen(true);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setOpen(false);
  };

  useEffect(() => {
    // Ensure required fields are added to formData on initial render
    setFormData(
      Data.map((row, i) => {
        const formEntry = {};
        requiredFields.forEach((field) => {
          formEntry[field] = row[field];
        });
        return { index: i, ...formEntry };
      })
    );
  }, [Data]);

  return (
    <TableContainer component={Paper}>
      <Table
        aria-label='brand table'
        sx={{ borderRadius: 'inherit', ...getSx(Style.table) }}>
        <TableHead sx={getSx(Style.head)}>
          <TableRow sx={getSx(Style.headRow)}>
            {Row.map((row, i) => (
              <TableCell
                key={i}
                sx={getSx(Style.headCell)}>
                {row.title}
              </TableCell>
            ))}
            {Style.threeBtns && (
              <>
                <TableCell sx={getSx(Style.headCell)}>Grant Credit</TableCell>
                <TableCell sx={getSx(Style.headCell)}>Ref. Document</TableCell>
                <TableCell sx={getSx(Style.headCell)}></TableCell>
              </>
            )}
            {Style.Invoice && (
              <TableCell sx={getSx(Style.headCell)}>Invoice</TableCell>
            )}
            {Style.isThreeDot && Row[1].field !== null && (
              <TableCell
                sx={{
                  ...(Style.headCell ? { ...Style.headCell } : {}),
                }}></TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody sx={{ overflow: 'auto', ...getSx(Style.body) }}>
          {Data.map((row, i) => (
            <TableRow
              key={i}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                ...(Style.oddEvenColor
                  ? { background: i % 2 !== 0 ? '#FAFAFA' : '#FFFFFF' }
                  : {}),
                ...getSx(Style.row),
              }}>
              {Row.map((col, j) => {
                const isTextField = Style.textFields?.includes(col.field);
                const isRequiredField = requiredFields.includes(col.field);

                const cellContent =
                  isTextField && row[Row[0].field] ? (
                    <TextField
                      value={
                        formData.find((entry) => entry.index === i)?.[
                          col.field
                        ] || row[col.field]
                      }
                      onChange={(e) =>
                        handleFieldChange(i, col.field, e.target.value)
                      }
                      InputProps={{ sx: { height: '20px' } }}
                    />
                  ) : (
                    row[col.field]
                  );

                return (
                  <TableCell
                    key={j}
                    sx={getSx(Style.cell)}>
                    {cellContent}
                  </TableCell>
                );
              })}
              {Style.threeBtns && (
                <>
                  {row[Row[0].field] ? (
                    <>
                      <TableCell sx={getSx(Style.cell)}>
                        <Button
                          disableRipple
                          sx={{
                            ...getSx(Style.sendCredit),
                            ...getSx(Style.sendCredit?.t),
                          }}
                          onClick={() => handleSendCredit(i)}>
                          Send Credit
                        </Button>
                      </TableCell>
                      <TableCell sx={getSx(Style.cell)}>
                        <Button
                          disableRipple
                          component='label'>
                          <RefIcon />
                          <input
                            type='file'
                            hidden
                            onChange={(e) => handleFileUpload(i, e)}
                          />
                        </Button>
                      </TableCell>
                      <TableCell sx={getSx(Style.cell)}>
                        <Button disableRipple>
                          <LetterIcon />
                        </Button>
                      </TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell sx={getSx(Style.cell)}></TableCell>
                      <TableCell sx={getSx(Style.cell)}></TableCell>
                      <TableCell sx={getSx(Style.cell)}></TableCell>
                    </>
                  )}
                </>
              )}
              {Style.Invoice && (
                <TableCell sx={getSx(Style.cell)}>
                  {row[Row[1].field] ? (
                    <Button>
                      <DownloadSvg />
                    </Button>
                  ) : null}
                </TableCell>
              )}
              {Style.isThreeDot && (
                <TableCell
                  sx={{ ...(Style.threeDot ? { ...Style.threeDot } : {}) }}>
                  {row[Row[1].field] ? <ThreeDot2 data={btnData} i={i} /> : null}
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
        <Snackbar
          open={open}
          autoHideDuration={2000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          sx={{ position: 'absolute', mt: '38px' }}>
          <Alert
            onClose={handleClose}
            severity={severity}
            variant='filled'
            sx={{ width: '100%' }}>
            {message}
          </Alert>
        </Snackbar>
      </Table>
    </TableContainer>
  );
};
