import { Box, Button, MenuItem, Select, TextField, Typography } from "@mui/material"
import { BundleOffersIcon, NewProductIcon, NewSellerIcon, OthersIcon, PriceDropIcon, PriceUpIcon } from "../../../../../../utils/icon/CorporateNormalUserDatas/Icon"
import { NotifiCategoryCard, NotifyCard } from "../../../../../../components/@extended/Card"
import { useState } from "react"
import { CategoryD, NotifiD } from "../../../../../../utils/data/CorporateNormalUserDatas/Data"
import { SearchIcon } from "../../../../../../utils/icon/IndividualPages/Icon"
import { CalendarIcon, RefreshIcon } from "../../../../../../utils/icon/CorporateNormalUserDatas/Icon"
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export const Template = () => {
    return (
        <Box>
        </Box>
    )
}

export const NotifiMain = () => {
    const [cat, setCat] = useState(-1)
    const [selectedNotifi, setSelectedNotifi] = useState('All');
    const [searchTerm, setSearchTerm] = useState('');


    const handleRefresh = () => {
        setCat(-1);
        setSearchTerm('');

    };

    const filteredNotifications = NotifiD.filter((notification) => {
        const matchesCategory = cat === -1 || notification.category === cat;
        const matchesSearch = notification.text?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            notification.prodName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            notification.EcommName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            notification.Seller?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            notification.prodPrice?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            notification.time?.toLowerCase().includes(searchTerm.toLowerCase());
        return matchesCategory && matchesSearch;
    });


    return (
        <Box sx={{ border: '0.8px solid #BCBFC7', borderRadius: '8px', display: 'flex', width: '100%' }}>
            <Box sx={{ borderRight: '0.8px solid #BCBFC7', p: 2, minWidth: 'max-content', width: '20%' }}>
                <Typography sx={{ fontSize: '14px', fontWeight: '600', color: '#000' }}>Category</Typography>
                <Category prop={{ cat, setCat }} />
            </Box>
            <Box sx={{ width: '80%', p: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography sx={{ fontSize: '14px', fontWeight: '600', color: '#000' }}>{cat !== -1 ? `${CategoryD[cat].rawText} Notification` : 'All Notification'}</Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', border: '1px solid #BCBFC766', borderRadius: '4px', padding: '0.5rem 1rem', width: '100%', height: '35px', maxWidth: '350px', }}>
                            <SearchIcon
                                sx={{ color: '#757575', marginRight: '0.5rem' }}
                            />
                            <TextField
                                sx={{
                                    flex: 1,
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            border: 'none',
                                            height: '35px',
                                        },
                                        '&:hover fieldset': {
                                            border: 'none',
                                        },
                                        '&.Mui-focused fieldset': {
                                            border: 'none',
                                        },
                                    },
                                }}
                                placeholder='Search ...'
                                variant='outlined'
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </Box>
                        <Button sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', border: '1px solid #BCBFC766', borderRadius: '4px', padding: '0.5rem 1rem', width: '40%', height: '35px', maxWidth: '350px', }}>
                            <Typography sx={{ fontSize: "12px", fontWeight: "500", color: "#797F8F" }}>26 Jun 2024</Typography>
                            <CalendarIcon sx={{ color: '#757575', }} />

                        </Button>
                        <Select
                            value={selectedNotifi}
                            onChange={(e) => setSelectedNotifi(e.target.value)}
                            displayEmpty
                            sx={{ p: 0, height: '36px', width: "150px", }}
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                            <MenuItem value='All'>All</MenuItem>
                            <MenuItem value='Brand'>Brand</MenuItem>
                            <MenuItem value='E-Commerce'>E-Commerce</MenuItem>
                        </Select>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', border: '1px solid #BCBFC766', borderRadius: '4px', padding: '0.5rem 1rem', width: '13%', height: '35px', maxWidth: '350px', cursor: 'pointer', }}
                        onClick={handleRefresh}  >
                        <RefreshIcon />
                        <Typography sx={{ color: "#474747", fontSize: "12px", fontWeight: "500" }}>Refresh</Typography>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
                        <Typography sx={{ color: "#000000", fontSize: "14px", fontWeight: "500" }}>Navigate to</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #BCBFC766', borderRadius: '4px', padding: '0.5rem 1rem', width: '5%', height: '30px', maxWidth: '350px', }}>
                            <Typography>01</Typography>
                        </Box>
                        <Typography sx={{ color: "#000000", fontSize: "14px", fontWeight: "500" }}>Page</Typography>
                        <ArrowBackIosNewIcon />
                        <ArrowForwardIosIcon />
                    </Box>
                </Box>
                <Notification prop={{ cat, notifications: filteredNotifications }} />
            </Box>
        </Box >
    )
}

export const Category = ({ prop }) => {
    const { cat, setCat } = prop;
    return (
        <Box sx={{ width: '100%', p: 2 }}>
            {CategoryD?.map((e, i) => (<NotifiCategoryCard p={{ ...e, cat, setCat, i }} key={i} />))}
        </Box>
    )
}
export const Notification = ({ prop }) => {
    const { notifications } = prop;
    return (
        <Box sx={{ width: '100%', p: 2 }}>
            {notifications.map((e, i) => (
                <NotifyCard p={e} key={i} />
            ))}
        </Box>
    )
}
