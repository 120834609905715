import { Box, IconButton, Typography, useMediaQuery ,Grid ,Card} from "@mui/material";
import { Card2pO } from "../components";
import { workCarouselData } from "../../../../../utils/data/IndividualDashboard/Data";
import Slider from "react-slick";
import { useState } from "react";
import { FirstPrevBtn, LastNexBtn, NexBtn, PrevBtn } from "../../../../../utils/icon/IndividualPages/Icon";


export const AboutWork = ({ autoRotate = true }) => {
    const [slider, setSlider] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: autoRotate,
        autoplaySpeed: 3000,
        // nextArrow: <NexBtn />,
        // prevArrow: <PrevBtn />,
        beforeChange: (oldIndex, newIndex) => setCurrentIndex(newIndex),
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false, // Hide arrows on smaller screens for better UX
                },
            },
        ],
    };

    const handlePrev = () => {
        if (slider) {
            slider.slickPrev();
        }
        if (currentIndex !== 0) {
            setCurrentIndex(prev => prev - 1)
        }
    };

    const handleNext = () => {
        if (slider) {
            slider.slickNext();
        }
        if (currentIndex !== workCarouselData.length) {
            setCurrentIndex(prev => prev + 1)
        }
    };

    const isXs = useMediaQuery((theme) => theme.breakpoints.down('xs'));
    const isSm = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const isMd = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const getVisibleItems = () => {
        if (isSm) return 1;
        if (isXs) return 1;
        return 3;
    };

    const visibleItems = getVisibleItems();
    return (
            <Box sx={{ display: 'flex', justifyContent: 'center', background: '#fff', color: '#000', width: '100%' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: { xs: '100%', md: '90%' }, px: { xs: 2, md: 5 } }}>
                    <Box sx={{ my: { xs: 1, md: 2 }, width: '100%' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Typography sx={{ textAlign: 'center', fontWeight: '500', fontSize: { xs: '23px', sm: '29px', md: '58px' }, mb: { xs: 2, md: 2 } }}>
                                How We Treat Our Work
                            </Typography>
                        </Box>
                        <Grid container spacing={2} sx={{ my: 2, justifyContent: 'center', px: { xs: 0, md: 4 } }}>
                            {workCarouselData.slice().map((e, i) => (
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={4}
                                    key={i}
                                    sx={{
                                        mb: { xs: 2, md: 2 },
                                        p: 0,
                                    }}
                                >
                                    <Card sx={{ height: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', p: 2, boxShadow: 'none' }}>
                                        <Card2pO data={e} />
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </Box>
            </Box>
        );
}

export const AboutWork2 = ({ autoRotate = true }) => {
    const [slider, setSlider] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: autoRotate,
        autoplaySpeed: 3000,
        // nextArrow: <NexBtn />,
        // prevArrow: <PrevBtn />,
        beforeChange: (oldIndex, newIndex) => setCurrentIndex(newIndex),
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false, // Hide arrows on smaller screens for better UX
                },
            },
        ],
    };

    const handlePrev = () => {
        if (slider) {
            slider.slickPrev();
        }
    };

    const handleNext = () => {
        if (slider) {
            slider.slickNext();
        }
    };
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', background: '#fff', color: '#000', px: { xs: 0, md: 5 }, }}>
            <Box sx={{ my: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' , alignItems: 'center'}}>
                    <Typography sx={{ textAlign: 'center', fontWeight: '500', fontSize: '58px' ,flexGrow: 1,pr: 10}}>How We Treat Our Work</Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'center' ,ml: 12}}>
                        <IconButton onClick={handlePrev} disabled={currentIndex === 0}>
                            {currentIndex === 0 ? <FirstPrevBtn /> : <PrevBtn />}
                        </IconButton>
                        <IconButton onClick={handleNext} disabled={currentIndex === workCarouselData.length - 3}>
                            {currentIndex === workCarouselData.length - 3 ? <LastNexBtn /> : <NexBtn />}
                        </IconButton>
                    </Box>
                </Box>
                <Box sx={{ my: 4, display: 'flex' }}>
                    <Slider ref={c => setSlider(c)} {...settings}>
                        {workCarouselData.map((e, i) => (
                            <Box key={i} sx={{ px: 2 }}>
                                <Card2pO data={e} />
                            </Box>
                        ))}
                    </Slider>
                </Box>
            </Box>
        </Box >
    );
}
