import { Box, Button, TextField, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Sty } from '../../../utils/data/LandingPage/Data';
import http from '../../../utils/http-common';
import { useState } from 'react';

export const Test = () => {
  const count = useSelector((state) => state.counter.count);
  const dispatch = useDispatch();
  const [veri, setVeri] = useState('False');
  const id = localStorage.getItem('email')
  const [ids, setIds] = useState(id || 0);

  const handleIncrement = () => {
    dispatch({ type: 'increment' });
  };
  const pay = JSON.stringify({
    email: ids,
    verified: veri
  })

  const handleDecrement = () => {
    dispatch({ type: 'decrement' });
  };
  const Delete = async () => {
    try {
      const res = await http.post(`/auth_service/delete-user`, pay);
      console.log('res', res);
    } catch (error) {
      console.error('err', error);
    }
  };
  const Validate = async () => {
    try {
      const res = await http.post(
        `/auth_service/verfiy`,
        pay
      );
      console.log('res', res);
    } catch (error) {
      console.error('err', error);
    }
  };
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%', justifyContent: 'center' }}>
      <Typography>Count: {count}</Typography>
      <Button onClick={handleIncrement}>+</Button>
      <Button onClick={handleDecrement}>-</Button>
      {/* <div
        className='loader'
        style={Sty.loader}>
      </div> */}
      <Box sx={{ display: 'flex', flexDirection: 'column', maxWidth: '300px' }}>
        <TextField
          value={veri}
          sx={{ my: 2 }}
          onChange={(e) => setVeri(e.target.value)}></TextField>
        <TextField
          value={ids}
          sx={{ my: 2 }}
          onChange={(e) => setIds(e.target.value)}></TextField>
        <Button
          variant='contained'
          onClick={() => Validate()}
          sx={{ textTransform: 'capitalize', my: 2 }}>
          Validate
        </Button>
        <Button
          variant='contained'
          onClick={() => Delete()}
          // disabled={true}
          sx={{ textTransform: 'capitalize', my: 2 }}>
          Delete User
        </Button>
      </Box>
    </Box>
  );
};
