import { createSlice } from '@reduxjs/toolkit';

export const navSlice = createSlice({
    name: 'sideNav',
    initialState: {
        sideNav: { nav: -1, subNav: -1 },
    },
    reducers: {
        setNav: (state, action) => {
            console.log('Redux updateBundle action:', state, action);
            state.sideNav.nav = action.payload;
        },
        setSubNav: (state, action) => {
            console.log('Redux updateBundle action:', state, action);
            state.sideNav.subNav = action.payload;
        },
        clearToken: (state) => {
            state.sideNav = {};
        },
    },
});

// Export the actions
export const { setNav, setSubNav, clearToken } = navSlice.actions;

// Export the reducer
export default navSlice.reducer;
