import { Box, Button, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { BackIcon } from "../../../../../utils/icon/AdminPage/Icon";
import { CorporateNormalUserTable } from "../../../../../components/@extended/ModifiedTable";
import { modifyUserAllProductTable } from "../../../../../components/@extended/CustomFunctions";
import { useState, useEffect } from 'react';
import http from '../../../../../utils/http-common';
import { Back } from "../../../../../components/@extended/Button";

export const All_Products = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(1);
    const [rowsPerPage] = useState(9);
    const [cn, setCn] = useState('');
    const [bnd, setBnd] = useState('');
    const [indBundleId, setIndBundleId] = useState('');
    const [brandId, setBrandId] = useState('');
    const [productTableData, setProductTableData] = useState(JSON.parse(localStorage.getItem('AllProductData')) || []);

    const location = useLocation();

    const getQueryParams = (search) => {
        console.log('ser', search)
        const params = new URLSearchParams(search);

        if (params.get("category_name")) {
            setCn(params.get("category_name"));
        }
        if (params.get("brand_name")) {
            setBnd(params.get("brand_name"));
        }
        if (params.get("ind_bundle_id")) {
            setIndBundleId(params.get("ind_bundle_id"));
        }

        if (params.get("brand_id")) {
            setBrandId(params.get("brand_id"));
        }

    };
    useEffect(() => {
        getQueryParams(location.search);
    }, [location.search]);

    const fetchProducts = async () => {
        try {
            const response = await http.post('master_service/all_product/page', JSON.stringify({
                ind_bundle_id: indBundleId,
                brand_id: brandId,
            }));
            setProductTableData(response?.data?.bundle?.products || []);
            localStorage.setItem('AllProductData', JSON.stringify(response?.data?.bundle?.products || []))
        } catch (error) {
            console.error("Error fetching product data:", error);
        }
    };

    useEffect(() => {
        if (indBundleId !== "" && brandId !== "") {
            fetchProducts();
        }
    }, [indBundleId, brandId]);

    const tbl = modifyUserAllProductTable(productTableData || []);
    const filteredData = tbl.filter(product =>
        product.product_name?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (filteredData.length === 0) {
        filteredData.push({
            product_name: '',
            'No. of Sellers': '',
            'Max Price (₹)': '',
            'Min Price (₹)': '',
            'MRP (₹)': '',
            '': '',
        });
    }

    const props = {
        title: 'Product Table',
        searchTerm,
        setSearchTerm,
        filteredData,
        rowsPerPage,
        page,
        setPage
    };

    return (
        <Box sx={{ p: 2, px: 3 }}>
            <Back prop={{ txt: 'Back' }} />
            <Box sx={{ display: 'flex', alignItems: 'center', pl: 2 }}>
                <Typography sx={{ fontWeight: '600', fontSize: '20px', color: '#000' }}>
                    {cn} - {bnd} Products
                </Typography>
            </Box>

            <CorporateNormalUserTable prop={props} />
        </Box>
    );
};
