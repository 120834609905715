import { Box, Typography } from "@mui/material"
import { Brand_Card } from "../../../../components/card/brand-card";
import Bosch from '../../../../assets/brand_icons/bosch.png'
import Flipkart from '../../../../assets/brand_icons/Flipkart Logo.png'
import Indiamart from '../../../../assets/brand_icons/India Mart Logo.png'
import { Filter } from "../../../../components/filter";
import { DashboardTableData } from "../../../../utils/data/DashboardTableData";
import { AutoCarousel } from "../../../../components/Carousel/AutoCarousel";
import { SellerData } from "../../../../utils/data/SellerData";


export const Dashboard = () => {

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: '64px', width: '100%', p: '25px', overflow: 'auto' }}>
            {/* <CssBaseline /> */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', my: '15px', width: '100%', alignItems: 'center' }}>
                <Typography sx={{ my: '15px', fontSize: '16px', fontWeight: '600' }}>Power Tools Comparison</Typography>
                {/* <Filter /> */}
            </Box>
            <Box sx={{ my: '15px', display: 'flex', flexDirection: 'column', }}>
                {/* <Brand_Card data={DashboardTableData} /> */}
                <Brand_Card data={SellerData} />
                {/* <Brand_Card data={data} />
                <Brand_Card data={data} /> */}
            </Box>
            <Box sx={{ my: '15px' }}>
                <AutoCarousel />
            </Box>
        </Box>
    )
}

