import { Box, Typography } from "@mui/material";

export const Textarea = ({
  Placeholder,
  Value,
  onChange,
  Style,
  error,
  helperText,
}) => {
  return (
    <>
      <textarea
        placeholder={Placeholder}
        value={Value}
        onChange={onChange}
        style={{
          borderRadius: "8px",
          border: `1px solid ${error ? "#d32f2f" : "#ccc"}`,
          padding: "10px",
          fontSize: "16px",
          lineHeight: "1.5",
          color: "#000",
          backgroundColor: "#fff",
          // boxShadow: 'inset 0 2px 4px rgba(0, 0, 0, 0.1)',
          transition: "border-color 0.3s ease, box-shadow 0.3s ease",
          outline: "none",
          // resize: 'none',
          ...(Style ? { ...Style } : {}),
        }}
        onFocus={(e) => {
          // e.target.style.borderColor = '#8A18D0';
          // e.target.style.boxShadow = '0 0 0 2px rgba(138, 24, 208, 0.25)';
        }}
        onBlur={(e) => {
          e.target.style.borderColor = "#ccc";
          e.target.style.boxShadow = "inset 0 2px 4px rgba(0, 0, 0, 0.1)";
        }}
      ></textarea>
      {error && (
        <Typography sx={{ color: "#d32f2f", fontSize: "12px", mt: 1 }}>
          {helperText}
        </Typography>
      )}
    </>
  );
};


export const TextDescription = ({ prop }) => {
  return (
    <Box sx={{ background: "#fff", width: "100%", height: "100%", display: "flex", justifyContent: "flex-start", pl: 15, pt: 3, ...prop?.style?.box1 }}>
      <Box sx={{ display: "flex", flexDirection: "column", width: "90%", ...prop?.style?.box2 }}>
        {prop?.datas?.map((data, i) => (
          <Box key={i} sx={{ mb: 4, ...prop?.style?.box3 }}>
            <Typography sx={{ fontWeight: "600", mb: 1, fontSize: "24px", color: "#000", ...prop?.style?.title }}>
              {data?.title}
            </Typography>
            {data.content.map((sentence, index) => (
              sentence.trim() === "" ? (
                <Typography key={index} sx={{ mb: 1, ...prop?.style?.description }}>
                  <br />
                </Typography>
              ) : (
                <Typography key={index} sx={{ fontSize: "16px", fontWeight: "500", color: "#000", ...prop?.style?.description }}>
                  {sentence}
                </Typography>
              )))}
          </Box>
        ))}
      </Box>
    </Box>
  );
}

// Old Akalya code
export const OldTextDescription = ({ prop }) => {
  return (
    <Box sx={{ background: "#fff", width: "100%", height: "100%", display: "flex", justifyContent: "flex-start", pl: 15, pt: 3, ...prop?.style?.box1 }}>
      <Box sx={{ display: "flex", flexDirection: "column", width: "90%", ...prop?.style?.box2 }}>
        {prop.datas.map((data, i) => (
          <Box key={i} sx={{ mb: 4, ...prop?.style?.box3 }}>
            <Typography sx={{ fontWeight: "600", mb: 1, fontSize: "24px", color: "#000", ...prop?.style?.title }}>
              {data.title}
            </Typography>
            {data.content.split('. ').map((sentence, index) => (
              <Typography key={index} sx={{ fontSize: "16px", fontWeight: "500", color: "#000", mb: 2, ...prop?.style?.description }}>
                {sentence.trim()}{sentence.trim() ? '.' : ''}
              </Typography>
            ))}
          </Box>
        ))}
      </Box>
    </Box>
  );
}

