import { createSlice } from '@reduxjs/toolkit';

export const tokenSlice = createSlice({
    name: 'token',
    initialState: {
        token: localStorage.getItem('token') || '',
    },
    reducers: {
        updateToken: (state, action) => {
            console.log('Redux updateBundle action:', state, action);
            state.token = action.payload;
        },
        clearToken: (state) => {
            state.token = '';
        },
    },
});

// Export the actions
export const { updateToken, clearToken } = tokenSlice.actions;

// Export the reducer
export default tokenSlice.reducer;
