import { Box } from "@mui/material"
import { Back } from "../../../../../components/@extended/Button"
import { NotifiMain } from "./components"



export const Notification = () => {
    return (
        <Box sx={{ p: 2, px: 3 }}>
            <Back prop={{ txt: 'Back', url: '/User' }} />
            <NotifiMain />
        </Box>
    )
}