import { Button } from "@mui/material";
import {
  RefIcon,
  LetterIcon,
  UserSvg,
  CreateSvg,
  ListSvg,
  SubscriptionSvg,
} from "../../icon/AdminPage/Icon.jsx";
import {
  BundleSvg,
  DataSvg,
  PlaySvg,
  QueriesSvg,
  RechareSvg,
} from "../../icon/IndividualPages/Icon.jsx";

export const CreditRequestStyle = {
  table: {
    borderRadius: "8px",
    maxWidth: "100%",
    my: 2,
    height: "100%",
    marginBottom: "0px",
  },
  head: {
    background: "#8A18D01A",
    borderRadius: "8px",
  },
  headRow: {},
  headCell: {
    fontWeight: "600",
    fontSize: "12px",
    color: "#2B2B2B",
  },
  body: {
    overflowY: "auto",
  },
  row: {
    height: "50px",
  },
  cell: {
    fontWeight: "500",
    fontSize: "11px",
    color: "#2B2B2B",
    // textAlign: 'center',
  },
  threeBtns: true,
  textFields: ["payment_type", "transaction_no", "payment_date"],
  sendCredit: {
    borderRadius: "2px",
    px: "12px",
    height: "20px",
    textWrap: "nowrap",
    minWidth: "56px",
    color: "#fff",
    textTransform: "capitalize",
    t: {
      background: "#8A18D0",
      "&:hover": { background: "#8a18d099" },
    },
    f: {
      background: "#727272",
    },
  },
  oddEvenColor: true,
};
export const Data = [
  {
    Request_id: "001",
    "Request Date": "16 Aug 2024",
    "User Id": "User_01",
    "User Name": "Srikanth",
    Amount: "20 dollars",
    Credit: "30",
    "Payment Type": "offline",
    "Transaction Number": "073512003212",
    "Payment Date": "16 aug 2024",
    "Mail Sent": "yes",
    "Payment Status": "Not Paid",
    // "Grand Credit": "Send Credit",
    // "Ref document": <Button disableRipple><RefIcon /></Button>,
    // "": <Button disableRipple><LetterIcon /></Button>,
  },
  {
    Request_id: "001",
    "Request Date": "16 Aug 2024",
    "User Id": "User_01",
    "User Name": "Srikanth",
    Amount: "20 dollars",
    Credit: "30",
    "Payment Type": "offline",
    "Transaction Number": "073512003212",
    "Payment Date": "16 aug 2024",
    "Mail Sent": "yes",
    "Payment Status": "Not Paid",
    // "Grand Credit": "Send Credit",
    // "Ref document": <Button disableRipple><RefIcon /></Button>,
    // "": <Button disableRipple><LetterIcon /></Button>,
  },
];

export const sideNav = {
  p1: [
    {
      icon: <UserSvg />,
      name: "User Management",
      to: "/Admin/Users",
    },
    {
      icon: <CreateSvg />,
      name: "Create Bundle",
      to: "/Admin/Create-Bundle",
    },
    {
      icon: <ListSvg />,
      name: "Bundle List",
      to: "/Admin/Bundle-List",
    },
    {
      icon: <SubscriptionSvg />,
      name: "Subscription",
      to: "/Admin/Subscription",
    },
  ],
  p2: [
    {
      icon: <QueriesSvg />,
      name: "Any Queries?",
      to: "/Admin/Raise-Ticket",
    },
  ],
};

export const shortSideNav = {
  p1: [
    {
      icon: <UserSvg />,
      name: "User",
      to: "/Admin/Users",
    },
    {
      icon: <CreateSvg />,
      name: "Create",
      to: "/Admin/Create-Bundle",
    },
    {
      icon: <ListSvg />,
      name: "List",
      to: "/Admin/Bundle-List",
    },
    {
      icon: <SubscriptionSvg />,
      name: "Subscription",
      to: "/Admin/Subscription",
    },
  ],
  p2: [
    {
      icon: <QueriesSvg />,
      name: "Queries?",
      to: "/Admin/Raise-Ticket",
    },
  ],
};

export const routesMap = [
  // {
  //   map: "/Admin",
  //   nav: false,
  //   no: 0,
  // },
  {
    map: "/Admin/Users",
    nav: true,
    no: 0,
  },
  {
    map: "/Admin/Create-Bundle",
    nav: true,
    no: 1,
  },
  {
    map: "/Admin/Bundle-List",
    nav: true,
    no: 2,
  },
  {
    map: "/Admin/Subscription",
    nav: true,
    no: 3,
  },
  {
    map: "/Admin/Raise-Ticket",
    nav: true,
    no: 4,
  },
];

export const Plans = [
  // {
  //     title: 'One Time User',
  //     text: 'Access product with your own credits!!!',
  //     mainSubText: 'For Individual Users',
  //     subTextData: [
  //         {
  //             title: 'Custom plan',
  //         },
  //         {
  //             title: 'Customer Support',
  //         },
  //         {
  //             title: 'Smooth Onboarding',
  //         },
  //         {
  //             title: 'Best Price',
  //         },
  //     ]
  // },
  {
    title: "Silver",
    price: "$59",
    discount: "-15%",
    subText: "per user billed annually",
    mainSubText: "For Starting Team",
    btn: "Select Plan",
    btn_Link: "/Admin/Subscription",
    subTextData: [
      {
        title: "3 User Licenses",
      },
      {
        title: "Up to 1 Admin",
      },
      {
        title: "200 Credits",
      },
      {
        title: "Generate Reports",
      },
    ],
  },
  {
    title: "Gold",
    price: "$79",
    discount: "-15%",
    subText: "per user billed annually",
    mainSubText: "For Growing Team",
    btn: "Select Plan",
    btn_Link: "/Admin/Subscription",
    subTextData: [
      {
        title: "10 User Licenses",
      },
      {
        title: "Up to 2 Admin",
      },
      {
        title: "400 Credits",
      },
      {
        title: "All Channel Partners ",
      },
    ],
  },
  {
    title: "Platinum",
    black: "true",
    price: "$99",
    currentPlan: true,
    discount: "-15%",
    subText: "per user billed annually",
    mainSubText: "For Scaling Businesses",
    btn: "Select Plan",
    btn_Link: "/Admin/Subscription",
    subTextData: [
      {
        title: "15 User Licenses",
      },
      {
        title: "Up to 3 Admin",
      },
      {
        title: "600 Credits",
      },
      {
        title: "Access Marketing Hub",
      },
    ],
  },
  {
    title: "Custom",
    text: "Why settle for less when you can do more?",
    mainSubText: "For Customization",
    btn: "Contact Support",
    btn_Link: "/Custom_Plan",
    subTextData: [
      {
        title: "Custom plan",
      },
      {
        title: "Customer Support",
      },
      {
        title: "Smooth Onboarding",
      },
      {
        title: "Best Price",
      },
    ],
  },
];

export const DesignDatas = [
  "Category",
  "Country",
  "Platform",
  "Brand",
  "Select Frequency",
  "Preview",
];

export const AdminBundleListData = [
  {
    ind_bundle_id: "204",
    ind_bundle_name: "B-03",
    ind_bundle_cost: "2000000",
    ind_bundle_country: "India",
    ind_bundle_e_commerce: [
      "Amazon IN",
      "Flipkart",
      "Chennai Mobiles",
      "Croma",
      "JioMart",
      "IndiaMart",
      "Bigbasket",
      "Pai",
      "Reliance Digital",
      "LuluHypermarket",
    ],
    ind_bundle_brand: ["Eureka Forbes", "Honeywell", "Mi", "Philips", "Sharp"],
    ind_bundle_category: "Air Purifiers",
    ind_bundle_country_id: "1",
    ind_bundle_e_commerce_id: ["1", "2", "5", "8", "3", "6", "4", "9"],
    ind_bundle_brand_id: ["21", "24", "34", "37", "39"],
    ind_bundle_category_id: "1",
    ind_bundle_config_date: "2024-09-03T00:00:00.000Z",
    ind_bundle_run: true,
  },
  {
    ind_bundle_id: "204",
    ind_bundle_name: "C-03",
    ind_bundle_cost: "2000000",
    ind_bundle_country: "India",
    ind_bundle_e_commerce: [
      "Amazon IN",
      "Flipkart",
      "Chennai Mobiles",
      "Croma",
      "JioMart",
      "IndiaMart",
      "Bigbasket",
      "Pai",
      "Reliance Digital",
      "LuluHypermarket",
    ],
    ind_bundle_brand: ["Eureka Forbes", "Honeywell", "Mi", "Philips", "Sharp"],
    ind_bundle_category: "Air Purifiers",
    ind_bundle_country_id: "1",
    ind_bundle_e_commerce_id: ["1", "2", "5", "8", "3", "6", "4", "9"],
    ind_bundle_brand_id: ["21", "24", "34", "37", "39"],
    ind_bundle_category_id: "1",
    ind_bundle_config_date: "2024-09-03T00:00:00.000Z",
    ind_bundle_run: true,
  },
  {
    ind_bundle_id: "204",
    ind_bundle_name: "B-03",
    ind_bundle_cost: "2000000",
    ind_bundle_country: "India",
    ind_bundle_e_commerce: [
      "Amazon IN",
      "Flipkart",
      "Chennai Mobiles",
      "Croma",
      "JioMart",
      "IndiaMart",
      "Bigbasket",
      "Pai",
      "Reliance Digital",
      "LuluHypermarket",
    ],
    ind_bundle_brand: ["Eureka Forbes", "Honeywell", "Mi", "Philips", "Sharp"],
    ind_bundle_category: "Air Purifiers",
    ind_bundle_country_id: "1",
    ind_bundle_e_commerce_id: ["1", "2", "5", "8", "3", "6", "4", "9"],
    ind_bundle_brand_id: ["21", "24", "34", "37", "39"],
    ind_bundle_category_id: "1",
    ind_bundle_config_date: "2024-09-03T00:00:00.000Z",
    ind_bundle_run: true,
  },
];
