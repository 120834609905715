import {
  Box,
  Button,
  Typography,
  Grid,
  Pagination,
  Snackbar,
  Alert,
  LinearProgress,
  FormLabel,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Plans } from "../../../../../utils/data/AdminPage/Data";
import { BackIcon } from "../../../../../utils/icon/AdminPage/Icon";
import { SimpleCard } from "../../../../../components/card/Plan";
import { TableComp5 } from "../../../../../components/table/type - 5";
import {
  BundleRunLogStyle,
  RechargeCreditsStyle,
} from "../../../../../utils/data/IndividualDashboard/Data";
import EastIcon from "@mui/icons-material/East";
import { I_Icon } from "../../../../../utils/icon/IndividualPages/Icon";
import { downloadCSV } from "../../../../../components/Public/DownloadCSV";
import http from "../../../../../utils/http-common";
import { Pay, Plan } from "./components";
import { useDispatch, useSelector } from "react-redux";
import { updateCredit } from "../../../../../slice/credits";
import ComparePlans from "../../../../public/home/private-components/screens/ComparePlans";
import OurPlans from "../../../../public/home/private-components/screens/OurPlans";

export const Subscription = () => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [cp, setCp] = useState(0);
  const [admins, setAdmins] = useState(0);
  const [users, setUsers] = useState(0);
  const [inactiveUsers, setInactiveUsers] = useState(0);
  const credi = useSelector((state) => state.credit.credit);
  const seq = localStorage.getItem("seq");
  const sub = useSelector((state) => state.subscribe.subscribe);
  console.log("sub", sub);
  const dispatch = useDispatch();
  const userId = localStorage.getItem("user_id");
  const [tblData, setTblData] = useState([
    {
      Paid_by: "",
      Mail_Id: "",
      Transaction_No: "",
      Payment_Type: "",
      Payment_Date: "",
      // Invoice: "",
    },
  ]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("success");

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };
  const card = [
    {
      title: "Admin Access",
      value: admins,
    },
    {
      title: "Active Users",
      value: users,
    },
    {
      title: "Inactive Users",
      value: inactiveUsers,
    },
    {
      title: "Credits Left",
      value: credi.available_credit,
    },
    {
      title: "Credits Used",
      value: credi.used_credit,
    },
  ];
  const tD = () => {
    const labels = tblData.length > 0 ? Object.keys(tblData[0]) : [];

    // Create a template object with empty values for each label
    const emptyRow = labels.reduce((acc, label) => {
      acc[label] = "";
      return acc;
    }, {});
    const Data2 = [];
    // Push empty rows until Data2 has at least 7 entries
    while (Data2.length < 17) {
      Data2.push({ ...emptyRow });
    }
    setTblData(Data2);
  };
  const totalRows = tblData.length;
  const totalPages = Math.ceil(totalRows / rowsPerPage);
  const startIndex = (page - 1) * rowsPerPage;
  const endIndex = Math.min(startIndex + rowsPerPage, totalRows);

  // Slice data for current page
  const paginatedData = tblData.slice(startIndex, endIndex);
  console.log(
    "startIndex, endIndex, paginatedData, tblData",
    startIndex,
    endIndex,
    paginatedData,
    tblData
  );
  const Get = async () => {
    try {
      // const response = await http.get(`/master_service/billing_history/${3}`);
      const response = await http.get(
        `/master_service/billing_history/${userId}`
      );
      console.log(
        "resi",
        response,
        response.data,
        response?.data?.data[0].rechargeDetails,
        response?.data?.userCounts.Deactivate_User
      );
      setTblData(response?.data?.data[0]?.rechargeDetails);
      // await tD();
      dispatch(
        updateCredit({
          available_credit: response?.data?.data[0]?.userCreditDetails.available_credit,
          used_credit: response?.data?.data[0]?.userCreditDetails.used_credit,
        })
      );
      setAdmins(response?.data?.userCounts.Admin_Access || 0);
      setUsers(response?.data?.userCounts.Active_User || 0);
      setInactiveUsers(response?.data?.userCounts?.Deactivate_User || 0);

      // setMessage(response.data.message);
      // setSeverity('success');
      // handleClick();

    }

    catch (error) {
      console.error("Error fetching data:", error);
      setMessage(
        error?.response
          ? error?.response?.data.message
          : "Unable to Fetch the table data."
      );
      setSeverity("error");
      handleClick();
    }
  };
  useEffect(() => {
    Get();
  }, []);

  return (
    <>
      {cp ? (
        <Box sx={{ px: 5 }}>
          <Button
            disableRipple
            sx={{ textTransform: "capitalize", mt: 2, mb: -8 }}
            onClick={() => setCp(false)}
          >
            <BackIcon />
            <Typography
              sx={{
                pl: 1,
                fontWeight: "600",
                fontSize: "20px",
                color: "#000",
              }}
            >
              Change Plan
            </Typography>
          </Button>
          <OurPlans Plans={Plans} need={false} />
          <ComparePlans need={false} />
        </Box>
      ) : (
        <Box sx={{ px: 3, py: 2 }}>
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: "20px",
              pb: 3,
              background:
                "linear-gradient(to right, #8A17D0 50%, #CA3FC4 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            My Subscription
          </Typography>
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: "16px",
              pb: 2,
              color: "#000",
            }}
          >
            Plan Utilization
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: { xs: "center", md: "space-between" },
              width: "100%",
              flexWrap: { xs: "wrap", md: "nowrap" },
              my: 2,
            }}
          >
            {card.map((e, i) => (
              <SimpleCard x={e} key={i} wd={`${100 / card.length - 0.5}%`} />
            ))}
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
            <Box sx={{ width: { xs: "100%", md: "29%" } }}>
              {/* {seq && sub && (
                <> */}
              <Typography
                sx={{
                  fontWeight: "600",
                  fontSize: "16px",
                  pb: 2,
                  mt: 2,
                  color: "#000",
                }}
              >
                Current Plan Details
              </Typography>
              <Plan setCp={setCp} />
              {/* </>
              )} */}
              <Typography
                sx={{
                  fontWeight: "600",
                  fontSize: "16px",
                  py: 2,
                  color: "#000",
                }}
              >
                Want More Credits?
              </Typography>
              <Pay
                setMessage={setMessage}
                setSeverity={setSeverity}
                handleClick={handleClick}
              />
            </Box>
            <Box sx={{ width: { xs: "100%", md: "70%" } }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection: { xs: "column", md: "row" },
                  // mt: 4,
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "600",
                    fontSize: "16px",
                    // pb: 3,
                    color: "#000",
                    my: { xs: 1, md: 0 },
                  }}
                >
                  Billing History
                </Typography>
                <Button
                  sx={{
                    background: "#8A18D0",
                    color: "#fff",
                    // px: 4,
                    width: '120px',
                    display: "flex",
                    textWrap: 'nowrap',
                    textTransform: "capitalize",
                    "&:hover": { background: "#B37FFC" },
                  }}
                  onClick={() => {
                    downloadCSV(tblData, "Purchase_History_All_Data");
                    handleClick();
                    setSeverity("Success");
                  }}
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.99999 10.667L4.66666 7.33366L5.59999 6.36699L7.33332 8.10033V2.66699H8.66666V8.10033L10.4 6.36699L11.3333 7.33366L7.99999 10.667ZM3.99999 13.3337C3.63332 13.3337 3.31955 13.2032 3.05866 12.9423C2.79777 12.6814 2.6671 12.3674 2.66666 12.0003V10.0003H3.99999V12.0003H12V10.0003H13.3333V12.0003C13.3333 12.367 13.2029 12.681 12.942 12.9423C12.6811 13.2037 12.3671 13.3341 12 13.3337H3.99999Z"
                      fill="white"
                    />
                  </svg>
                  <Typography sx={{ pl: 2, fontSize: '12px', fontWeight: '600' }}>Download All</Typography>
                </Button>
              </Box>
              <Box
                sx={{
                  overflow: "auto",
                  borderTopLeftRadius: "15px",
                  border: "1px solid #E1E1E1",
                  borderTopRightRadius: "15px",
                  width: "100%",
                  mt: 2,
                }}
              >
                <TableComp5
                  Data={paginatedData}
                  Style={{ ...BundleRunLogStyle, Invoice: false }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  mt: 2,
                  // border: "1px solid #DADADA",
                  marginTop: "0px",
                  p: "10px",
                  borderBottom: "1px solid #DADADA",
                  borderRight: "1px solid #DADADA",
                  borderLeft: "1px solid #DADADA",
                  borderBottomRightRadius: "15px",
                  borderBottomLeftRadius: "15px",
                }}
              >
                <Button
                  variant="outlined"
                  onClick={handlePreviousPage}
                  disabled={page === 1}
                  sx={{
                    height: "30px",
                    color: "#474747",
                    display: { xs: "none", sm: "flex" },
                    px: 4,
                    alignItems: "center",
                    borderColor: "#DADADA",
                    justifyContent: "space-evenly",
                    textTransform: "capitalize",
                    "&:hover": { borderColor: "#DADADA" },
                    mr: 2,
                  }}
                >
                  <EastIcon sx={{ transform: "rotate(180deg)" }} />
                  Previous
                </Button>

                <Pagination
                  count={Math.ceil(tblData.length / rowsPerPage)}
                  page={page}
                  onChange={handleChangePage}
                  //   showFirstButton
                  //   showLastButton
                  hidePrevButton
                  hideNextButton
                  shape="rounded"
                />
                <Button
                  variant="outlined"
                  onClick={() => setPage((x) => x + 1)}
                  disabled={page >= totalPages}
                  sx={{
                    height: "30px",
                    color: "#474747",
                    display: { xs: "none", sm: "flex" },
                    px: 4,
                    alignItems: "center",
                    borderColor: "#DADADA",
                    justifyContent: "space-evenly",
                    textTransform: "capitalize",
                    "&:hover": { borderColor: "#DADADA" },
                    ml: 2,
                  }}
                >
                  Next
                  <EastIcon sx={{ ml: 1 }} />
                </Button>
                <Snackbar
                  open={open}
                  autoHideDuration={2000}
                  onClose={handleClose}
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  sx={{ position: "absolute", mt: "38px" }}
                >
                  <Alert
                    onClose={handleClose}
                    severity={severity}
                    variant="filled"
                    sx={{ width: "100%" }}
                  >
                    {message}
                  </Alert>
                </Snackbar>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};
