import { Box } from "@mui/material"
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarExport,
} from "@mui/x-data-grid";
import { useState } from "react";
import { snakeCaseToNormal } from "../../../../utils/Utility";
import { TableComp6 } from "../../../../components/table/type - 6";
import { ConfigStyleBar } from "../DataSelection";
import DataGridTemplate from "../../../../layout/datagridtable";

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport />
        </GridToolbarContainer>
    );
}

export const Sample_Data = () => {
    const [bundleData, setBundleData] = useState(JSON.parse(localStorage.getItem('sample_datas')))
    const [selectedData, setSelectedData] = useState([]);
    const data = bundleData.data;
    // setBundleData(bundleData.data)
    // const data = bundleData[0].data;
    // const Head = bundleData && bundleData.length > 0 ? bundleData.map((o) => ({ ...o })) : [];
    // const Row = Head && Head.length > 0
    //     ? Head.map((x) => ({
    //         title: snakeCaseToNormal(x),
    //         field: x,
    //     }))
    //     : [];

    const Head = data && data.length > 0 ? data.map((o) => ({ ...o })) : [];
    const Row =
        Head && Head.length > 0
            ? [
                ...Object.keys(Head[0])
                    .map((x) => ({
                        title: snakeCaseToNormal(x),
                        field: x
                    }))
                    .filter(({ hidden }) => !hidden)
            ]
            : [];
    const data2 = {
        columns: Head,
        rows: Row
    }
    console.log('bd', bundleData, data, Head, Row)
    return (
        <Box sx={{ p: 5 }}>
            {/* <DataGrid
                {...data2}
                rows={Head}
                columns={Row}
                pageSize={5}
                // rowsPerPageOptions={[10, 20, 50, 100]}
                loading={false}
                slots={{
                    toolbar: CustomToolbar,
                }}
            /> */}
            {/* <TableComp6 Data={bundleData.data} Style={ConfigStyleBar} /> */}
            <DataGridTemplate
                columns={Row}
                data={Head}
                createButtonTitle={"Create Configuration"}
                actions={false}
                selectedData={selectedData}
                setSelectedData={setSelectedData}
                printTitle={"Invoice List Data"}
                switchParams={{ key: "status", value: "Active" }}
            />
        </Box>
    );
}

