import TopNav from "../home/private-components/nav/TopNav.jsx";
import Footer from "../home/private-components/screens/Footer.jsx";
import { Box } from "@mui/material";
import { Element } from 'react-scroll';
import Customize from "./First_StaticPage.jsx";
import CategoryGrid from "./Category.jsx";
import Country from "./Country.jsx"
import Brands from "./Brand.jsx";
import Ecommerce from "./Ecommerce.jsx";

export const Static_Data = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        background: "#000000",
        color: "#fff",
        minHeight: "100vh",
        fontFamily: "Inter",
      }}
    >
      <Box
        sx={{
          width: "100%",
          pb: 6,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", md: "85%" },
            position: "sticky",
            top: "0",
            zIndex: "999",
          }}
        >
          <Box
            sx={{ display: { xs: "flex" }, justifyContent: { xs: "center" } }}
          >
            <Box sx={{ width: { xs: "80%", md: "100%" } }}>
              <TopNav />
            </Box>
          </Box>
        </Box>
       <Box sx={{ width: { xs: "100%" ,md: "100%"}}}>
       <Element name="main">
          <Customize />
        </Element>
        <Element name="category">
            <CategoryGrid />
        </Element>
        <Element name="country">
            <Country />
        </Element>
        <Element name="ecommerce">
            <Ecommerce />
        </Element>
        <Element name="brand">
            <Brands />
        </Element>
       </Box>
        <Box sx={{ width: { xs: "100%", md: "80%" } }}>
          <Element name="about-us">
            <Footer />
          </Element>
        </Box>
      </Box>
    </Box>
  )
}