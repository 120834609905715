import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { createBrowserRouter, Navigate, RouterProvider, useNavigate } from 'react-router-dom';
import { Dashboard } from './pages/private/OldDashboard/dashboard/index.jsx';
import { Main } from './pages/private/OldDashboard/main/index.jsx';
import { Brand } from './pages/private/OldDashboard/brand/index.jsx';
import { Sellers } from './pages/private/OldDashboard/seller/index.jsx';
import { DetailedSeller } from './pages/private/OldDashboard/detailed-seller/index.jsx';
import { Product } from './pages/private/OldDashboard/product/index.jsx';
import { Main_2 } from './pages/private/individual/main-2/index.jsx';
import IndividualUserLandingPage from './pages/private/individual/IndividualUserLandingPage/index.jsx';
import { DataSelection } from './pages/private/individual/DataSelection/index.jsx';
import { RechargeCredits } from './pages/private/individual/RechargeCredits/index.jsx';
import { Raise_Ticket } from './pages/private/common/Raise_Ticket/index.jsx';
import { Bundle_Creation } from './pages/private/individual/Bundle_Creation/index.jsx';
import { Bundle_Run_Log } from './pages/private/individual/Bundle_Run_Log/index.jsx';
import { Custom_Bundle } from './pages/private/common/Custom_Bundle/index.jsx';
import http from './utils/http-common.js';
import { Sample_Data } from './pages/private/individual/Sample-Data/index.jsx';
import { Create_Bundle } from './pages/private/individual/Create_Bundle/index.jsx';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './themes/index.js';
import { Main_3 } from './pages/private/AdminTemp/main-3/index.jsx';
import { CreditRequest } from './pages/private/AdminTemp/Credit_Request/index.jsx';
import { Provider as ReduxProvider } from 'react-redux';
import { store } from './store/index.js';
import { Main_Admin } from './pages/private/corporate/admin/admin-main/index.jsx';
import { Subscription } from './pages/private/corporate/admin/Subscription/index.jsx';
import { Landing } from './pages/private/corporate/admin/Landing/index.jsx';
import { Custom_Plan } from './pages/private/corporate/admin/Custom-Plan/index.jsx';
import { CreateBundle } from './pages/private/corporate/admin/Create-Bundle/index.jsx';
import { BundleList } from './pages/private/corporate/admin/Bundle-list/index.jsx';
import { UserManagement } from './pages/private/corporate/admin/User-Management/index.jsx';
// import { RaiseTicket } from "./pages/admin/Raise-Ticket/index.jsx";
import { User_Profile } from './pages/private/common/User_Profile/index.jsx';
import { AdminDataSelection } from './pages/private/corporate/admin/Data-Selection/index.jsx';
// import { CustomBundle } from './pages/admin/Custom-Bundle/index.jsx';
import { Sty } from './utils/data/LandingPage/Data.js';
import { Box } from '@mui/material';
import { Logo } from './assets/logo/index.jsx';
import { AdminBundleCreation } from './pages/private/corporate/admin/Bundle-Creation/index.jsx';
import { CorporateNormalUsersMain } from './pages/private/corporate/user/main/index.jsx';
import { CorporateNormalDashboard } from './pages/private/corporate/user/dashboard/index.jsx';
import { Brands } from './pages/private/corporate/user/brand/index.jsx';
import { Seller } from './pages/private/corporate/user/seller/index.jsx';
import { All_Sellers } from './pages/private/corporate/user/All_Sellers/index.jsx';
import { All_Products } from './pages/private/corporate/user/All_products/index.jsx';
import { ProductPage } from './pages/private/corporate/user/product/index.jsx';
import { MyZone } from './pages/private/corporate/user/My-Zone/index.jsx';
import { Notification } from './pages/private/corporate/user/Notification/index.jsx';
import { Home } from './pages/public/home/index.jsx';
import { About } from './pages/public/about/index.jsx';
import { Contact } from './pages/public/contact/index.jsx';
import { Faq } from './pages/public/Faq/index.jsx';
import { Terms_And_Condition } from './pages/public/Terms_And_Condition/index.jsx';
import { TempPricing } from './pages/public/TempPricing/index.jsx';
import { Forgot_Password, Login, Otp, Registeration } from './pages/public/Auth/index.jsx';
import { Static_Data } from './pages/public/Static_Data/index.jsx';
import { Pricing } from './pages/public/Pricing/index.jsx';
import { Test } from './pages/public/Test/index.jsx';
import { BundleReport } from './pages/private/corporate/user/Reports/index.jsx';
import { ErrorBoundary } from './utils/ErrorBoundary.jsx';
import { Bundle_List } from './pages/private/individual/Bundle-List/index.jsx';

const PrivateRoute = ({ element: Element, ...rest }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        console.log('ent fn');
        const response = await http.post('/auth_service/token');
        if (response.status === 200) {
          console.log('ent 200');
          setIsAuthenticated(true);
        } else if (response.status === 401) {
          localStorage.removeItem('token');
          localStorage.clear();

          // Redirect to login screen
          window.location.href = '/login';
          console.log('ent 401');
          setIsAuthenticated(false);
        } else if (response.status === 403) {
          localStorage.removeItem('token');
          localStorage.clear();

          // Redirect to login screen
          window.location.href = '/login';
          console.log('ent 403');
          setIsAuthenticated(false);
        } else if (response.status === 500) {
          console.log('ent 500');
          setIsAuthenticated(false);
        } else {
          console.log('not ent 200');
          setIsAuthenticated(false);
        }
      } catch (error) {
        setIsAuthenticated(false);
        console.log(error);
      }
    };

    checkAuthentication();
  }, []);

  if (isAuthenticated === null) {
    // While the authentication status is being checked, you might want to show a loading indicator.
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Logo />
          {/* <div className='loader' style={{ ...Sty.loader, marginTop: '15px' }}></div> */}
        </Box>
      </Box>
    );
  }

  return isAuthenticated ? <Element {...rest} /> : <Navigate to='/login' />;
  // return isAuthenticated2 ? <Element {...rest} /> : <Navigate to="/Login" />;
};

export const RedirectRoute = async (l, navigate) => {
  console.log('ent fn ent', l);
  // const [isAuthenticated, setIsAuthenticated] = useState(null);
  // const navigate = useNavigate();
  try {
    console.log('ent fn');
    const response = await http.post('/auth_service/token');
    if (response.status === 200) {
      console.log('ent 200');
      navigate(l);
      // window.location.href = l;
      // setIsAuthenticated(true);
    } else if (response.status === 401) {
      localStorage.removeItem('token');
      localStorage.clear();
      console.log('ent 401');
    } else if (response.status === 403) {
      localStorage.removeItem('token');
      localStorage.clear();

      console.log('ent 403');
    } else if (response.status === 500) {
      console.log('ent 500');
    } else {
      console.log('not ent 200');
    }
  } catch (error) {
    console.log(error);
  }
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/about',
    element: <About />,
  },
  {
    path: '/contact',
    element: <Contact />,
  },
  {
    path: '/faq',
    element: <Faq />,
  },
  {
    path: '/Terms-And-Condition',
    element: <Terms_And_Condition />,
  },
  {
    path: '/temp_pricing',
    element: <TempPricing />,
  },
  {
    path: '/Reg',
    element: <Registeration />,
  },
  {
    path: '/Login',
    element: <Login />,
  },
  {
    path: '/Otp',
    element: <Otp />,
  },
  {
    path: '/Forgot-Password',
    element: <Forgot_Password />,
  },
  {
    path: '/data-info',
    element: <Static_Data />,
  },
  {
    path: '/Pricing',
    element: <Pricing />,
  },
  {
    path: 'test',
    element: <Test />,
  },

  {
    path: '/Admin',
    // element: <Main_Admin />,
    element: <PrivateRoute element={Main_Admin} />,
    children: [
      {
        path: '',
        element: <Landing />,
      },
      {
        path: 'Users',
        element: <UserManagement />,
      },
      {
        path: 'Bundle-List',
        element: <BundleList />,
      },
      {
        path: 'Create-Bundle',
        element: <CreateBundle />,
      },
      {
        path: 'Data-Selection',
        element: <AdminDataSelection />,
      },
      {
        path: 'Bundle-Creation',
        element: <AdminBundleCreation />,
      },
      {
        path: 'Subscription',
        element: <Subscription />,
      },
      {
        path: 'Custom_Plan',
        element: <Custom_Plan />,
      },
      {
        path: 'Raise-Ticket',
        element: <Raise_Ticket />,
      },
      {
        path: 'User_Profile',
        element: <User_Profile />,
      },
      {
        path: 'Custom-Bundle',
        element: <Custom_Bundle />,
      },
      {
        path: 'test',
        element: <Test />,
      },
    ],
  },

  {
    path: '/User',
    // element: <CorporateNormalUsersMain />,
    element: <PrivateRoute element={CorporateNormalUsersMain} />,
    children: [
      {
        path: '',
        element: <CorporateNormalDashboard />,
      },
      {
        path: 'Report',
        element: <BundleReport />,
      },
      {
        path: 'Brands',
        element: <Brands />,
      },
      {
        path: 'Product',
        element: <ProductPage />,
      },
      {
        path: 'Raise-Ticket',
        element: <Raise_Ticket />,
      },
      {
        path: 'User_Profile',
        element: <User_Profile />,
      },
      {
        path: 'seller',
        element: <Seller />,
      },
      {
        path: 'All-Sellers',
        element: <All_Sellers />,
      },
      {
        path: 'All-Products',
        element: <All_Products />,
      },
      {
        path: 'Notification',
        element: <Notification />,
      },
      {
        path: 'My-Zone',
        element: <MyZone />,
      },
      {
        path: 'test',
        element: <Test />,
      },
    ],
  },
  {
    path: '/Dashboard/Individual-Dashboard',
    element: <PrivateRoute element={Main_2} />,
    children: [
      {
        path: '',
        element: <IndividualUserLandingPage />,
      },
      {
        path: 'Bundle-Log',
        element: <Bundle_List />,
      },
      {
        path: 'Create-Bundle',
        element: <Create_Bundle />,
      },
      {
        path: 'User-Wizard',
        element: <DataSelection />,
      },
      {
        path: 'Custom-Bundle',
        element: <Custom_Bundle />,
      },
      {
        path: 'Bundle-Creation',
        element: <Bundle_Creation />,
      },
      {
        path: 'Sample-Data',
        element: <Sample_Data />,
      },
      {
        path: 'Bundle-Run-Log',
        element: <Bundle_Run_Log />,
      },
      {
        path: 'Recharge',
        element: <RechargeCredits />,
      },
      {
        path: 'Raise-Ticket',
        element: <Raise_Ticket />,
      },
      {
        path: 'User_Profile',
        element: <User_Profile />,
      },
      {
        path: 'test',
        element: <Test />,
      },
    ],
  },
  {
    path: '/Admin/Dashboard',
    element: <Main_3 />,
    // element: <PrivateRoute element={Main_3} />,
    children: [
      {
        path: '',
        element: <CreditRequest />,
      },
    ],
  },
  {
    path: '/Dashboard',
    element: <PrivateRoute element={Main} />,
    children: [
      {
        path: '',
        element: <Dashboard />,
      },
      {
        path: 'Brands',
        element: <Brand />,
      },
      {
        path: 'Product',
        element: <Product />,
      },
      {
        path: 'Sellers',
        element: <Sellers />,
      },
      {
        path: 'Detailed-Seller',
        element: <DetailedSeller />,
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to='/' />, // Redirect unknown routes to home
  },
]);
ReactDOM.createRoot(document.getElementById('root')).render(
  // <React.StrictMode>
  <ReduxProvider store={store}>
    <ThemeProvider theme={theme}>
      {/* <ErrorBoundary> */}
        <RouterProvider router={router} />
      {/* </ErrorBoundary> */}
    </ThemeProvider>
  </ReduxProvider>
  // </React.StrictMode>
);

/*
src/pages/

+public
  +All the folders of code that are accessible by every users like Home, Auth, Contact us

+private
  +Individual
  +Corporate
    +Admin
    +Normal Users
    +common
  +Admin
  +common
  +Old Dashboard




*/
