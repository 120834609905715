import { Box } from "@mui/material"
import { ComboTxtBtn } from "../../../../../components/@extended/Button"
import { Navigate, useNavigate } from "react-router-dom"
import { DownloadIcn } from "../../../../../utils/icon/IndividualPages/Icon"
import { PlatformDistribution, ProductListTable, ProductPerBrand, ProductRating, SellerDistribution, SellerListTable, SellerPerBrand } from "./components"
import { Sunburst, Sunbursts } from "./components/Sunburst"
import { Sankey } from "./components/Sankey"
import { ErrorBoundary } from "../../../../../utils/ErrorBoundary"


export const BundleReport = () => {
    return (
        <Box sx={{ p: 2, width: '100%' }}>
            <ComboTxtBtn prop={{ name: 'Power Tools', txtStyl: { fontSize: '20px' }, btnIkn: null, btnTxt: 'Bundle', btnStyl: { width: '80px' }, btnTxtStyl: { fontSize: '12px' }, btnLnk: '/user', fn: () => { } }} />
            <ComboTxtBtn prop={{ name: 'Reports ', txtStyl: {}, btnIkn: null, btnTxt: '', fn: () => { } }} />
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <PlatformDistribution />
                <SellerDistribution />
                <ProductRating />
            </Box>
            <ComboTxtBtn prop={{ name: 'Product List', txtStyl: {}, btnIkn: <DownloadIcn />, btnTxt: 'Report',  btnTxtStyl: { fontSize: '10px' }, fn: () => { } }} />
            <Box>
                <ProductListTable />
            </Box>
            {/* <ComboTxtBtn prop={{ name: 'Products Per Brand ', txtStyl: {}, btnIkn: <DownloadIcn />, btnTxt: 'Report', fn: () => { } }} /> */}
            <ProductPerBrand />
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <ErrorBoundary>
                    <Sunbursts />
                </ErrorBoundary>
                <Sankey />
            </Box>
            <ComboTxtBtn prop={{ name: 'Sellers List', txtStyl: {}, btnIkn: <DownloadIcn />, btnTxt: 'Report', fn: () => { } }} />
            <SellerListTable />
            {/* <ComboTxtBtn prop={{ name: 'Sellers Per Brand', txtStyl: {}, btnIkn: <DownloadIcn />, btnTxt: 'Report', fn: () => { } }} /> */}
            <SellerPerBrand />
            {/* <Sunbursts /> */}
        </Box>
    )
}