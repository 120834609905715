import { Box, Button, Pagination, Rating, TextField, Typography } from '@mui/material';
import { CorporateNormalStyleBar, CorporateNormalStyleBar2, SideNavCorporateNormal } from '../../../../../../utils/data/CorporateNormalUserDatas/Data.js';
import { store } from '../../../../../../store/index.js';
import StarIcon from '@mui/icons-material/Star';
import { propValidatorsDataGrid } from '@mui/x-data-grid/internals';
import { SellerData } from '../../../../../../utils/data/SellerData.jsx';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SearchIcon } from '../../../../../../utils/icon/IndividualPages/Icon.jsx';
import { TableComp6 } from '../../../../../../components/table/type - 6/index.jsx';
import { generateTableData2 } from '../../../../../../components/@extended/TableEssentials.js';
import EastIcon from '@mui/icons-material/East';
import { modifyUserBrandProductTable, modifyUserBrandSellerTable, modifyUserBrandTable } from '../../../../../../components/@extended/CustomFunctions.jsx';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { BackIcon } from '../../../../../../utils/icon/AdminPage/Icon.jsx';
import { CorporateNormalUserTable } from '../../../../../../components/@extended/ModifiedTable.jsx';
import http from '../../../../../../utils/http-common.js'

export const BrandHeader = () => {
    const subnav = useSelector((state) => state.navs.sideNav.subNav);
    const location = useLocation();
    const [subNav, setSubNav] = useState(subnav !== -1 ? subnav : 0);
    const [cn, setCn] = useState('');
    const [cdLen, setCdLen] = useState(0);
    const [indBundleId, setIndBundleId] = useState('');
    const [brand_id, setbrand_id] = useState('');
    const [bnd, setBnd] = useState('');
    // const [subNav, setSubNav] = useState(0)
    // const [subNav, setSubNav] = useState(store.getState().navs.sideNav.subNav !== -1 ? store.getState().navs.sideNav.subNav : 0)
    // const subNavValue = store.getState().navs.sideNav.subNav || 0;
    useEffect(() => {
        if (subnav !== -1) {
            setSubNav(subnav);
        }
    }, [subnav]);
    const getQueryParams = (search) => {
        // console.log('ser', search)
        const params = new URLSearchParams(search);
        if (params.get("category_name")) {
            setCn(params.get("category_name"));
        }
        if (params.get("brand_name")) {
            setBnd(params.get("brand_name"));
        }
        if (params.get("ind_bundle_id")) {
            setIndBundleId(params.get("ind_bundle_id"));
        }

        if (params.get("brand_id")) {
            setbrand_id(params.get("brand_id"));
        }
        if (params.get("cardLength")) {
            setCdLen(params.get("cardLength"));
        }
    };

    useEffect(() => {
        getQueryParams(location.search);
    }, [location]);
    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography sx={{ fontWeight: '600', fontSize: '20px', lineHeight: '28px', color: '#000' }}>
                    {cn} - {bnd} Overview
                </Typography>
                <Typography sx={{ fontWeight: '500', fontSize: '12px', color: '#8A18D0', px: '7px', py: 0, height: '25px', display: 'flex', alignItems: 'center', ml: '5px', background: '#F3E7FA', borderRadius: '16px', }}>
                    {cdLen} Platforms
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Link to={`/user/All-Sellers?category_name=${encodeURIComponent(cn)}&brand_name=${encodeURIComponent(bnd)}&ind_bundle_id=${encodeURIComponent(indBundleId)}&brand_id=${encodeURIComponent(brand_id)}`}>
                    <Button disableTouchRipple disableFocusRipple disableGutters sx={{ textTransform: 'capitalize', background: '#8A18D0', color: '#fff', mr: 1, mb: 2, '&:hover': { background: '#B37FFC' } }}>
                        All Sellers
                    </Button>
                </Link>
                <Link to={`/user/All-products?category_name=${encodeURIComponent(cn)}&brand_name=${encodeURIComponent(bnd)}&ind_bundle_id=${encodeURIComponent(indBundleId)}&brand_id=${encodeURIComponent(brand_id)}`}>
                    <Button disableTouchRipple disableFocusRipple disableGutters sx={{ textTransform: 'capitalize', background: '#8A18D0', color: '#fff', ml: 1, mb: 2, '&:hover': { background: '#B37FFC' }, }}>
                        All Products
                    </Button>
                </Link>
            </Box>
        </Box>
    );
};

export const BrandCards = () => {
    const navValue = store.getState().navs.sideNav.nav || 0;
    let [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();
    const [card, setCard] = useState(0);
    const [cardDatas, setCardDatas] = useState([]);
    const [indBundleId, setIndBundleId] = useState(null);
    const [brand_id, setbrand_id] = useState('');
    const [site_id, setSite_id] = useState('');
    const subnav = useSelector((state) => state.navs.sideNav.subNav);
    // const [subNav, setSubNav] = useState(0)
    const [subNav, setSubNav] = useState(subnav !== -1 ? subnav : 0);
    useEffect(() => {
        if (subnav !== -1) {
            setSubNav(subnav);
        }
    }, [subnav]);
    const getQueryParams = (search) => {
        const params = new URLSearchParams(search);
        if (params.get("site_id")) {
            setSite_id(Number(params.get("site_id")));
        }
        if (params.get("card")) {
            setCard(Number(params.get("card")));
        }

        if (params.get("ind_bundle_id")) {
            setIndBundleId(params.get("ind_bundle_id"));
        }

        if (params.get("brand_id")) {
            setbrand_id(params.get("brand_id"));
        }

    };

    useEffect(() => {
        getQueryParams(location.search);
    }, [location]);


    const fetchDatas = async () => {
        try {
            const response = await http.post('master_service/brand_page/product_card', JSON.stringify({
                ind_bundle_id: indBundleId,
                brand_id: brand_id,
            }))
            console.log("response:", response.data, response.data?.brands[0]?.ecommerce);
            setCardDatas(response?.data?.brands[0]?.ecommerce)
            searchParams.set('cardLength', response?.data?.brands[0]?.ecommerce.length);
            setSearchParams(searchParams);
        }
        catch (error) {
            console.error("Error fetching data:", error)
        }
    }
    const setCol = (i) => {
        setCard(i)
        searchParams.set('card', i);
        searchParams.set('site_id', cardDatas[i].site_id);
        setSearchParams(searchParams);
    }

    useEffect(() => {
        if (indBundleId !== null) {
            fetchDatas();
        }
    }, [indBundleId, brand_id]);
    return (
        <Box sx={{ display: 'flex', gap: 1, justifyContent: 'space-between', overflowX: 'auto', scrollbarWidth: 'none', mb: 2, width: '100%' }}>
            {cardDatas?.map((e, i) => (
                <BrandCard prop={{ ...e, wd: 100 / cardDatas.length - 1, col: card === i, setcol: setCol, i: i, }} />
            ))}
        </Box>
    );
};

export const BrandCard = ({ prop }) => {
    return (
        <Box onClick={() => prop.setcol(prop.i)} sx={{ display: 'flex', flexDirection: 'column', border: '1px solid #EAECF0', p: 2, borderRadius: '5px', background: prop.col && '#F3E7FA', minWidth: 'min-content', maxWidth: '35%', cursor: 'pointer', width: `${prop.wd}%` }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography sx={{ fontWeight: '600', textWrap: 'nowrap', fontSize: '20px', lineHeight: '28px', color: '#000' }}>{prop?.site_name}</Typography>
                {prop.leading && <Typography sx={{ fontWeight: '700', textWrap: 'nowrap', fontSize: '10px', lineHeight: '28px', color: '#fff', background: '#FBBC05', borderRadius: '4px', px: 1, py: 0 }}>Leading platform</Typography>}
            </Box>
            <Box sx={{ display: 'flex', gap: 1, justifyContent: 'space-between' }}>
                <Box sx={{}}>
                    <Typography sx={{ fontWeight: '500', textWrap: 'nowrap', fontSize: '14px', lineHeight: '28px', color: '#000' }}>Sellers : {prop.seller_count}</Typography>
                    <Typography sx={{ fontWeight: '500', textWrap: 'nowrap', fontSize: '14px', lineHeight: '28px', color: '#000' }}>Products : {prop.product_count}</Typography>
                </Box>
                <Box sx={{}}>
                    <Rating name="rating" value={parseFloat(prop?.ratings)} precision={0.5} readOnly emptyIcon={<StarIcon fontSize="small" style={{ color: '#00000033' }} />} icon={<StarIcon fontSize="small" style={{ color: '#F4A403' }} />} />
                    <Typography sx={{ fontWeight: '400', textWrap: 'nowrap', fontSize: '12px', color: '#474747' }}>{prop?.NoOfRatings} reviews</Typography>
                </Box>
            </Box>
        </Box >
    );
};

export const BrandTable = () => {
    const [tab, setTab] = useState(0);
    const location = useLocation();
    const [indBundleId, setIndBundleId] = useState(null);
    const [brand_id, setbrand_id] = useState('');
    const [site_id, setSite_id] = useState('');
    const [card, setCard] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const subnav = useSelector((state) => state.navs.sideNav.subNav);
    let [searchParams, setSearchParams] = useSearchParams();
    const [subNav, setSubNav] = useState(subnav !== -1 ? subnav : 0);
    const [tblDataP, setTblDataP] = useState([]);
    const [tblDataS, setTblDataS] = useState([]);
    // const tblP = tblDataP.length > 0 && modifyUserBrandProductTable({ d: tblDataP[card]?.products });
    const tblP = modifyUserBrandProductTable({ d: tblDataP || [] });
    // const tblS = tblDataS.length > 0 && modifyUserBrandSellerTable({ d: tblDataS[card]?.sellers });
    const tblS = modifyUserBrandSellerTable({ d: tblDataS[card]?.sellers || [] });
    const [page, setPage] = useState(1);
    const [rowsPerPage] = useState(5);
    console.log('tbl', tblP, tblS)

    useEffect(() => {
        if (subnav !== -1) {
            setSubNav(subnav);
        }
    }, [subnav]);
    const getQueryParams = (search) => {
        // console.log('ser', search)
        const params = new URLSearchParams(search);

        if (params.get("ind_bundle_id")) {
            setIndBundleId(params.get("ind_bundle_id"));
        }

        if (params.get("brand_id")) {
            setbrand_id(params.get("brand_id"));
        }
        if (params.get("site_id")) {
            setSite_id(Number(params.get("site_id")));
        }
        if (params.get("card")) {
            setCard(Number(params.get("card")));
        }
        if (params.get("type")) {
            setTab(Number(params.get("type")));
        }
    };

    useEffect(() => {
        getQueryParams(location.search);
    }, [location]);
    // useEffect(() => {
    //     if (tblData.length > 0) {
    //         const modifiedTable = modifyUserBrandTable({ d: tblData[0]?.products || [] });
    //         setTblData(modifiedTable);
    //     }
    // }, [tblData]);
    const fetchDatasP = async () => {
        try {
            const responseP = await http.post('master_service/brand_page/product_list', JSON.stringify({
                ind_bundle_id: indBundleId,
                brand_id: brand_id,
                site_id: site_id,
            }))
            console.log("response tbl products:", responseP.data, responseP?.data.bundle?.brands[0]?.ecommerce, responseP?.data.bundle?.brands[0]?.ecommerce[0]?.products);
            setTblDataP(responseP?.data.bundle?.brands[0]?.ecommerce[0]?.products || [])
            // localStorage.setItem('ProductData', JSON.stringify(responseP?.data.bundle?.brands[0]?.ecommerce || []))
        }
        catch (error) {
            console.error("Error fetching data:", error)
        }
    }
    const fetchDatasS = async () => {
        try {
            const responseS = await http.post('master_service/brand_page/seller_list', JSON.stringify({
                ind_bundle_id: indBundleId,
                brand_id: brand_id,
                site_id: site_id,
            }))
            console.log("response tbl seller:", responseS.data, responseS?.data.bundles[0]?.brands[0]?.ecommerce);
            setTblDataS(responseS?.data.bundles[0]?.brands[0]?.ecommerce || [])
            // localStorage.setItem('SellerData', JSON.stringify(responseS?.data.bundles[0]?.brands[0]?.ecommerce || []))
        }
        catch (error) {
            console.error("Error fetching data:", error)
        }
    }

    useEffect(() => {
        if (indBundleId !== null) {
            if (tab === 0) { setTblDataP([]); fetchDatasP(); } else if (tab === 1) { setTblDataS([]); fetchDatasS(); }
        }
    }, [indBundleId, brand_id, site_id, tab]);
    // console.log('tbl datas2', SellerData[subNav].productTableDatas, generateTableData2(SellerData[subNav].productTableDatas));
    const fltP = tblP.filter((item) => item.produce_name.toLowerCase().includes(searchTerm.toLowerCase()));
    const fltS = tblS.filter((item) => item.seller_name.toLowerCase().includes(searchTerm.toLowerCase()));
    const tbl = tab === 0 ? fltP : fltS
    const filteredData = tbl
    // const filteredData = tbl.filter((item) => item.No_Of_Sellers.includes(searchTerm.toLowerCase()) || item.produce_name.toLowerCase().includes(searchTerm.toLowerCase()));
    if (filteredData.length === 0) {
        filteredData.push({ produce_name: '', No_Of_Sellers: '', 'Max Price (₹)': '', 'Min Price (₹)': '', 'MRP (₹)': '', '': '', });
    }
    const setTabFn = (i) => {
        setTab(i)
        searchParams.set('type', i);
        setSearchParams(searchParams);
    }

    const props = { Btns: [{ name: 'Product List', }, { name: 'Seller List', },], border: true, setTab: setTabFn, tab: tab, title: tab === 0 ? 'Product List' : 'Seller List', searchTerm: searchTerm, setSearchTerm: setSearchTerm, filteredData: filteredData, rowsPerPage, page, setPage }
    // const props = { Btns: [{ name: 'Product List', }, { name: 'Seller List', },], btn1: 'Product List', btn2: 'Seller List', border: true, setTab: setTab, tab: tab, title: 'Product List', searchTerm: searchTerm, setSearchTerm: setSearchTerm, filteredData: filteredData, rowsPerPage, page, setPage }
    console.log('filteredData', filteredData, tbl, fltP, tblP, tblDataP);
    // console.log('startIndex, endIndex, paginatedData, filteredData', startIndex, endIndex, paginatedData, filteredData);
    return (
        <CorporateNormalUserTable prop={props} />
    );
};
