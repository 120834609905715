import {
  Alert,
  Box,
  Button,
  FormLabel,
  MenuItem,
  Pagination,
  Select,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import { SingleUserSvg } from '../../../../../utils/icon/AdminPage/Icon';
import { useEffect, useState } from 'react';
import { SearchIcon } from '../../../../../utils/icon/IndividualPages/Icon';
import { BundleRunLogStyle } from '../../../../../utils/data/IndividualDashboard/Data';
import { TableComp5 } from '../../../../../components/table/type - 5';
import http from '../../../../../utils/http-common';
import EastIcon from '@mui/icons-material/East';
import { TableComp6 } from '../../../../../components/table/type - 6';
import { updateCredit } from '../../../../../slice/credits';
import { useDispatch } from 'react-redux';
import { modifyAdminLandingTable } from '../../../../../components/@extended/CustomFunctions';

export const UserManagement = () => {
  const [name, setName] = useState('');
  const [activeUsers, setActiveUsers] = useState('');
  const [mail, setMail] = useState('');
  const [role, setRole] = useState('');
  const [userId, setUserId] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(9);
  const userIdOg = localStorage.getItem('user_id');
  const adminId = localStorage.getItem('admin_id');
  const dispatch = useDispatch();
  const [tblData, setTblData] = useState([
    {
      Name: '',
      User_Role: '',
      Joined_On: '',
      Initial_Status: '',
      User_Status: '',
      // Invoice: "",
    },
  ]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success');

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };
  // const handlePreviousPage = () => {
  //   if (page > 1) {
  //     setPage(page - 1);
  //   }
  // };

  const Data2 = tblData;
  // console.log('da1', Data2);
  const tD = async () => {
    const labels = tblData.length > 0 ? Object.keys(tblData[0]) : [];
    // Create a template object with empty values for each label
    const emptyRow = labels.reduce((acc, label) => {
      acc[label] = '';
      return acc;
    }, {});
    // Push empty rows until Data2 has at least 7 entries
    // console.log('da', Data2);
    while (Data2.length < 7) {
      Data2.push({ ...emptyRow });
    }
    setTblData([...tblData, Data2]);
  };

  useEffect(() => {
    Get();
    if (tblData) {
      tD();
    }
    const id = JSON.parse(localStorage.getItem('id'));
    if (id) {
      setUserId(id.user_id);
      const pg = id;
      console.log('pg', pg, id);
      setName(pg.user_first_name);
      setMail(pg.user_email && pg.user_email);
      setRole(pg.user_role && pg.user_role);
      localStorage.removeItem('id');
    }
  }, []);
  // Slice data for current page
  // const filterDatas = tblData.filter((data) => {
  //   const userId = data.user_id ? String(data.user_id).toLowerCase() : ''; // Convert user_id to a string
  //   const userFirstName = data.user_first_name
  //     ? data.user_first_name.toLowerCase()
  //     : '';
  //   const userEmail = data.user_email ? data.user_email.toLowerCase() : '';
  //   const user_role = data.user_role ? data.user_role.toLowerCase() : '';
  //   const user_status = data.user_status ? data.user_status.toLowerCase() : '';
  //   const invite_status = data.invite_status
  //     ? data.invite_status.toLowerCase()
  //     : '';
  //   const joined_on = data.joined_on ? data.joined_on.toLowerCase() : '';

  //   const searchLower = searchTerm.toLowerCase();

  //   return (
  //     userId.includes(searchLower) ||
  //     userEmail.includes(searchLower) ||
  //     userFirstName.includes(searchLower) ||
  //     user_role.includes(searchLower) ||
  //     user_status.includes(searchLower) ||
  //     invite_status.includes(searchLower) ||
  //     joined_on.includes(searchLower)
  //   );
  // });
  const filterDatas = Array.isArray(tblData) ? tblData.filter((data) => {
    const userId = data.user_id ? String(data.user_id).toLowerCase() : ''; // Convert user_id to a string
    const userFirstName = data.user_first_name ? data.user_first_name.toLowerCase() : '';
    const userEmail = data.user_email ? data.user_email.toLowerCase() : '';
    const user_role = data.user_role ? data.user_role.toLowerCase() : '';

    const searchLower = searchTerm.toLowerCase();

    return (
      userId.includes(searchLower) ||
      userEmail.includes(searchLower) ||
      userFirstName.includes(searchLower) ||
      user_role.includes(searchLower)
    );
  }) : [];

  // console.log('filt', filterDatas);

  const totalRows = filterDatas.length;
  const totalPages = Math.ceil(totalRows / rowsPerPage);
  const startIndex = (page - 1) * rowsPerPage;
  const endIndex = Math.min(startIndex + rowsPerPage, totalRows);
  // const paginatedData = filterDatas.slice(startIndex, endIndex);
  const fltData = filterDatas;
  // const fltData = filterDatas?.slice(startIndex, endIndex);
  const paginatedData = fltData?.map((obj) => {
    const { user_id, user_first_name, user_email, user_status_og, invite_status_og, ...rest } = obj;
    return rest;
  });
  const makeItMt = () => {
    setName('');
    setMail('');
    setRole('');
    setUserId('');
  };
  const btns = [
    {
      title: ({ i }) => {
        return 'Edit';
      },
      fn: ({ index, i }) => {
        console.log(i);
        console.log(fltData[i]);
        setName(fltData[i].user_first_name);
        setMail(fltData[i].user_email);
        setRole(fltData[i].user_role);
        setUserId(fltData[i].user_id);
        // Edit();
        console.log('usid', userId);
      },
      cb: true,
      link: '',
    },
    {
      title: ({ i }) => {
        return 'Resend Mail';
      },
      fn: ({ index, i }) => {
        Resend({ uid: fltData[i].user_id });
      },
      cb: true,
      link: '',
    },
    {
      title: ({ i }) => {
        return 'Delete User';
      },
      fn: ({ index, i }) => {
        Delete({ uid: fltData[i].user_id });
      },
      cb: true,
      link: '',
    },
    {
      title: ({ i }) => {
        return fltData[i].user_status_og === 'Deactivate'
          ? 'Active'
          : 'Deactivate';
      },
      fn: ({ index, i }) => {
        console.log('dec', fltData[i].user_status === 'Deactivate', paginatedData[i].user_status)
        Deactivate({
          uid: fltData[i].user_id,
          status:
            fltData[i].user_status_og === 'Deactivate'
              ? 'Active'
              : 'Deactivate',
        });
      },
      cb: true,
      link: '',
    },
  ];
  const Get = async () => {
    try {
      const response = await http.get(`/master_service/user_list/${userIdOg}`);
      console.log('resi', response.data);
      setTblData(modifyAdminLandingTable({ d: response.data.data.users }));
      setActiveUsers(response.data.data.subscription_details.active_user);
      dispatch(
        updateCredit({
          available_credit: response.data.data.creditDetails.available_credit,
          used_credit: response.data.data.creditDetails.used_credit,
        })
      );
      // await tD();

      // setMessage(response.data.message);
      // setSeverity('success');
      // handleClick();
    } catch (error) {
      console.error('Error fetching data:', error);
      setMessage(
        error.response
          ? error.response.data.message
          : 'Unable to Fetch the table data.'
      );
      setSeverity('error');
      handleClick();
    }
  };

  const Add = async () => {
    try {
      const response = await http.post(
        '/master_service/manage_user',
        JSON.stringify({
          admin_id: adminId,
          user_first_name: name,
          user_email: mail,
          user_role: role,
        })
      );
      console.log(response.data);
      makeItMt();
      await Get();

      setMessage(response.data.message);
      setSeverity('success');
      handleClick();
    } catch (error) {
      console.error('Error fetching data:', error);
      setMessage(
        error.response
          ? error.response.data.message
          : 'Unable to Fetch the table data.'
      );
      setSeverity('error');
      handleClick();
    }
  };

  const Edit = async () => {
    try {
      const response = await http.post(
        '/master_service/edit_new_user',
        JSON.stringify({
          admin_id: adminId,
          user_id: userId,
          user_first_name: name,
          user_email: mail,
          user_role: role,
        })
      );
      console.log(response.data);
      makeItMt();
      await Get();

      setMessage(response.data.message);
      setSeverity('success');
      handleClick();
    } catch (error) {
      console.error('Error fetching data:', error);
      setMessage(
        error.response
          ? error.response.data.message
          : 'Unable to Fetch the table data.'
      );
      setSeverity('error');
      handleClick();
    }
  };

  const Resend = async ({ uid }) => {
    try {
      const response = await http.post(
        `/master_service/user_resend_mail/${uid}`
      );
      console.log(response.data);

      setMessage(response.data.message);
      setSeverity('success');
      handleClick();
    } catch (error) {
      console.error('Error fetching data:', error);
      setMessage(
        error.response
          ? error.response.data.message
          : 'Unable to Fetch the table data.'
      );
      setSeverity('error');
      handleClick();
    }
  };

  const Delete = async ({ uid }) => {
    try {
      const response = await http.delete(`/master_service/delete/${uid}`);
      console.log(response.data);
      await Get();

      setMessage(response.data.message);
      setSeverity('success');
      handleClick();
    } catch (error) {
      console.error('Error fetching data:', error);
      setMessage(
        error.response
          ? error.response.data.message
          : 'Unable to Fetch the table data.'
      );
      setSeverity('error');
      handleClick();
    }
  };

  const Deactivate = async ({ uid, status }) => {
    try {
      const response = await http.post(
        '/master_service/update_user_status',
        JSON.stringify({
          user_id: uid,
          admin_id: adminId,
          user_status: status,
        })
      );
      console.log(response.data);
      await Get();

      setMessage(response.data.message);
      setSeverity('success');
      handleClick();
    } catch (error) {
      console.error('Error fetching data:', error);
      setMessage(
        error.response
          ? error.response.data.message
          : 'Unable to Fetch the table data.'
      );
      setSeverity('error');
      handleClick();
    }
  };
  return (
    <Box sx={{ px: 3, py: 2 }}>
      <Typography
        sx={{
          fontWeight: '600',
          fontSize: '20px',
          pb: 3,
          background: 'linear-gradient(to right, #8A17D0 50%, #CA3FC4 100%)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        }}>
        User Management
      </Typography>
      <Box>
        <Button
          //   key={i}
          //   onClick={() => setTab(i)}
          sx={{
            background: '#8A18D0',
            color: '#fff',
            px: '18px',
            py: '15px',
            borderTopLeftRadius: '16px',
            borderTopRightRadius: '16px',
            mx: { xs: '5px', md: 0 },
            my: { xs: 1, md: 0 },
            textTransform: 'capitalize',
            borderBottomLeftRadius: { xs: '16px', md: 0 },
            borderBottomRightRadius: { xs: '16px', md: 0 },
            '&:hover': { background: '#B37FFC' },
          }}>
          {SingleUserSvg()}
          <Typography sx={{ pl: '6px', fontWeight: '500', fontSize: '14px' }}>
            {'User List'}
          </Typography>
        </Button>
        <Box
          sx={{
            border: '0.8px solid #797F8F80',
            borderRadius: '8px',
            borderTopLeftRadius: '0px',
            height: '100%',
            width: '100%',
            px: 2,
            py: 2,
            display: 'flex',
            flexDirection: 'column',
            // justifyContent: 'center',
          }}>
          <Typography
            sx={{ fontWeight: '600', fontSize: '16px', pb: 1, color: '#000' }}>
            User Configuration
          </Typography>
          <Typography
            sx={{
              fontWeight: '400',
              fontSize: '12px',
              pb: 2,
              color: '#797F8F',
            }}>
            Note: you can only have {activeUsers} active users as per your
            subscription
          </Typography>
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ mr: 3 }}>
              <FormLabel>Name</FormLabel>
              <TextField
                placeholder='Enter User name'
                value={name}
                onChange={(e) => setName(e.target.value)}
                type='text'
                size='small'
                required
                sx={{
                  color: '#000000',
                  height: '32px',
                  width: { xs: '100%', md: '220px' },
                  borderRadius: '3px',
                  border: '0.8px',
                  my: '5px',
                  display: 'flex',
                }}></TextField>
            </Box>
            <Box sx={{ mr: 3 }}>
              <FormLabel>User Mail ID</FormLabel>
              <TextField
                placeholder='Enter User Mail ID'
                value={mail}
                onChange={(e) => setMail(e.target.value)}
                type='text'
                size='small'
                required
                sx={{
                  color: '#000000',
                  height: '32px',
                  width: { xs: '100%', md: '220px' },
                  borderRadius: '3px',
                  border: '0.8px',
                  my: '5px',
                  display: 'flex',
                }}></TextField>
            </Box>
            <Box sx={{ mr: 3, display: 'flex', flexDirection: 'column' }}>
              <FormLabel sx={{ mb: 1 }}>User Role</FormLabel>
              <Select
                value={role}
                onChange={(e) => setRole(e.target.value)}
                displayEmpty
                sx={{
                  p: 0,
                  height: '36px',
                  width: { xs: '100%', md: '220px' },
                }}
                inputProps={{ 'aria-label': 'Without label' }}>
                <MenuItem value=''>Enter or Select User Role</MenuItem>
                <MenuItem value={'Admin User'}>Admin User</MenuItem>
                <MenuItem value={'Normal User'}>Normal User</MenuItem>
                <MenuItem value={'Marketing Manager'}>
                  Marketing Manager
                </MenuItem>
              </Select>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'end' }}>
              <Button
                onClick={() => (userId ? Edit() : Add())}
                sx={{
                  background: '#8A18D0',
                  minWidth: 'max-content',
                  textTransform: 'capitalize',
                  color: '#fff',
                  height: '40px',
                  '&:hover': { background: '#B37FFC' },
                }}>
                {userId ? 'Update' : 'Add'} User
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mt: 4,
            }}>
            <Typography
              sx={{
                fontWeight: '600',
                fontSize: '16px',
                pb: 1,
                color: '#000',
              }}>
              User List
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                border: '1px solid #797F8F',
                borderRadius: '4px',
                padding: '0.5rem 1rem',
                // backgroundColor: '#f9f9f9',
                width: '100%',
                height: '35px',
                maxWidth: '350px',
              }}>
              <SearchIcon sx={{ color: '#757575', marginRight: '0.5rem' }} />
              <TextField
                sx={{
                  flex: 1,
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                      height: '35px',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                }}
                placeholder='Search...'
                variant='outlined'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </Box>
          </Box>
          <Box
            sx={{
              overflow: 'auto',
              borderTopLeftRadius: '15px',
              border: '1px solid #E1E1E1',
              borderTopRightRadius: '15px',
              borderBottomRightRadius: '15px',
              borderBottomLeftRadius: '15px',
              width: '100%',
              mt: 2,
            }}>
            <TableComp6
              Data={paginatedData}
              Style={{
                ...BundleRunLogStyle,
                Invoice: false,
                isThreeDot2: true,
                threeDot2: { textAlign: 'end' },
              }}
              btnData={btns}
            />
          </Box>
          {/* <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              mt: 2,
              // border: "1px solid #DADADA",
              marginTop: "0px",
              p: "10px",
              borderBottom: "1px solid #DADADA",
              borderRight: "1px solid #DADADA",
              borderLeft: "1px solid #DADADA",
              borderBottomRightRadius: "15px",
              borderBottomLeftRadius: "15px",
            }}
          >
            <Button
              variant="outlined"
              onClick={handlePreviousPage}
              disabled={page === 1}
              sx={{
                height: "30px",
                color: "#474747",
                display: { xs: "none", sm: "flex" },
                px: 4,
                alignItems: "center",
                borderColor: "#DADADA",
                justifyContent: "space-evenly",
                textTransform: "capitalize",
                "&:hover": { borderColor: "#DADADA" },
                mr: 2,
              }}
            >
              <EastIcon sx={{ transform: "rotate(180deg)" }} />
              Previous
            </Button>

            <Pagination
              count={Math.ceil(tblData.length / rowsPerPage)}
              page={page}
              onChange={handleChangePage}
              //   showFirstButton
              //   showLastButton
              hidePrevButton
              hideNextButton
              shape="rounded"
            />
            <Button
              variant="outlined"
              onClick={() => setPage((x) => x + 1)}
              disabled={page >= totalPages}
              sx={{
                height: "30px",
                color: "#474747",
                display: { xs: "none", sm: "flex" },
                px: 4,
                alignItems: "center",
                borderColor: "#DADADA",
                justifyContent: "space-evenly",
                textTransform: "capitalize",
                "&:hover": { borderColor: "#DADADA" },
                ml: 2,
              }}
            >
              Next
              <EastIcon sx={{ ml: 1 }} />
            </Button>
           
          </Box> */}
          <Snackbar
            open={open}
            autoHideDuration={2000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            sx={{ position: 'absolute', mt: '38px' }}>
            <Alert
              onClose={handleClose}
              severity={severity}
              variant='filled'
              sx={{ width: '100%' }}>
              {message}
            </Alert>
          </Snackbar>
        </Box>
      </Box>
    </Box>
  );
};
