import { Box, List, ListItem, Typography } from "@mui/material";
import { I_Icon } from "../../../../utils/icon/LandingPage/Icon";


export
    const PasswordModel = () => {
        return (
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "22vh",
                    width: "30vh",
                    border: "1px solid #EAECF0",
                    background: "#FFF6E5",
                    borderRadius: "4px",
                    p: "10px",
                }}
            >
                <Box sx={{ display: "flex", alignItems: "center", mb: "8px" }}>
                    <Box sx={{ display: "flex", alignItems: "center", mr: "8px" }}>
                        <I_Icon />
                    </Box>
                    <Typography>Note</Typography>
                </Box>
                <Box>
                    <Typography
                        sx={{
                            color: "#808080",
                            fontSize: "12px",
                            mb: "2px",
                            ml: "20px",
                        }}
                    >
                        Password must contain:
                    </Typography>
                    <List
                        sx={{
                            padding: 0,
                            margin: 0,
                            listStyleType: "disc",
                            pl: "32px",
                            ml: "10px",
                        }}
                    >
                        <ListItem
                            sx={{
                                padding: 0,
                                fontSize: "12px",
                                color: "#808080",
                                display: "list-item",
                            }}
                        >
                            At least 8 characters long.
                        </ListItem>
                        <ListItem
                            sx={{
                                padding: 0,
                                fontSize: "12px",
                                color: "#808080",
                                display: "list-item",
                            }}
                        >
                            One uppercase letter.
                        </ListItem>
                        <ListItem
                            sx={{
                                padding: 0,
                                fontSize: "12px",
                                color: "#808080",
                                display: "list-item",
                            }}
                        >
                            One lowercase letter.
                        </ListItem>
                        <ListItem
                            sx={{
                                padding: 0,
                                fontSize: "12px",
                                color: "#808080",
                                display: "list-item",
                            }}
                        >
                            One digit.
                        </ListItem>
                        <ListItem
                            sx={{
                                padding: 0,
                                fontSize: "12px",
                                color: "#808080",
                                display: "list-item",
                            }}
                        >
                            One special character.
                        </ListItem>
                    </List>
                </Box>
            </Box>
        );
    };
