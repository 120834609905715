import {
  Alert,
  Box,
  Button,
  Pagination,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import { SimpleCard } from '../../../../../../components/card/Plan';
import {
  CheckIcon,
  CheckIcon2,
  renderIcon,
} from '../../../../../../utils/icon/LandingPage/Icon';
import {
  I_Icon,
  SearchIcon,
} from '../../../../../../utils/icon/IndividualPages/Icon';
import EastIcon from '@mui/icons-material/East';
import { UpArrow } from '../../../../../../utils/icon/AdminPage/Icon';
import { TableComp5 } from '../../../../../../components/table/type - 5';
import { BundleRunLogStyle } from '../../../../../../utils/data/IndividualDashboard/Data';
import { useEffect, useState } from 'react';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Link, useNavigate } from 'react-router-dom';
import http from '../../../../../../utils/http-common';
import { useDispatch, useSelector } from 'react-redux';
import { updateCredit } from '../../../../../../slice/credits';
import { notSubscribed, subscribed } from '../../../../../../slice/subscribed';
import { downloadCSV } from '../../../../../../components/Public/DownloadCSV';
import { TableComp6 } from '../../../../../../components/table/type - 6';
import { modifyAdminLandingTable } from '../../../../../../components/@extended/CustomFunctions';

export const MyCredits = () => {
  const credi = useSelector((state) => state.credit.credit);
  return (
    <Box>
      <Typography
        sx={{ fontWeight: '600', fontSize: '16px', pb: 2, color: '#000' }}>
        My Credits
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <SimpleCard
          x={{ title: 'Credits Left', value: credi.available_credit }}
          wd={'48%'}
        />
        <SimpleCard
          x={{ title: 'Credits Used', value: credi.used_credit }}
          wd={'48%'}
        />
      </Box>
    </Box>
  );
};

export const SubscriptionDetails = () => {
  const userIdOg = localStorage.getItem('user_id');
  const [plans, setPlan] = useState(null);
  const dispatch = useDispatch();
  const Get = async () => {
    try {
      const response = await http.get(
        `/master_service/subscription_details/${userIdOg}`
      );
      console.log('resi', response.data);
      setPlan(response.data.planDetails);
      localStorage.setItem('seq', response.data.sequence - 1);
      dispatch(subscribed());
      // await tD();

      // setMessage(response.data.message);
      // setSeverity('success');
      // handleClick();
    } catch (error) {
      console.error('Error fetching data:', error);
      if (error.response.data.error === 'Plan data not found') {
        console.log('yes not found');
        localStorage.removeItem('seq');
        dispatch(notSubscribed());
      }
      // setMessage(
      //   error.response
      //     ? error.response.data.message
      //     : 'Unable to Fetch the table data.'
      // );
      // setSeverity('error');
      // handleClick();
    }
  };
  useEffect(() => {
    Get();
  }, []);

  return (
    plans && (
      <Box sx={{ mt: 3 }}>
        <Typography
          sx={{ fontWeight: '600', fontSize: '16px', pb: 2, color: '#000' }}>
          Subscription Details
        </Typography>
        <Box
          // onClick={() => handleCardClick(index)}
          sx={{
            border: '1px solid #E4E4E4',
            color: '#000000',
            p: 2,
            mt: 1,
            borderRadius: '8px',
            minWidth: '200px',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            overflow: 'hidden',
            height: 'auto',
            boxSizing: 'border-box',
            transition: 'transform 0.3s ease, box-shadow 0.3s ease',
            // transform: clickedPlan === index ? "scale(1.05)" : "scale(1)",
            // boxShadow: clickedPlan === index ? "0px 10px 20px rgba(0,0,0,0.2)" : "none",
            // cursor: "pointer",
            perspective: '1000px',
            transformStyle: 'preserve-3d',
          }}>
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              {plans.title && (
                <Typography
                  sx={{
                    fontWeight: '500',
                    fontSize: '24px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    // mb: 2,
                  }}>
                  {plans.title}
                </Typography>
              )}
              {/* <Button
            disableRipple
            sx={{ minWidth: 'max-content' }}>
            <I_Icon style={{ height: '25px', width: '25px' }} />
          </Button> */}
            </Box>

            {plans.text && (
              <Typography
                sx={{
                  fontWeight: '500',
                  fontSize: '15px',
                  color: '#727272',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'normal',
                  // mb: 3,
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 2,
                }}>
                {plans.text}
              </Typography>
            )}

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {plans.price && (
                <Typography
                  sx={{
                    fontWeight: '500',
                    fontSize: '32px',
                    pr: '5px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'normal',
                  }}>
                  {plans.price}
                </Typography>
              )}
              {plans.discount && (
                <Typography
                  sx={{
                    fontWeight: '500',
                    fontSize: '12px',
                    // background:
                    //     clickedPlan === index ? "#4B4B4B" : "#F2F2F2",
                    padding: '2px 6px',
                    borderRadius: '4px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'normal',
                  }}>
                  {plans.discount}
                </Typography>
              )}
            </Box>
            {plans.subText && (
              <Typography
                sx={{
                  fontWeight: '500',
                  fontSize: '12px',
                  color: '#AFAFAF',
                  mb: 3,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'normal',
                }}>
                {plans.subText}
              </Typography>
            )}
            {plans.mainSubText && (
              <Typography
                sx={{
                  fontWeight: '500',
                  fontSize: '14px',
                  color: '#000',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'normal',
                }}>
                {plans.mainSubText}
              </Typography>
            )}
            <Box>
              {plans.subTextData &&
                plans.subTextData.map((item, idx) => (
                  <Box
                    key={idx}
                    sx={{ display: 'flex', alignItems: 'center', py: '10px' }}>
                    {CheckIcon2()}
                    <Typography
                      sx={{
                        fontWeight: '500',
                        fontSize: '14px',
                        pl: '7px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'normal',
                      }}>
                      {item.title}
                    </Typography>
                  </Box>
                ))}
            </Box>
          </Box>
          {plans.btn && (
            <Button
              sx={{
                // border: clickedPlan !== index && "1px solid #8A18D0",
                textTransform: 'capitalize',
                background: '#8A18D0',
                '&:hover': { background: '#8a18d099' },
                color: '#fff',
                width: '100%',
                mt: 2,
                height: '40px',
              }}
              // onClick={() => SelectPlan({ title: plan.title })}
              component={Link}
              to={'/Admin/Subscription'}>
              {plans && 'More Details'}
            </Button>
          )}
        </Box>
      </Box>
    )
  );
};

export const UserTable = () => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const userId = localStorage.getItem('user_id');
  const adminId = localStorage.getItem('admin_id');
  const [searchTerm, setSearchTerm] = useState('');
  const dispatch = useDispatch();
  const navi = useNavigate();
  const [tblData, setTblData] = useState([]);
  const [expTbl, setExpTbl] = useState([]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success');

  const Resend = async ({ uid }) => {
    try {
      const response = await http.post(
        `/master_service/user_resend_mail/${uid}`
      );
      console.log(response.data);

      setMessage(response.data.message);
      setSeverity('success');
      handleClick();
    } catch (error) {
      console.error('Error fetching data:', error);
      setMessage(
        error.response
          ? error.response.data.message
          : 'Unable to Fetch the table data.'
      );
      setSeverity('error');
      handleClick();
    }
  };

  const Delete = async ({ uid }) => {
    console.log('uid', uid)
    try {
      const response = await http.delete(`/master_service/delete/${uid}`);
      console.log(response.data);
      await Get();

      setMessage(response.data.message);
      setSeverity('success');
      handleClick();
    } catch (error) {
      console.error('Error fetching data:', error);
      setMessage(
        error.response
          ? error.response.data.message
          : 'Unable to Fetch the table data.'
      );
      setSeverity('error');
      handleClick();
    }
  };

  const Deactivate = async ({ uid, status }) => {
    try {
      const response = await http.post(
        '/master_service/update_user_status',
        JSON.stringify({
          user_id: uid,
          admin_id: adminId,
          user_status: status,
        })
      );
      console.log(response.data);
      await Get();

      setMessage(response.data.message);
      setSeverity('success');
      handleClick();
    } catch (error) {
      console.error('Error fetching data:', error);
      setMessage(
        error.response
          ? error.response.data.message
          : 'Unable to Fetch the table data.'
      );
      setSeverity('error');
      handleClick();
    }
  };

  const Get = async () => {
    try {
      const response = await http.get(`/master_service/user_list/${userId}`);
      console.log('resi', response.data);
      setExpTbl(response.data.data.users)
      setTblData(modifyAdminLandingTable({ d: response.data.data.users }));
      // await tD();
      dispatch(
        updateCredit({
          available_credit: response.data.data.creditDetails.available_credit,
          used_credit: response.data.data.creditDetails.used_credit,
        })
      );
      dispatch(
        response.data.data.isSubscribed ? subscribed() : notSubscribed()
      );

      // setMessage(response.data.message);
      // setSeverity("success");
      // handleClick();
    } catch (error) {
      console.error('Error fetching data:', error);
      setMessage(
        error.response
          ? error.response.data.message
          : 'Unable to Fetch the table data.'
      );
      setSeverity('error');
      handleClick();
    }
  };
  useEffect(() => {
    Get();
  }, []);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };
  // const handlePreviousPage = () => {
  //   if (page > 1) {
  //     setPage(page - 1);
  //   }
  // };
  const Data2 = [
    {
      Name: 'Mark',
      User_Role: 'Sales Representative',
      Joined_On: '-',
      Invite_Status: 'Pending',
      User_Status: 'Active',
      // Invoice: "",
    },
    {
      Name: 'Carlo',
      User_Role: 'Admin',
      Joined_On: 'Jun 10 2024',
      Invite_Status: 'Accepted',
      User_Status: 'Active',
      // Invoice: "",
    },
  ];
  const tD = () => {
    const labels = tblData.length > 0 ? Object.keys(tblData[0]) : [];

    // Create a template object with empty values for each label
    const emptyRow = labels.reduce((acc, label) => {
      acc[label] = '';
      return acc;
    }, {});
    // Push empty rows until Data2 has at least 7 entries
    while (Data2.length < 17) {
      Data2.push({ ...emptyRow });
    }
    setTblData(Data2);
  };
  // useEffect(() => {
  //   tD();
  // }, []);
  console.log('tbld', tblData)
  const filterDatas = Array.isArray(tblData) ? tblData.filter((data) => {
    const userId = data.user_id ? String(data.user_id).toLowerCase() : ''; // Convert user_id to a string
    const userFirstName = data.user_first_name ? data.user_first_name.toLowerCase() : '';
    const userEmail = data.user_email ? data.user_email.toLowerCase() : '';
    const user_role = data.user_role ? data.user_role.toLowerCase() : '';

    const searchLower = searchTerm.toLowerCase();

    return (
      userId.includes(searchLower) ||
      userEmail.includes(searchLower) ||
      userFirstName.includes(searchLower) ||
      user_role.includes(searchLower)
    );
  }) : [];
  // const filterDatas = tblData;
  const totalRows = filterDatas.length;
  const totalPages = Math.ceil(totalRows / rowsPerPage);
  const startIndex = (page - 1) * rowsPerPage;
  const endIndex = Math.min(startIndex + rowsPerPage, totalRows);
  // Slice data for current page
  const fltData = filterDatas;
  // const fltData = filterDatas?.slice(startIndex, endIndex);
  const paginatedData = fltData?.map((obj) => {
    const { user_id, user_first_name, user_email, user_status_og, invite_status_og, ...rest } = obj;
    return rest;
  });
  const btns = [
    {
      title: ({ i }) => { return 'Edit'; },
      fn: ({ index, i }) => { console.log(i); console.log('pgn', fltData[i]); localStorage.setItem('id', JSON.stringify(fltData[i])); navi('/Admin/Users'); console.log('usid', userId); },
      cb: true,
      link: '',
    },
    {
      title: ({ i }) => { return 'Resend Mail'; },
      fn: ({ index, i }) => { Resend({ uid: fltData?.[i]?.user_id }); },
      cb: true,
      link: '',
    },
    {
      title: ({ i }) => { return 'Delete User'; },
      fn: ({ index, i }) => { Delete({ uid: fltData?.[i]?.user_id }); },
      cb: true,
      link: '',
    },
    {
      title: ({ i }) => { return fltData?.[i]?.user_status_og === 'Deactivate' ? 'Active' : 'Deactivate'; },
      fn: ({ index, i }) => { console.log('pgn', fltData[i]); Deactivate({ uid: fltData?.[i]?.user_id, status: fltData?.[i]?.user_status_og === 'Deactivate' ? 'Active' : 'Deactivate', }); },
      cb: true,
      link: '',
    },
  ];

  console.log(
    'startIndex, endIndex, paginatedData, tblData',
    startIndex,
    endIndex,
    paginatedData,
    tblData
  );
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: { xs: 'column', md: 'row' },
          // mt: 4,
        }}>
        <Typography
          sx={{
            fontWeight: '600',
            fontSize: '16px',
            // pb: 3,
            color: '#000',
            my: { xs: 1, md: 0 },
          }}>
          User Table
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              border: '1px solid #797F8F',
              borderRadius: '4px',
              padding: '0.5rem 1rem',
              // backgroundColor: '#f9f9f9',
              mr: 2,
              width: '100%',
              height: '35px',
              maxWidth: '250px',
            }}>
            <SearchIcon sx={{ color: '#757575', marginRight: '0.5rem' }} />
            <TextField
              sx={{
                flex: 1,
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: 'none',
                    height: '35px',
                  },
                  '&:hover fieldset': {
                    border: 'none',
                  },
                  '&.Mui-focused fieldset': {
                    border: 'none',
                  },
                },
              }}
              placeholder='Search...'
              variant='outlined'
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Box>
          <Button
            sx={{
              // background: '#8A18D0',
              border: '1px solid #47474733',
              color: '#474747',
              // px: 4,
              mx: 2,
              fontSize: '12px',
              // width: '86px',
              display: 'flex',
              minWidth: 'max-content',
              textTransform: 'capitalize',
              // '&:hover': { background: '#B37FFC' },
            }}
            onClick={() => {
              downloadCSV(expTbl, 'User Table');
              handleClick();
              setSeverity('Success');
            }}>
            {/* {UpArrow} */}
            {/* {UpArrow()} */}
            <ArrowUpwardIcon sx={{ height: '15px' }} />
            <Typography sx={{ pl: 0.5, fontSize: '12px', }}>Export</Typography>
          </Button>
          <Link to={'/Admin/Users'}>
            <Button
              sx={{
                background: '#8A18D0',
                color: '#fff',
                // px: 4,
                width: '89px',
                fontSize: '12px',
                display: 'flex',
                textTransform: 'capitalize',
                textWrap: { xs: 'wrap', md: 'nowrap' },
                '&:hover': { background: '#B37FFC' },
              }}>
              <Typography sx={{ fontSize: '12px', }}>Manage User</Typography>
            </Button>
          </Link>
        </Box>
      </Box>

      <Box
        sx={{
          overflow: 'auto',
          borderTopLeftRadius: '15px',
          border: '1px solid #E1E1E1',
          borderTopRightRadius: '15px',
          borderBottomRightRadius: '15px',
          borderBottomLeftRadius: '15px',
          width: '100%',
          mt: 2,
        }}>
        <TableComp6
          Data={paginatedData}
          Style={{
            ...BundleRunLogStyle,
            Invoice: false,
            isThreeDot2: true,
            threeDot2: { textAlign: 'end' },
          }}
          btnData={btns}
        />
        <Snackbar
          open={open}
          autoHideDuration={2000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          sx={{ position: 'absolute', mt: '38px' }}>
          <Alert
            onClose={handleClose}
            severity={severity}
            variant='filled'
            sx={{ width: '100%' }}>
            {message}
          </Alert>
        </Snackbar>
      </Box>
      {/* <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          mt: 2,
          // border: "1px solid #DADADA",
          marginTop: "0px",
          p: "10px",
          borderBottom: "1px solid #DADADA",
          borderRight: "1px solid #DADADA",
          borderLeft: "1px solid #DADADA",
          borderBottomRightRadius: "15px",
          borderBottomLeftRadius: "15px",
        }}
      >
        <Button
          variant="outlined"
          onClick={handlePreviousPage}
          disabled={page === 1}
          sx={{
            height: "30px",
            color: "#474747",
            display: { xs: "none", sm: "flex" },
            px: 4,
            alignItems: "center",
            borderColor: "#DADADA",
            justifyContent: "space-evenly",
            textTransform: "capitalize",
            "&:hover": { borderColor: "#DADADA" },
            mr: 2,
          }}
        >
          <EastIcon sx={{ transform: "rotate(180deg)" }} />
          Previous
        </Button>

        <Pagination
          count={Math.ceil(tblData.length / rowsPerPage)}
          page={page}
          onChange={handleChangePage}
          //   showFirstButton
          //   showLastButton
          hidePrevButton
          hideNextButton
          shape="rounded"
        />
        <Button
          variant="outlined"
          onClick={() => setPage((x) => x + 1)}
          disabled={page >= totalPages}
          sx={{
            height: "30px",
            color: "#474747",
            display: { xs: "none", sm: "flex" },
            px: 4,
            alignItems: "center",
            borderColor: "#DADADA",
            justifyContent: "space-evenly",
            textTransform: "capitalize",
            "&:hover": { borderColor: "#DADADA" },
            ml: 2,
          }}
        >
          Next
          <EastIcon sx={{ ml: 1 }} />
        </Button>
      </Box> */}
    </Box>
  );
};
