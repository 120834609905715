import { Box, Button, Typography } from "@mui/material"
import { ForwardIcon } from "../../utils/icon/AdminPage/Icon"
import { useState } from "react"


export const SellerReviewCard = () => {
    return (
        <Box></Box>
    )
}
export const NotifiCategoryCard = ({ p }) => {
    const { cat, setCat, i } = p;
    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', cursor: 'pointer', p: 2, my: 1, borderRadius: '4px', background: cat === i ? '#8A18D0' : '#FAFAFA', color: cat === i ? '#fff' : '#000', border: '0.8px solid #E1E2E6' }} onClick={() => setCat(i)}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <p.ikn />
                <Box sx={{ pl: 2 }}>
                    <Typography sx={{ fontSize: '12px', fontWeight: '600', pb: 1, }}>{p.text}</Typography>
                    <Typography sx={{ fontSize: '8px', fontWeight: '400', }}>{p.subText}</Typography>
                </Box>
            </Box>
            <Button disableFocusRipple disableRipple disableTouchRipple sx={{ minWidth: 'max-content', width: 'max-content' }}><ForwardIcon /></Button>
        </Box>
    )
}
export const NotifyCard = ({ p }) => {
    const [show, setShow] = useState(false)
    return (
        <Box sx={{ borderRadius: '8px', my: 1, background: '#FAFAFA', border: '0.8px solid #BCBFC766' }}>
            <Box sx={{ display: 'flex', p: 2, alignItems: 'center', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {/* {p.ikn} */}
                    <Box sx={{ height: "40px", width: "40px", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "50%", background: "#000", color: "#fff", fontSize: "20px", fontWeight: "bold", }}>
                        {p.text.charAt(0).toUpperCase()}
                    </Box>
                    <Box sx={{ pl: 2 }}>
                        <Typography sx={{ fontSize: '12px', fontWeight: '600', color: '#000', pb: 1 }}>{p.text}</Typography>
                        <Typography sx={{ fontSize: '8px', fontWeight: '400', color: '#000' }}>{p.subText}</Typography>
                    </Box>
                </Box>
                <Box>
                    <Typography sx={{ fontSize: '12px', fontWeight: '600', color: '#000' }}>{p.time}</Typography>
                    <Button sx={{ fontSize: '8px', fontWeight: '400', color: '#000', textTransform: 'capitalize', }} onClick={() => setShow(pre => !pre)}>{!show ? 'More' : 'Less'}</Button>
                </Box>
            </Box>
            {show &&
                <Box sx={{ borderTop: '2px solid #E1E2E6', display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: 2 }}>
                    <Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography sx={{ fontSize: '12px', fontWeight: '600', color: '#000', pr: 1 }}>Product Name :</Typography>
                            <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#000' }}>{p.prodName}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography sx={{ fontSize: '12px', fontWeight: '600', color: '#000', pr: 1 }}>E-Commerce Name :</Typography>
                            <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#000' }}>{p.EcommName}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography sx={{ fontSize: '12px', fontWeight: '600', color: '#000', pr: 1 }}>Seller :</Typography>
                            <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#000' }}>{p.Seller}</Typography>
                        </Box>
                    </Box>
                    <Box>
                        <Typography sx={{ fontSize: '12px', fontWeight: '600', color: '#000' }}>Product Price</Typography>
                        <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#000', p: 1, background: '#e3c9f2', textAlign: 'center', borderRadius: '3px', }}>{p.prodPrice}</Typography>
                    </Box>
                </Box>
            }
        </Box>
    )
}
