import { Box, Typography, Button } from "@mui/material";
import { DataNotFound_Svg } from "../../../../../../../utils/icon/IndividualPages/Icon";
import { Link, useNavigate } from "react-router-dom";

export const DataNotFound = () => {
  const userType = localStorage.getItem("user_type");
  const navigate = useNavigate();

  const handleButtonClick = () => {
    if (userType === "company") {
      navigate("/Admin/Custom-Bundle");
    } else {
      navigate("/Dashboard/Individual-Dashboard/Custom-Bundle");
    }
  };

  return (
    <Box
      sx={{
        minHeight: "420px",
        width: "100%",
        border: "0.8px solid #797F8F80",
        borderRadius: "4px",
        p: 3,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <Box sx={{ mt: 2 }}>
        <DataNotFound_Svg />
        <Typography
          sx={{ py: 2, fontSize: "20px", fontWeight: "600", color: "#000000" }}
        >
          Data not found
        </Typography>
      </Box>
      <Typography
        sx={{ fontSize: "16px", fontWeight: "500", color: "#000000" }}
      >
        Data is unavailable for the selected configuration.
        <br />
        Would you like to have a custom bundle?
      </Typography>
      <Button
        onClick={handleButtonClick}
        sx={{
          mt: 2,
          width: "150px",
          height: "35px",
          textTransform: "none",
          fontWeight: 500,
          color: "#ffffff",
          backgroundColor: "#8A18D0",
          padding: "6px 16px",
          "&:hover": {
            backgroundColor: "#CA3FC4",
          },
        }}
      >
        Custom Bundle
      </Button>
    </Box>
  );
};
