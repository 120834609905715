import { Table, Box, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { CheckIcon, CrossIcon, InfoIcon } from "../../../../../utils/icon/LandingPage/Icon";
import { tableData } from "../../../../../utils/data/LandingPage/Data";



const ConfigurationsTable = ({ i }) => (
    <Box sx={{ overflowX: 'auto', width: '100%' }}>
        <Table sx={{ border: '1px solid #EAECF0', mt: 4, borderRadius: '8px' }}>
            <TableBody sx={{ width: '100%', overflowX: 'auto' }}>
                {renderSection('Configurations', tableData.configurations, i)}
                {renderSection('Features', tableData.features, i)}
                {renderSection('Support', tableData.support, i)}
            </TableBody>
        </Table>
    </Box>
);

const renderValue = (value) => {
    if (typeof value === 'boolean') {
        return value ? <CheckIcon /> : <CrossIcon />;
    }
    return value;
};

const renderSection = (title, data, i) => (
    <>
        <TableRow>
            <TableCell colSpan={4} sx={{ position: 'sticky', minWidth: '150px', }}>
                <Typography sx={{ fontWeight: 'bold', color: '#8A18D0', }}>{title}</Typography>
            </TableCell>
        </TableRow>
        {data.map((row, index) => (
            <TableRow key={index} sx={{ borderTop: index === 0 ? '1px solid #EAECF0' : 'none', pl: '150px' }}>
                <TableCell
                    sx={{
                        position: 'sticky',
                        left: 0,
                        backgroundColor: '#fff',
                        zIndex: 1,
                        borderRight: '1px solid #EAECF0',
                        minWidth: '150px',
                        // width: '23%'
                    }}
                >
                    {!row.i && row.label}
                    {row.i && (
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography>{row.label}</Typography>
                            <InfoIcon />
                        </Box>
                    )}
                </TableCell>
                {row.values.map((value, idx) => (
                    <TableCell
                        key={idx}
                        sx={{
                            background: idx === i ? '#FAFAFA' : 'none',
                            minWidth: '150px',
                            // width: '500px',
                            // width: { xs: '500px', md: '22%' },
                            // width: '22%',
                            // whiteSpace: 'nowrap',
                            // overflowX: 'auto'
                        }}
                    >
                        {renderValue(value)}
                    </TableCell>
                ))}
            </TableRow>
        ))}
    </>
);



export default ConfigurationsTable;