import { useState, useEffect } from "react";
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import { Link as RouterLink, useNavigate, useLocation } from "react-router-dom";
import { Link as ScrollLink, scroller } from "react-scroll";
import { Logo } from "../../../../../assets/logo";
import { navItems } from "../../../../../utils/data/LandingPage/Data";

const TopNav = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  // const navItems = [
  //   { label: "About us", to: "about-us", route: "/about" },
  //   { label: "Why Brand Aura?", to: "why-brand-aura", route: "/" },
  //   // { label: "Features", to: "features", route: "/" },
  //   // { label: "Pricing", to: "pricing", route: "/" },
  //   { label: "FAQ", to: "faq", route: "/faq" },
  //   { label: "Contact us", to: "contact-us", route: "/contact" },
  // ];

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleNavigation = (route, section) => {
    // if (route === "/") {
    //   navigate(route);
    //   scroller.scrollTo(section, {
    //     duration: 500,
    //     delay: 100,
    //     smooth: "easeInOutQuart",
    //     offset: -70,
    //   });
    // } else {
    //   navigate(route);
    scroller.scrollTo(section, {
      duration: 500,
      delay: 100,
      smooth: "easeInOutQuart",
      offset: -70,
    });
    handleMenuClose();
    // }
  };

  // useEffect(() => {
  //   if (location.pathname === "/") {
  //     scroller.scrollTo("main", {
  //       duration: 500,
  //       delay: 100,
  //       smooth: "easeInOutQuart",
  //       offset: -70,
  //     });
  //   }
  // }, [location.pathname]);

  return (
    <AppBar
      position="static"
      sx={{
        background: "#fff",
        borderBottomLeftRadius: "16px",
        borderBottomRightRadius: "16px",
        py: 0.5,
      }}
    >
      <Toolbar sx={{ justifyContent: "space-between" }}>
        {/* <Box sx={{ display: { xs: "flex", md: "none" }, }}>
          {"/" === window.location.pathname ? (
            <ScrollLink
              to="main"
              spy={true}
              smooth={true}
              offset={-70}
              duration={900}
              style={{
                cursor: "pointer",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              <Logo />
            </ScrollLink>
          ) : (
            <RouterLink to={"/"} style={{ textDecoration: "none" }}>
              <Logo />
            </RouterLink>
          )}
        </Box> */}
        <Box
          sx={{
            display: { xs: "flex", md: "flex" },
            color: "#000000",
            width: "100%",
            justifyContent: "space-between",
            alignItems: 'center'
          }}
        >
          {"/" === window.location.pathname ? (
            <ScrollLink
              to="main"
              spy={true}
              smooth={true}
              offset={-70}
              duration={900}
              style={{
                cursor: "pointer",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              <Logo />
            </ScrollLink>
          ) : (
            <RouterLink to={"/"} style={{ textDecoration: "none" }} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
              <Logo />
            </RouterLink>
          )}
          <Box sx={{ display: { xs: "none", md: "flex" }, justifyContent: 'center', alignItems: 'center', width: '70%', flexWrap: "nowrap" }}>
            {navItems.map((item, index) => (
              <Box key={index} sx={{ mx: { xs: 0.5, md: 1 } }}>
                <RouterLink
                  to={item.route}
                  style={{ textDecoration: "none" }}
                  onClick={() => {
                    setTimeout(() => {
                      scroller.scrollTo(item.to, { smooth: true, duration: 1500 });
                    }, 50);
                  }}
                >
                  <Button
                    sx={{
                      color: "inherit",
                      flexWrap: "nowrap",
                      fontStyle: "normal",
                      textTransform: "capitalize",
                    }}
                  >
                    {item.label}
                  </Button>
                </RouterLink>
              </Box>
            )
            )}
          </Box>
          <Box sx={{ display: { xs: 'none', md: "flex" }, flexWrap: "nowrap" }}>
            <RouterLink to="/Login" style={{ textDecoration: "none" }}>
              <Button
                sx={{
                  color: "inherit",
                  border: "1px solid #000000",
                  mx: "10px",
                  textTransform: "capitalize",
                  minWidth: { xs: 'min-content', md: '108px' },
                  textWrap: 'nowrap',
                  // px: 3,
                }}
              >
                Sign in
              </Button>
            </RouterLink>
            <RouterLink to="/Reg" style={{ textDecoration: "none" }}>
              <Button
                sx={{
                  color: "#fff",
                  background: "#8A18D0",
                  mx: "10px",
                  minWidth: { xs: 'min-content', md: '108px' },
                  textWrap: 'nowrap',
                  textTransform: "capitalize",
                  "&:hover": { background: "#8a18d099" },
                  // px: 3,
                }}
              >
                Try for Free
              </Button>
            </RouterLink>
          </Box>
        </Box>
        <IconButton
          edge="start"
          color="#000"
          aria-label="menu"
          sx={{ display: { xs: "block", md: "none" } }}
          onClick={handleMenuOpen}
        >
          <MenuIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          sx={{ display: { xs: "block", md: "none" } }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          {navItems.map((item, index) =>
            // item.route === "/" ? (
            //   <ScrollLink
            //     key={index}
            //     to={item.to}
            //     spy={true}
            //     smooth={true}
            //     offset={-70}
            //     duration={900}
            //     style={{
            //       cursor: "pointer",
            //       color: "inherit",
            //       textDecoration: "none",
            //       textTransform: "capitalize",
            //     }}
            //   >
            //     <MenuItem onClick={handleMenuClose}>{item.label}</MenuItem>
            //   </ScrollLink>
            // ) : (
            <RouterLink
              key={index}
              to={item.route}
              style={{ textDecoration: "none" }}
              // onClick={() => handleNavigation(item.route, item.to)}
              onClick={() => {
                setTimeout(() => {
                  scroller.scrollTo(item.to, { smooth: true, duration: 1500 });
                }, 50);
              }}
            >
              <MenuItem onClick={handleMenuClose}>{item.label}</MenuItem>
            </RouterLink>
            // )
          )}
          <RouterLink to="/Login" style={{ textDecoration: "none" }}>
            <MenuItem onClick={handleMenuClose} sx={{ px: 3 }}>
              Login
            </MenuItem>
          </RouterLink>
          <RouterLink to="/Reg" style={{ textDecoration: "none" }}>
            <MenuItem onClick={handleMenuClose} sx={{ px: 3 }}>
              Try for Free
            </MenuItem>
          </RouterLink>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default TopNav;
