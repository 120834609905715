import { Box } from '@mui/material';
import { TopNav_2 } from '../../../../../components/nav/top_nav_2';
import { Outlet, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { SideNav_3 } from '../../../../../components/nav/side_nav_3';
import {
  routesMap,
  shortSideNav,
  sideNav,
} from '../../../../../utils/data/AdminPage/Data';
import {
  adminNavItms,
  corpNavItms,
  navItms,
} from '../../../../../utils/data/IndividualDashboard/Data';

export const Main_Admin = () => {
  const [nav, setNav] = useState(-1);
  const [subNav, setSubNav] = useState(-1);
  const containerRef = useRef(null);
  const sideNavRef = useRef(null);
  const [minimize, setMinimize] = useState(false);
  const [smallScreen, setSmallScreen] = useState(window.innerWidth < 1100);
  const ut = localStorage.getItem('user_type');
  const navigate = useNavigate();
  const handleResize = () => {
    setMinimize(window.innerWidth < 1100);
    setSmallScreen(window.innerWidth < 1100);
  };

  useEffect(() => {
    const userRole = localStorage.getItem('user_role');
    if (ut === 'company' && userRole === 'Normal User') {
      navigate('/User');
    } else if (ut === 'individual') {
      navigate('/Dashboard/Individual-Dashboard');
    }
  }, []);
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const mainContentWidth = containerRef.current && sideNavRef.current
    ? `${containerRef.current.offsetWidth - sideNavRef.current.offsetWidth}px`
    : '100%';
  return (
    <Box sx={{ display: 'flex', height: '100vh', width: '100%' }} ref={containerRef}>
      <SideNav_3 ref={sideNavRef} minimize={minimize} smallScreen={smallScreen} setSmallScreen={setSmallScreen} setMinimize={setMinimize} Data={!minimize ? sideNav : shortSideNav} routesMap={routesMap} commonPath={'/Admin'} />
      <Box sx={{ display: 'flex', flexDirection: 'column', width: mainContentWidth }}>
        <TopNav_2 setNav={setNav} minimize={minimize} setMinimize={setMinimize} Data={!smallScreen ? ut === 'company' ? corpNavItms : navItms : adminNavItms} />
        <Box sx={{ height: '100%', overflowY: 'auto', scrollbarWidth: 'none' }}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};
