import { Box, Typography, Button, TextField, FormLabel, Divider, Snackbar, Alert } from '@mui/material';
import { Profile_I_Svg } from '../../../../utils/icon/IndividualPages/Icon';
import { useState, useEffect } from 'react';
import http from '../../../../utils/http-common';
import { dateClnse } from '../../../../components/Public/DateClnse';
import { base64ToBlob, extractBase64Data } from '../../../../components/@extended/CustomFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { setImage } from '../../../../slice/ProfilePic';

export const User_Profile = () => {
  const userType = localStorage.getItem('user_type');
  const [date, setDate] = useState('');
  const [name, setName] = useState('');
  const [country, setCountry] = useState('');
  const [imageChanged, setImageChanged] = useState(null);
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [company_name, setCompany_Name] = useState('');
  const [originalData, setOriginalData] = useState({});
  const [isFormChanged, setIsFormChanged] = useState(false);

  const image = useSelector((state) => state.profile.ProfilePic);
  const dispatch = useDispatch();
  // const [image, setImage] = useState('');
  const [imageFile, setImageFile] = useState('');
  useEffect(() => {
    setImageFile(image)
  }, [image]);
  const [errors, setErrors] = useState({});
  const [severity, setSeverity] = useState('success');
  const [message, setMessage] = useState('');
  const [open, setOpen] = useState(false);
  const user_id = localStorage.getItem('user_id');
  const fetchUserData = async () => {
    try {
      const response = await http.get(`/master_service/profile/${user_id}`);
      if (response.status === 200) {
        const data = response.data;
        setDate(dateClnse(data.joined_on) || '');
        setName(data.user_first_name || '');
        setCountry(data.user_country || '');
        setPhone(data.user_phone_number || '');
        setEmail(data.user_email || '');
        setCompany_Name(data.user_company_name || '');
      }
      console.log('res', response.data);
    } catch (error) {
      console.log('Fetching failed', error)
      setMessage('Fetching failed.');
      setSeverity('error');
      handleClick();
    }
  };
  const fetchImage = async () => {
    try {
      const response = await http.get(`/master_service/get_image/${user_id}`);
      dispatch(setImage(response.data.imageUrl))
      console.log('response', response, response.data.imageUrl)
    } catch (error) {
      console.log('Fetching img failed', error)
      setMessage(error?.message || 'Fetching img failed.');
      setSeverity('error');
      handleClick();
    }
  };

  const Edit = async () => {
    try {
      const response = await http.post(
        `/master_service/profile_update/${user_id}`,
        JSON.stringify({
          user_first_name: name,
          // user_last_name: ,
          user_country: country,
          user_phone_number: phone,
          user_company_name: company_name,
        })
      );
      if (response.status === 200) {
        localStorage.setItem('user_name', response.data.updatedFields.user_first_name)
        setMessage(response.data.message);
        setSeverity('success');
        handleClick();
        setIsFormChanged(false);
      }
      console.log(response.data);
    } catch (error) {
      console.log('Save failed', error)
      setMessage('Save failed.');
      setSeverity('error');
      handleClick();
    }
  };

  const uploadImage = async (event) => {
    const formData = new FormData();

    if (imageChanged === 1) {
      const base64Image = extractBase64Data(imageFile);
      const mime = "image/jpeg";
      const blob = base64ToBlob(base64Image, mime);
      const file = new File([blob], "filename.jpeg", { type: mime });
      console.log('img', imageFile, blob, file)
      formData.append('user_id', user_id);
      formData.append("image", file);
      // formData.append("image", event.target.files[0]);
    } else {
      formData.append("uploaded_picture_path", imageFile);
    }
    try {
      console.log('frm', formData, imageChanged)
      console.log('frm img', imageFile)
      const response = await http.post(`/master_service/upload_image/profile`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response.status === 200) {
        setImageChanged(null)
        setMessage(response.data.message);
        setSeverity('success');
        handleClick();
        // const previewUrl = URL.createObjectURL(event.target.files[0]);
        const previewUrl = response?.data?.imageUrl;
        dispatch(setImage(previewUrl));
        setImageFile(previewUrl)
      }
    } catch (error) {
      console.log('img upload failed', error)
      setMessage('Image upload failed.');
      setSeverity('error');
      handleClick();
    }
  };

  const RemoveImg = () => {
    setImageFile('')
    setImageChanged(2)
    console.log('rem', imageChanged, imageChanged === null, imageChanged !== null)
  }
  const RemoveImage = async () => {
    if (!image) {
      setMessage('No image to remove. Please upload an image.');
      setSeverity('error');
      handleClick();
      return;
    }

    try {
      const response = await http.post(`/master_service/delete_image`, JSON.stringify({ user_id: user_id }));
      if (response.status === 200) {
        setImageChanged(null)
        setMessage(response.data.message);
        setSeverity('success');
        handleClick();
        dispatch(setImage(''));
      }
    } catch (error) {
      console.log('img delete failed', error)
      setMessage('Image delete failed.');
      setSeverity('error');
      handleClick();
    }
  };

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    console.log('hic', file, event)
    if (file) {
      console.log('hic ent')
      setImageFile(file);
      setImageChanged(1);

      // Create a preview URL for the selected image
      // const previewUrl = URL.createObjectURL(file);
      // dispatch(setImage(previewUrl));
      // const fileName = file.name;
      // setImageFile(fileName);
      console.log('Selected file name:', file, imageFile, imageChanged);
      // await uploadImage(event);
    }
  };
  // const handleImageChange = (event) => {
  //   setImageChanged(true);
  //   const file = event.target.files[0];
  //   const validTypes = ["image/jpeg", "image/png", "image/gif"];
  //   const maxSize = 5 * 1024 * 1024; // 5 MB

  //   if (file && validTypes.includes(file.type) && file.size <= maxSize) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       // setImage(reader.result); // Preview the image
  //       setImageFile(file)
  //     };
  //     reader.readAsDataURL(file);
  //     const previewUrl = URL.createObjectURL(file);
  //     setImage(previewUrl);
  //     const fileName = file.name;
  //     //   uploadImage(file);
  //     uploadImage();
  //     console.log('Selected file name:', file.name);
  //   } else {
  //     alert("Please upload a valid image file (JPEG, PNG, GIF) under 5MB.");
  //   }
  // }
  // Validate form fields
  const validateForm = () => {
    let tempErrors = {};
    let isValid = true;

    // Name validation
    if (!name) {
      tempErrors.name = 'Name is required.';
      isValid = false;
    }

    // Country validation
    if (!country) {
      tempErrors.country = 'Country is required.';
      isValid = false;
    }

    // Phone number validation
    if (!phone || phone.length < 10) {
      tempErrors.phone = 'A valid phone number is required.';
      isValid = false;
    }

    // Email validation
    if (!email) {
      tempErrors.email = 'Email is required.';
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      tempErrors.email = 'Email address is invalid.';
      isValid = false;
    }

    // Company name validation (only for company users)
    if (userType === 'company' && !company_name) {
      tempErrors.company_name = 'Company name is required.';
      isValid = false;
    }

    setErrors(tempErrors);
    return isValid;
  };
  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  const handleFieldChange = (field, newValue) => {
    if (newValue !== originalData[field]) {
      setIsFormChanged(true);
    }
  };


  const handleFileChange = (event) => {
    console.log('img')
    setImageChanged(1);
    const file = event.target.files[0];
    const validTypes = ["image/jpeg", "image/png", "image/gif"];

    if (file && validTypes.includes(file.type)) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageFile(reader.result); // Preview the image
      };
      reader.readAsDataURL(file);
      //   uploadImage(file);
    } else {
      alert("Please upload a valid image file (JPEG, PNG, GIF).");
    }
  };


  const handleSubmit = () => {
    if (validateForm() && isFormChanged && imageChanged === 1) {
      Edit();
      uploadImage()
      console.log('Form submitted successfully');
    } else if (validateForm() && isFormChanged && imageChanged === 2) {
      Edit();
      RemoveImage()
      console.log('Form submitted successfully');
    } else if (imageChanged === 1 && !isFormChanged) {
      uploadImage()
    } else if (imageChanged === 2 && !isFormChanged) {
      RemoveImage()
    } else if (validateForm() && isFormChanged) {
      Edit();
    }
  };

  useEffect(() => {
    fetchUserData();
    fetchImage();
  }, []);
  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        width: '100%',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        p: 5,
        pr: 15,
      }}
    >
      <Box sx={{ flexDirection: 'column', mr: 5 }}>
        <Box sx={{ mb: 2 }}>
          <Typography sx={{ fontSize: '20px', fontWeight: '600', color: '#8A17D0' }}>Profile</Typography>
        </Box>
        <Box sx={{ display: 'flex', marginTop: '10px' }}>
          <Box
            sx={{
              width: '90px',
              height: '90px',
              borderRadius: '50%',
              border: '1px solid #ccc',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              overflow: 'hidden',
              marginTop: '20px',
              backgroundColor: 'white',
            }}
          >
            {imageFile ? (
              <img
                src={imageFile}
                alt='Profile'
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
              />
            ) : (
              <Box
                sx={{
                  height: "90px",
                  width: "90px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "50%",
                  background: "#B37FFC",
                  color: "#fff",
                  fontSize: "50px",
                  fontWeight: "bold",
                }}
              >
                {name && name.charAt(0).toUpperCase()}
              </Box>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            mt: 2,
          }}
        >
          <Button disableElevation disableFocusRipple disableRipple disableTouchRipple onClick={() => document.getElementById('file-input').click()} sx={{ width: '100px', height: '32px', textTransform: 'none', fontWeight: 500, color: '#ffffff', backgroundColor: '#8A18D0', padding: '6px 16px', '&:hover': { backgroundColor: '#8A18D0', }, marginTop: '10px', }}>
            Upload
          </Button>
          <input
            type='file'
            // onChange={handleImageChange}
            onChange={(e) => { console.log('img tag'); handleFileChange(e); e.target.value = ''; }}
            id='file-input'
            style={{ display: 'none' }}
            accept='image/*'
          />
          <Button disableElevation disableFocusRipple disableRipple disableTouchRipple sx={{ width: '100px', height: '32px', textTransform: 'none', fontWeight: 500, color: '#8A18D0', backgroundColor: '#ffffff', '&:hover': { backgroundColor: '#ffffff', }, border: '1px solid #8A18D0', marginTop: '12px', }} onClick={() => RemoveImg()}>
            Remove
          </Button>
        </Box>
      </Box>
      <Box sx={{ height: '70%', mx: 6, mt: 8 }}>
        <Divider
          orientation='vertical'
          sx={{
            border: '1px solid #E7E8EA',
            height: '100%',
          }}
        />
      </Box>
      <Box sx={{ flex: 1, mt: 7 }}>
        <Box
          sx={{
            border: '1px',
            height: '40px',
            width: '100%',
            background: '#FFF6E5',
            borderRadius: '8px',
            mt: 2,
            mb: 2,
          }}
        >
          <Box sx={{ display: 'flex', p: 1 }}>
            <Profile_I_Svg />
            <Typography
              sx={{
                marginLeft: 2,
                fontWeight: '600',
                fontSize: '12px',
                color: '#040815',
                mt: '4px',
              }}
            >
              User Registered Since {date}
            </Typography>
          </Box>
        </Box>
        <Typography
          sx={{
            mb: 2,
            fontWeight: '600',
            fontSize: '20px',
            color: '#000000',
          }}
        >
          Personal Details
        </Typography>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'space-between',
            my: { xs: '0', sm: '15px' },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: { xs: '100%', sm: '46%' },
              my: { xs: '15px', sm: '0' },
            }}
          >
            <FormLabel
              sx={{
                fontWeight: '500',
                fontSize: '14px',
                color: '#000000',
                my: 1,
              }}
            >
              Name*
            </FormLabel>
            <TextField
              value={name}
              onChange={(e) => {
                setName(e.target.value)
                handleFieldChange('user_name', e.target.value);
              }}
              type='text'
              InputProps={{
                sx: { height: '40px' },
              }}
              placeholder='Enter name'
              error={!!errors.name}
              helperText={errors.name}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: { xs: '100%', sm: '46%' },
              my: { xs: '20px', sm: '0' },
            }}
          >
            <FormLabel
              sx={{
                fontWeight: '500',
                fontSize: '14px',
                color: '#000000',
                my: 1,
              }}
            >
              Country*
            </FormLabel>
            <TextField
              placeholder='Enter country'
              type='text'
              value={country}
              onChange={(e) => {
                setCountry(e.target.value);
                handleFieldChange('user_country', e.target.value);
              }}
              InputProps={{
                sx: { height: '40px' },
              }}
              error={!!errors.country}
              helperText={errors.country}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'space-between',
            my: { xs: '0', sm: '15px' },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: { xs: '100%', sm: '46%' },
              my: { xs: '15px', sm: '0' },
            }}
          >
            <FormLabel
              sx={{
                fontWeight: '500',
                fontSize: '14px',
                color: '#000000',
                my: 1,
              }}
            >
              Phone*
            </FormLabel>
            <TextField
              value={phone}
              onChange={(e) => {
                setPhone(e.target.value);
                handleFieldChange('user_phone_number', e.target.value);
              }}
              type='text'
              InputProps={{
                sx: { height: '40px' },
              }}
              placeholder='Enter phone'
              error={!!errors.phone}
              helperText={errors.phone}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: { xs: '100%', sm: '46%' },
              my: { xs: '20px', sm: '0' },
            }}
          >
            <FormLabel
              sx={{
                fontWeight: '500',
                fontSize: '14px',
                color: '#000000',
                my: 1,
              }}
            >
              Email ID*
            </FormLabel>
            <TextField
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                handleFieldChange('user_email', e.target.value);
              }}
              disabled
              type='text'
              InputProps={{
                sx: {
                  height: '40px',
                  backgroundColor: '#47474799',
                },
              }}
              placeholder='Enter Email ID'
              error={!!errors.email}
              helperText={errors.email}
            />
          </Box>
        </Box>
        <Typography
          sx={{
            mt: 2,
            fontSize: '20px',
            fontWeight: '600',
            color: '#000000',
          }}
        >
          Company Details
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: { xs: '100%', sm: '46%' },
            my: { xs: '15px', sm: '10px' },
          }}
        >
          <FormLabel
            sx={{
              fontWeight: '500',
              fontSize: '14px',
              color: '#000000',
              my: 1,
            }}
          >
            Company Name{userType === 'company' ? '*' : ''}
          </FormLabel>
          <TextField
            value={company_name}
            onChange={(e) => {
              setCompany_Name(e.target.value);
              handleFieldChange('user_company_name', e.target.value);
            }}
            type='text'
            InputProps={{
              sx: { height: '40px' },
            }}
            placeholder='Enter company name'
            error={!!errors.company_name}
            helperText={errors.company_name}
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button disableElevation disableFocusRipple disableRipple disableTouchRipple disabled={!isFormChanged && imageChanged === null} sx={{ width: '100px', height: '32px', textTransform: 'none', fontWeight: 500, color: '#ffffff', backgroundColor: '#8A18D0', padding: '6px 16px', '&:hover': { backgroundColor: '#CA3FC4', }, }} onClick={handleSubmit}>
            Save
          </Button>
          <Snackbar
            open={open}
            autoHideDuration={2000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          >
            <Alert onClose={handleClose} severity={severity} variant='filled' sx={{ width: '100%' }}>
              {message}
            </Alert>
          </Snackbar>
        </Box>
      </Box>
    </Box>
  );
};
