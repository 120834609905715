import { DataGrid } from '@mui/x-data-grid';
import { Box, Paper } from '@mui/material';
import { snakeCaseToNormal } from '../../../../utils/Utility';
import { ThreeDots } from '../../../../utils/icon/IndividualPages/Icon';

export const DataG_T_1 = ({ Data = [], Style = {}, bundle_id = '', rb, sorting = false, ChangeModelData }) => {
    const sanitizedData = Array.isArray(Data) ? Data : [];
    const columns = sanitizedData && sanitizedData.length > 0
        ? Object.keys(sanitizedData[0]).map((key) => ({
            field: key,
            headerName: snakeCaseToNormal(key),
            flex: 1, // to make columns responsive
        }))
        : [];

    // If the three dots menu needs to be added to every row
    if (Style.isThreeDot) {
        columns.push({
            field: 'actions',
            headerName: '',
            sortable: false,
            flex: 0.5, // Adjust the size of the action column
            renderCell: (params) => (
                <ThreeDots bundle_id={bundle_id} rb={rb} ChangeModelData={ChangeModelData} />
            )
        });
    }

    return (
        <Box sx={{ overflow: 'auto', border: "0.8px solid #797F8F80", borderRadius: '8px', width: '100%' }}>
            <Paper sx={{ width: '100%', overflow: 'auto' }}>
                <DataGrid
                    rows={sanitizedData.map((row, index) => ({ id: index, ...row }))}
                    columns={columns}
                    disableSelectionOnClick
                    sx={{
                        borderRadius: '25px',
                        ...(Style.table ? { ...Style.table } : {}),
                        '& .MuiDataGrid-columnHeaders': { ...(Style.head ? { ...Style.head } : {}) },
                        '& .MuiDataGrid-columnHeader': { ...(Style.headCell ? { ...Style.headCell } : {}) },
                        '& .MuiDataGrid-row': {
                            "&:nth-of-type(odd)": { backgroundColor: Style.oddEvenColor ? '#FAFAFA' : '#FFFFFF' },
                            ...(Style.row ? { ...Style.row } : {}),
                        },
                        '& .MuiDataGrid-cell': { ...(Style.cell ? { ...Style.cell } : {}) },
                    }}
                    getRowId={(row) => row.id}
                    autoHeight
                    hideFooter
                    disableColumnFilter
                    disableColumnMenu
                    // disableAutosize
                    disableColumnSorting={sorting ? false : true}
                />
            </Paper>
        </Box>
    );
};
